import React from "react";
import { useTranslation } from 'react-i18next';


const OurServices = () => {
    const {t} = useTranslation()
    return (
        <div className="ourservice-main-container">
            <div className="ourservice-sec-container">
                <div>
                    <img src="../img/pettransport.jpg" alt="" />
                </div>
                    <a> 
                        {t("service_first_text")}
                    </a>

                <hr />

                <div className="service-buttons-container">
                    <div>
                        <a>{t("tel")}: +995-591-88-81-84</a>
                    </div>

                    <div>
                        <a href="servicedet.html">{t("detail_info")}</a>
                    </div>
                </div>
            </div>

            <div className="ourservice-sec-container">
                <div>
                    <img src="../img/hotel.jpeg" alt="" />
                </div>
                <a> 
                    {t("service_second_text")}
                </a>

                <hr />

                <div className="service-buttons-container">
                    <div>
                        <a>{t("tel")}: +995-591-88-81-84</a>
                    </div>

                    <div>
                        <a href="hoteldet.html">{t("detail_info")}</a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OurServices;