import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="rules-parent-container">
        <div className="rules-parent-container-header"></div>
        <div className="rules-inside-container">
          <ul className="table-of-content">
            <li>როგორ ვიყენებთ თქვენს პერსონალურ მონაცემებს</li>
            <li>პერსონალურ მონაცემთა დაცვის დაპირება</li>
            <li>ვინ ვართ ჩვენ</li>
            <li>როგორ გიცავთ კანონი და მომხმარებლის უფლებები</li>
            <li>შეგროვებული მონაცემების სახეობა და მისი გამოყენება</li>
            <li>„მზა ჩანაწერები“ (Cookies)</li>
            <li>
              როგორ ვიყენებთ თქვენს მონაცემებს ავტომატური გადაწყვეტილებების
              მიღების პროცესში
            </li>
            <li>პერსონალური მონაცემების ჯგუფები</li>
            <li>
              რისთვის ვიყენებთ თქვენს პერსონალურ მონაცემებს და ჩვენი მიზანი
            </li>
            <li>მონაცემთა დამუშავების ჩვენი ლეგიტიმური ინტერესები:</li>
            <li>მონაცემთა დამუშავების ჩვენი სამართლებრივი საფუძველი:</li>
            <li>წყაროები, საიდანაც მოვიპოვებთ პერსონალურ მონაცემებს</li>
            <li>ვის ვუზიარებთ თქვენს პერსონალურ მონაცემებს</li>
            <li>GDPR-ის საფუძველზე არსებული სპეციალური ღონისძიებები</li>
            <li>პერსონალური მონაცემების უსაფრთხოება</li>
            <li>თაღლითობის გამოვლენა</li>
            <li>პერსონალური მონაცემების დაცვის სხვადასხვა საკითხი</li>
            <li>საჯარო ადგილები</li>
            <li>„Facebook ავტორიზაცია“</li>
            <li>„Google ავტორიზაცია“</li>
            <li>პერსონალური მონაცემების შენახვის ვადა</li>
            <li>მონაცემთა პირდაპირი მარკეტინგის მიზნისთვის დამუშავება</li>
            <li>
              მონაცემთა პირდაპირი მარკეტინგის მიზნით შეწყვეტის
              მოთხოვნა/თანხმობის გახმობა
            </li>
            <li>როგორ გაითხოვოთ თქვენი თანხმობა</li>
            <li>კონფიდენციალობის პოლიტიკის ცვლილება</li>
            <li>როგორ დაგვიკავშირდეთ</li>
          </ul>

          <div className="editor">
            <h2>კონფიდენციალობის პოლიტიკა&nbsp;- 29.02.2024</h2>
            <div className="read-rules">
              <p>
                წინამდებარე დოკუმენტი (კონფიდენციალურობის პოლიტიკა)განსაზღვრავს
                და წარმოადგენს Veli.store-ის ვებგვერდით სარგებლობის წესებისა და
                პირობების განუყოფელ ნაწილს, რომელიც არის ორმხრივი
                მავალდებულებელი დოკუმენტი.
              </p>
              <h3>როგორ ვიყენებთ თქვენს პერსონალურ მონაცემებს</h3>
              <p>
                ჩვენი მიზანია, გაგაცნოთ, როგორ ამუშავებს და იყენებს{" "}
                <strong>შპს</strong>
                <strong> „</strong>
                <strong>თინეთი</strong>
                <strong>“</strong> (შემდგომში „კომპანია“ ან „ჩვენ“) თქვენს
                პერსონალურ მონაცემებს.
              </p>
              <p>წინამდებარე პოლიტიკის მიზანია მოგაწოდოთ ინფორმაცია:</p>
              <ul>
                <li>
                  თქვენი პერსონალური მონაცამების დამუშავებისას როგორ განვმარტავთ
                  პრინციპებს, რომლითაც ვხელმძღვანელობთ;
                </li>
                <li>
                  განვიხილავთ როგორ უზრუნველყოფს კანონმდებლობა თქვენი უფლებების
                  დაცვას.
                </li>
                <li>
                  დეტალურად მოგაწვდით ინფორმაციას იმის შესახებ თუ რა ტიპის
                  პერსონალურ მონაცემებს აგროვებს კომპანია თქვენ შესახებ მაშინ
                  როდესაც ხდებით ჩვენი მომხმარებელი;
                </li>
                <li>
                  როგორ ხდება ამ მონაცემების გამოყენება პირდაპირი მარკეტინგის
                  მიზნებისთვის.
                </li>
                <li>
                  როგორ მუშავდება მონაცემები საქართველოს კანონმდებლობისა
                  შესაბამისად.
                </li>
              </ul>
              <h3>პერსონალურ მონაცემთა დაცვის დაპირება</h3>
              <p>ჩვენ გპირდებით, რომ:</p>
              <ul>
                <li>
                  ვიზრუნებთ თქვენი პერსონალური მონაცემების უსაფრთხოებასა და
                  დაცვაზე;
                </li>
                <li>არ გამოვიყენებთ თქვენს მონაცემებს უკანონოდ;</li>
                <li>
                  თქვენი მოთხოვნის შესაბამისად, მოგაწვდით სრულ და დეტალურ
                  ინფორმაციას თქვენი პერსონალური მონაცემების დამუშავებასთან
                  დაკავშირებით.
                </li>
              </ul>
              <h3>ვინ ვართ ჩვენ</h3>
              <p>
                შპს „თინეთი“ წარმოადგენს საქართველოს კანონმდებლობის შესაბამისად
                რეგისტრირებულ იურიდიულ პირს, რომელიც წინამდებარე პოლიტიკის
                მიზნებისთვის მოქმედებს, როგორც მონაცემთა დამმუშავებელი
                (დამუშავებისთვის პასუხისმგებელი პირი).
              </p>
              <p>
                შპს „თინეთი“ ფლობს და მართავს შემდეგ პლატფორმებს:{" "}
                <a href="http://www.vendoo.ge/">vendoo.ge</a>;{" "}
                <a href="http://www.livo.ge/">livo.ge</a>;{" "}
                <a href="http://www.swoop.ge/">swoop.ge</a>;{" "}
                <a href="http://www.mymarket.ge/">mymarket.ge</a>;
                <a href="http://www.myshop.ge/"> myshop.ge</a>; myauto.ge;
                <a href="http://www.myparts.ge/"> myparts.ge</a>;
                <a href="http://www.myhome.ge/"> myhome.ge</a>; myads.ge;
                myjobs.ge; &nbsp;tkt.ge და აპლიკაციას Super App (
                <a href="https://superapp.tnet.ge/">
                  https://superapp.tnet.ge/
                </a>
                )-ს.
              </p>
              <p>საიდენტიფიკაციო კოდი: 402116474</p>
              <p>იურიდიული მისამართი: თბილისი, შ. ნუცუბიძის ქ. 129ა, სართ.4</p>
              <p>
                ელ.ფოსტა:{" "}
                <a href="mailto:Personaldata@tnet.ge">Personaldata@tnet.ge</a>
              </p>
              <h3>როგორ გიცავთ კანონი და მომხმარებლის უფლებები</h3>
              <p>
                <strong>&nbsp;</strong>თქვენი უფლებები დაცულია საქართველოს
                კანონმდებლობის შესაბამისად, რომლის საფუძველზე თქვენ უფლება
                გაქვთ, მოგვთხოვოთ ინფორმაცია იმის შესახებ, თუ როგორ ვიყენებთ
                თქვენს პერსონალურ მონაცემებს.
              </p>
              <p>
                თქვენ გაქვთ უფლება კანონმდებლობით დადგენილ ვადაში მიიღოთ შემდეგი
                სახის ინფორმაცია:
              </p>
              <ul>
                <li>რომელი მონაცემები მუშავდება თქვენ შესახებ;</li>
                <li>მონაცემთა დამუშავების მიზანი;</li>
                <li>მონაცემთა დამუშავების სამართლებრივი საფუძველი;</li>
                <li>რა გზით შეგროვდა თქვენი მონაცემები;</li>
                <li>
                  მონაცემთა შენახვის ვადის შესახებ, ხოლო თუ კონკრეტული ვადის
                  განსაზღვრა შეუძლებელია, ვადის განსაზღვრის კრიტერიუმების
                  თაობაზე;
                </li>
                <li>ვის გადაეცა თქვენი მონაცემები;</li>
                <li>
                  ავტომატიზებული დამუშავების, მათ შორის, პროფაილინგის შედეგად
                  მიღებული გადაწყვეტილების და იმ ლოგიკის შესახებ, რომელიც
                  გამოიყენება ამგვარი გადაწყვეტილების მისაღებად, აგრეთვე
                  მონაცემთა დამუშავებაზე მისი გავლენისა და დამუშავების
                  მოსალოდნელი/სავარაუდო შედეგის თაობაზე.
                </li>
                <li>მონაცემთა გაცემის საფუძველი და მიზანი.</li>
                <li>კანონმდებლობით განსაზღვრული სხვა ინფორმაცია.</li>
              </ul>
              <p>კანონმდებლობის შესაბამისად თქვენ უფლება გაქვთ:</p>
              <ul>
                <li>
                  მოითხოვოთ თქვენი პერსონალური მონაცემების გასწორება, განახლება,
                  დამატება, დაბლოკვა, წაშლა ან განადგურება თუ ისინი არასრულია,
                  არაზუსტია, არ არის განახლებული ან თუ მათი შეგროვება და
                  დამუშავება განხორციელდა კანონის საწინააღმდეგოდ.
                </li>
                <li>
                  მოითხოვოთ თქვენი მონაცემების დამუშავების შეწყევტა პირდაპირი
                  მარკეტინგის მიზნებისთვის.
                </li>
                <li>
                  გაიხმოთ თქვენს მიერ პერსონალური მონაცემების დამუშავებაზე
                  გაცხადებული თანხმობები.
                </li>
                <li>
                  პერსონალურ მონაცემთა დამუშავებასთან დაკავშირებული პრეტენზიები
                  დააფიქსიროთ პერსონალურ მონაცემთა დაცვის სააგენტოში;
                </li>
                <li>
                  კანონმდებლობით დადგენილი ვადაში მოითხოვოთ ჩვენ მიერ
                  დამუშავებული ინფორმაციის გაცნობა ან ასლის მიღება.
                </li>
                <li>
                  უარი განაცხადოთ ავტომატური საშუალებით გადაწყვეტილების მიღების
                  პროცესში თქვენი მონაცემების გამოყენებაზე (მათ შორის
                  პროფაილინგზე).
                </li>
              </ul>
              <p>
                ჩვენ ვმოქმედებთ საქართველოს კანონმდებლობის შესაბამისად, რამაც
                შესაძლოა ხელი შეგვიშალოს დაუყოვნებლივ წავშალოთ თქვენი
                პერსონალური მონაცემები. ასეთი ვალდებულებები შესაძლოა
                გამომდინარეობდეს საგადასახადო კანონმდებლობის, მომხმარებელთა
                უფლებების დაცვისა და სხვა რელევანტური კანონმდებლობიდან.
              </p>
              <p>
                პერსონალური მონაცემების გამოყენების უფლება გვაქვს იმ
                შემთხვევაში, როცა ამისათვის გაგვაჩნია თქვენი თანხმობა,
                კონკრეტული სახელშეკრულებო ან/და კანონისმიერი საფუძველი.
              </p>
              <p>
                მნიშვნელოვანია, რომ კომპანიის მიერ თქვენი პერსონალური
                მონაცემების დამუშავება არ არის თქვენთვის ზიანის მომტანი.
              </p>
              <p>
                არასრულწლოვნის პერსონალური მონაცემების დამუშავება დასაშვებია
                მხოლოდ საქართველოს კანონმდებლობისა და არასრულწლოვნის საუკეთესო
                ინტერესების გათვალისწინებით.
              </p>
              <p>
                პერსონალურ მონაცემებთან დაკავშირებით მოთხოვნის დაფიქსირების
                საჭიროების შემთხვევაში მოგვმართეთ შემდეგი ელექტრონული ფოსტის
                მისამართზე&nbsp;{" "}
                <a href="mailto:Personaldata@tnet.ge">Personaldata@tnet.ge</a>
              </p>
              <h3>შეგროვებული მონაცემების სახეობა და მისი გამოყენება</h3>
              <p>ჩვენ ვაგროვებთ შესახებ შემდეგი სახის&nbsp; ინფორმაციას:</p>
              <ul>
                <li>თქვენი სახელი და გვარი;</li>
                <li>ელექტრონული ფოსტის მისამართი;</li>
                <li>ტელეფონის ნომერი;</li>
                <li>პირადი ნომერი;</li>
                <li>მისამართი;</li>
                <li>სქესი;</li>
                <li>დაბადების თარიღი;</li>
                <li>
                  ანგარიშის ნომერი (როცა ნივთის რეალიზაციას ახდენთ ონლაინ);
                </li>
                <li>აუდიომონიტორინგის ჩანაწერები;</li>
                <li>ელექტრონული საკომუნიკაციო საშუალებებით მიმოწერა;</li>
                <li>არაელექტრონული სახის მიმოწერები/დოკუმენტები;</li>
                <li>IP მისამართი;</li>
                <li>
                  სხვა დამატებითი ნებაყოფლობით ან სავალდებულო შესავსები
                  ინფორმაცია თქვენ შესახებ, რაც გასაზღვრულია სხვადასხვა სერვისის
                  ფარგლებში.
                </li>
              </ul>
              <p>
                როდესაც თქვენ უკვეთავთ პროდუქტებს ან მომსახურებას, ჩვენ შეიძლება
                ასევე გთხოვოთ, წარმოადგინოთ თქვენი პლასტიკური ბარათის ნომერი,
                ვადის გასვლის თარიღი, აუთენტურობის კოდი ან სხვა ინფორმაცია.
                გადახდა ხდება ჩვენი პარტნიორი ბანკის უსაფრთხოების სერვერზე. ჩვენ
                ვინახავთ მხოლოდ თქვენი პლასტიკური ბარათის შემდეგ ინფორმაციას:
                ბარათის ტიპი და ვალიდურობის მაიდენტიფიცირებელი პირველი ექვსი და
                ბოლო ოთხი ციფრი. იმის მიხედვით, თუ რა სახის აქტივობაში
                ჩაერთვებით, ზოგიერთი ინფორმაციის წარმოდგენა იქნება სავალდებულო,
                ზოგიერთის კი – ნებაყოფლობითი. თუ თქვენ არ წარადგენთ კონკრეტული
                აქტივობისთვის სავალდებულო ინფორმაციას, თქვენ ვერ ჩაერთვებით ამ
                აქტივობაში.
              </p>
              <p>
                როდესაც თქვენ უკვეთავთ პროდუქტებს ან მომსახურებას, თქვენ ასევე
                შესაძლებლობა გაქვთ თქვენს ანგარიშზე (პროფილზე) დაიმახსოვროთ
                პლასტიკური ბარათის ინფორმაცია შემდგომი შეკვეთების მარტივად
                გაფორმების მიზნით. ბარათის დამახსოვრებაზე თანხმობის გაცხადების
                შემთხვევაში, ჩვენ შევინახავთ თქვენი საგადახდო ბარათის შემდეგ
                ინფორმაციას: ბარათის ტიპი და ვალიდურობის მაიდენტიფიცირებელი
                პირველი ექვსი და ბოლო ოთხი ციფრი.&nbsp;&nbsp;
              </p>
              <h3>„მზა ჩანაწერები“ (Cookies)</h3>
              <p>
                <strong>&nbsp;</strong>ჩვენი ვებგვერდის
                მომხმარებელთა/ვიზიტორების ქცევის მონიტორინგს ვახორციელებთ ე.წ.
                „მზა ჩანაწერების" (cookies-ის) საშუალებით იმისათვის, რომ
                ვიზიტორებს გავუადვილოთ ვებგვერდით სარგებლობა და გავაუმჯობესოთ
                მისი ფუნქციონირების ხარისხი.
              </p>
              <p>
                „მზა ჩანაწერები" მცირე ზომის ფაილებია, რომლებიც საიტზე ვიზიტისას
                ინახება მომხმარებლის კომპიუტერში, პლანშეტში ან მობილურ
                მოწყობილობაში. ისინი რჩება თქვენს მოწყობილობაში და საიტს&nbsp;
                უბრუნდება, როცა კვლავ ეწვევით იმავე მისამართს.
              </p>
              <p>
                ჩვენს პლატფორმებზე ვაგროვებთ ინფორმაციას Cookies-ების,
                მომხმარებლების ვებგვერდზე ნავიგაციისა და ქცევის შესახებ, კერძოდ:
              </p>
              <ul>
                <li>
                  "IP მისამართი, მოწყობილობის ტიპი, ოპერაციული სისტემა, ეკრანის
                  რეზოლუცია, ბრაუზერი (ტიპი და სხვა ტექნიკური მახასიათებლები)
                  საიდანაც ხდება ვებგვერდზე შემოსვლა;
                </li>
                <li>
                  ვაგროვებთ ინფორმაციას ჩვენს ვებგვერდზე გახსნილი გვერდების,
                  სესიის ხანგრძლივობისა და სესიის სხვადასხვა პარამეტრების
                  შესახებ;
                </li>
                <li>
                  ინფორმაცია იმ ქმედებების შესახებ, რომელიც ჩვენს ვებგვერდზე
                  მოხდა: ფორმების შევსება, ვებგვერდის ინტერაქციული ელემენტების
                  გამოყენება და ა.შ.
                </li>
                <li>ვებგვერდზე ველების შევსების პროცესი, დრო და ფორმა;</li>
              </ul>
              <p>
                &nbsp;პლატფორმის გამოყენების დაწყებამდე, თქვენ გაქვთ საშუალება
                დაეთანხმოთ Cookies და მომხმარებელთა ქცევაზე დაკვირვების შესახებ
                შეტყობინებას „თანხმობა“ ღილაკზე დაჭერით.
              </p>
              <p>
                თუ გსურთ შეზღუდოთ Cookie ფაილები ან დაბლოკოთ ისინი ჩვენს
                პლატფორმაზე, ამის განხორციელება შეგიძლიათ თქვენი ბრაუზერის
                პარამეტრების შეცვლით. ამასთან, გთხოვთ, გაითვალისწინოთ რომ, თუ არ
                დაეთანმხებით „<u>Cookie პოლიტიკ</u>
                <u>ას</u>“ ან დაბლოკავთ ან წაშლით Cookie-ს ფაილებს, ჩვენ მიერ
                შემოთავაზებულმა ზოგიერთმა სერვისმა/ფუნქციონალმა შესაძლოა არ
                იფუნქციონიროს სრულყოფილად, რამაც შესაძლოა თქვენთვის გამოიწვიოს
                ჩვენი სერვისის მიღების სრულად ან ნაწილობრივ შეუძლებლობა.
              </p>
              <p>
                თუ გსურთ, უკეთ გაიგოთ, როგორ ვიყენებთ „მზა ჩანაწერებს", ჩვენს
                საიტზე იხ. ჩვენი „Cookie პოლიტიკა".
              </p>
              <p>
                ჩვენ ან/და ჩვენ მიერ უფლებამოსილ მესამე მხარე მომსახურების
                პროვაიდერებს და მარეკლამირებელ პირებს შეუძლიათ ავტომატურად
                მიიღონ ზემოაღნიშნული ინფორმაცია იმ დროს, როდესაც თქვენ
                სარგებლობთ ჩვენი პლატფორმ/ებ/ით. ეს ხორციელდება ისეთი
                ელექტრონული მექანიზმების მეშვეობით, როგორიცაა „Cookies“, „Web
                Beacons“, „Pixel Tags“.
              </p>
              <h3>
                როგორ ვიყენებთ თქვენს მონაცემებს ავტომატური გადაწყვეტილებების
                მიღების პროცესში
              </h3>
              <p>
                ავტომატური გადაწყვეტილების მისაღებად, მათ შორის
                პროფილირებისთვის, ჩვენ ზოგჯერ ვიყენებთ პერსონალურ მონაცემებს,
                რომლებიც გვაქვს, ან გვაქვს უფლება შევაგროვოთ სხვა სუბიექტებისგან
                კანონმდებლობის, თქვენთან გაფორმებული ხელშეკრულების ან თქვენი
                თანხმობის საფუძველზე. აღნიშნული გვეხმარება დავრწმუნდეთ იმაში,
                რომ ჩვენი გადაწყვეტილებები არის სწრაფი, სამართლიანი და ეფექტური.
                ავტომატიზირებულმა გადაწყვეტილებებმა შესაძლოა გავლენა იქონიოს
                ჩვენს მიერ ახლა ან მომავალში შემოთავაზებულ/შემოსათავაზებელ
                პროდუქტებზე და სერვისზე. თუ არ არსებობს ზემოაღნიშნული საფუძველი
                (საკანონმდებლო, სახელშეკრულებო, თქვენი თანხმობა), შეგიძლიათ უარი
                თქვათ თქვენი მონაცემების ავტომატურ დამუშვებაზე, მათ შორის,
                პროფილირების მიზნით, თუ აღნიშნული წარმოშობს სამართლებრივ,
                ფინანსურ ან სხვა სახის არსებითი მნიშვნელობის შედეგს.&nbsp;
              </p>
              <p>ჩვენ ვიღებთ შემდეგი სახის ავტომატურ გადაწყვეტილებებს:</p>
              <ul>
                <li>ფასდადება</li>
              </ul>
              <p>
                ჩვენ შეგვიძლია მივიღოთ ზოგიერთ პროდუქტზე და სერვისის ფასზე
                გადაწყვეტილება ჩვენთვის ხელმისაწვდომი ინფორმაციის საფუძველზე.
              </p>
              <ul>
                <li>
                  პროდუქტებისა და სერვისების მომხმარებელთა საჭიროებებზე მორგება
                </li>
              </ul>
              <p>
                ჩვენს მომხმარებლებს ვაჯგუფებთ გარკვეული სეგმენტების მიხედვით.
                ამგვარ დაჯგუფებას ვიყენებთ მომხმარებლების საჭიროებების
                შესასწავლად და მათთვის სასარგებლო და ხელსაყრელი
                გადაწყვეტილებების მისაღებად. პროდუქტებისა თუ მომსახურების
                შემუშავებისა და შეთავაზებისას ვითვალისწინებთ მომხმარებლების
                სხვადასხვა სეგმენტის მახასიათებლებს, რაც მათთან ურთიერთობის
                სწორად წარმართვაში გვეხმარება.&nbsp;
              </p>
              <ul>
                <li>პერსონალიზებული შეთავაზებებისთვის</li>
              </ul>
              <p>
                თქვენი პერსონალური მონაცემები გვეხმარება, დავადგინოთ თქვენი
                სავარაუდო ინტერესები და მათი გათვალისწინებით შემოგთავაზოთ
                თქვენზე მორგებული მარკეტინგული შეთავაზებები.
              </p>
              <ul>
                <li>სხვა</li>
              </ul>
              <p>
                კომპანიის/პარტნიორი კომპანიების სერვისების/პროდუქტების
                შეთავაზებისა და მიწოდების მიზნით.
              </p>
              <h3>პერსონალური მონაცემების ჯგუფები</h3>
              <p>ჩვენ თქვენს პერსონალურ მონაცემებს ვაჯგუფებთ შემდეგნაირად:</p>
              <table
                style={{ width: "100%" }}
                border="1"
                width="100%"
                cellspacing="10"
                cellpadding="10"
              >
                <tbody>
                  <tr>
                    <td width="347">
                      <p>პერსონალური მონაცემების ტიპები</p>
                    </td>
                    <td width="360">
                      <p>აღწერილობა</p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>საკონტაქტო</p>
                    </td>
                    <td width="360">
                      <p>
                        თქვენს მიერ რეგისტრაციისას ან შეკვეთის გაფორმებისას
                        მითითებული მისამართი და საკონტაქტო ინფორმაცია.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>სახელშეკრულებო</p>
                    </td>
                    <td width="360">
                      <p>
                        დეტალური ინფორმაცია ჩვენ მიერ თქვენთვის მიწოდებული
                        პროდუქტებისა და გაწეული მომსახურების შესახებ
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>ლოკაციური</p>
                    </td>
                    <td width="360">
                      <p>
                        მონაცემები თქვენს ადგილსამყოფელთან დაკავშირებით, რომელიც
                        შესაძლოა კომპანიამ მოიპოვოს თქვენი მობილური ტელეფონის
                        საშუალებით, კომპიუტერის ინტერნეტში ჩართვის მისამართიდან
                        ან იმ ობიექტის ადგილმდეგარეობიდან, სადაც ახორციელებთ
                        ოპერაციებს.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>ქცევითი</p>
                    </td>
                    <td width="360">
                      <p>
                        დეტალური ინფორმაცია იმის შესახებ, თუ როგორ იყენებთ ჩვენს
                        პროდუქტებს და მომსახურებას.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>ტექნოლოგიური</p>
                    </td>
                    <td width="360">
                      <p>
                        ინფორმაცია იმ ტექნიკა/ მოწყობილობების შესახებ, რომლებსაც
                        იყენებთ ჩვენი პროდუქტების/სერვისების/პლატფორმების
                        სარგებლობისას.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>საკომუნიკაციო</p>
                    </td>
                    <td width="360">
                      <p>
                        ინფორმაცია თქვენს შესახებ, რომელსაც კომპანია მოიპოვებს
                        როგორც მატერიალური ფორმის ასევე ელექტრონული წერილებიდან,
                        სატელეფონო და სხვა სახის საკომუნიკაციო არხებიდან, როგორც
                        თქვენსა და ჩვენ შორის, ასევე თქვენსა და ჩვენს
                        პარტნიორ(ებ)ს* შორის განხორციელებულ კომუნიკაციისას.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>ფინანსური და ტრანზაქციული</p>
                    </td>
                    <td width="360">
                      <p>
                        ინფორმაცია პლატფორმაზე თქვენი &nbsp;გადახდებისა,
                        ბალანსისა და დავალიანების თაობაზე.
                      </p>
                      <p>
                        ანგარიშის ნომერი, როცა ახდენთ ნივთის ონლაინ რეალიზაციას.
                      </p>
                      <p>
                        საგადახდო ბარათის შესახებ ინფორმაცია (პირველი ექვსი და
                        ბოლო ოთხი ციფრი).
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>ღია წვდომის მონაცემები და საჯარო ჩანაწერები</p>
                    </td>
                    <td width="360">
                      <p>
                        საჯარო წყაროებში თქვენს შესახებ არსებული ინფორმაცია, მათ
                        შორის, საჯარო საარჩევნო სიებში არსებული ინფორმაცია,
                        ასევე, სხვა ინფორმაცია, რომელიც ინტერნეტში კანონიერად
                        არის ხელმისაწვდომი
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>სოციალურ ურთიერთობებთან დაკავშირებული</p>
                    </td>
                    <td width="360">
                      <p>
                        ინფორმაცია თქვენი ოჯახის წევრების და საკონტაქტო პირების
                        შესახებ, რომელსაც უთითებთ შეკვეთის გაფორმების დროს.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="347">
                      <p>დოკუმენტური მონაცემები</p>
                    </td>
                    <td width="360">
                      <p>
                        სხვადასხვა ფორმატის დოკუმენტებსა და მათ ასლებში ასახული
                        ინფორმაცია თქვენს შესახებ. ასეთ დოკუმენტებს მიეკუთვნება
                        თქვენი პასპორტი, პირადობის დამადასტურებელი საბუთი,
                        დაბადების მოწმობა, ავტომობილის მართვის მოწმობა,
                        ავტომობილის ტექ პასპორტი/VIN კოდი და პიროვნების
                        მაიდენტიფიცირებელი სხვა დოკუმენტი.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>
                რისთვის ვიყენებთ თქვენს პერსონალურ მონაცემებს და ჩვენი მიზანი:
              </h3>
              <ul>
                <li>თქვენთან ურთიერთობისთვის;</li>
                <li>
                  თქვენზე მორგებული კომპანიისა თუ კომპანიის პარტნიორი
                  კომპანიების ეფექტური მარკეტინგული აქტივობების შესამუშავებლად,
                  შემოსათავაზებლად და განსახორციელებლად;
                </li>
                <li>
                  ჩვენი ან/და ჩვენი პარტნიორი კომპანიების გათამაშებებში ან სხვა
                  სახის ბენეფიტურ აქტივობებში ჩართვისათვის.
                </li>
                <li>
                  თქვენი მოთხოვნების დასაკმაყოფილებლად, თქვენთვის საინტერესო
                  პროდუქტებისა და მომსახურების იდენტიფიცირებისა და ამ
                  ინფორმაციის თქვენთვის მოწოდებისთვის, თანამშრომლობის ახალი
                  გზების შესამუშავებლად და ჩვენი ბინზესის განსავითარებლად;
                </li>
                <li>
                  იმის შესასწავლად, თუ როგორ იყენებენ მომხმარებლები ჩვენს
                  პროდუქტებსა და მომსახურებას და მარკეტინგული თუ სხვა
                  აქტივობების ეფექტურობის შესაფასებლად;
                </li>
                <li>
                  ჩვენს პროდუქტებთან/მომსახურებასთან დაკავშირებით რჩევებისა თუ
                  რეკომენდაციების მისაღებად ან/და პროდუქტების/მომსახურების
                  გაუმჯობესების მიზნით სხვადასხვა კვლევის ჩასატარებლად;
                </li>
                <li>
                  ჩვენი პლატფორმების, ბრენდების, პროდუქტებისა და მომსახურების
                  განსავითარებლად/სამართავად და სტატისტიკის წარმოებისთვის;
                </li>
                <li>ჩვენი პროდუქტებისა და მომსახურების მოსაწოდებლად;</li>
                <li>
                  მომხმარებლის გადახდების განსახორციელებლად და სამართავად;
                </li>
                <li>
                  მომხმარებლისთვის შეთავაზებული ან მომხმარებლის მიერ ჩვენს
                  პლატფორმებზე მოპოვებული ბენეფიტების მიღების უზრუნველსაყოფად;
                </li>
                <li>
                  ფინანსური დანაშაულის გამოსავლენად, პრევენციისა და ანგარიშგების
                  საწარმოებლად;
                </li>
                <li>ჩვენი და ჩვენი მომხმარებლების რისკების სამართავად;</li>
                <li>
                  ჩვენთვის რელევანტური კანონებისა და რეგულაციების შესასრულებლად;
                </li>
                <li>
                  საჩივრებზე/პრეტენზიებზე რეაგირებისა და მოგვარების გზების
                  მოსაძიებლად;
                </li>
                <li>
                  მინიჭებული უფლებებისა და ვალდებულებების განსახორციელებლად;
                </li>
                <li>
                  ვებგვერდის მომხმარებლების დაცვისა და თაღლითური ქმედებების
                  პრევენციისთვის;
                </li>
                <li>
                  ფულის გათეთრების, ტერორიზმის დაფინანსებისა და სხვა
                  დანაშაულებრივი ღონისძიებების თავიდან აცილების მიზნით;
                </li>
                <li>
                  ვებგვერდის და მისი კომპონენტების ადაპტირებისთვის მომხმარებლის
                  საჭიროებების მიხედვით;
                </li>
                <li>
                  თქვენი საჭიროებების და სურვილების თაობაზე პარტნიორ(ებ)ის
                  ინფორმირებისთვის;
                </li>
              </ul>
              <h3>მონაცემთა დამუშავების ჩვენი ლეგიტიმური ინტერესები:</h3>
              <ul>
                <li>
                  მონაცემთა განახლება, თქვენთვის საინტერსო პროდუქტებისა და
                  მომსახურების იდენტიფიცირება და თქვენთვის შესაბამისი
                  ინფორმაციის მოწოდება;
                </li>
                <li>პროდუქტებისა და მომსახურების განვითარება;</li>
                <li>
                  პროდუქტებისა და მომსახურების განვითარების მიზნით
                  პარტნიორ(ებ)ის ინფორმირება თქვენი საჭიროებების და სურვილების
                  თაობაზე;
                </li>
                <li>
                  ახალი პროდუქტებისა თუ მომსახურების შეთავაზებისთვის
                  მომხმარებელთა ფოკუს ჯგუფების განსაზღვრა;
                </li>
                <li>
                  ჩვენი კანონიერი მოვალეობების და სახელშეკრულებო ვალდებულებების
                  ეფექტურად შესრულება;
                </li>
                <li>
                  ჩვენთვის რელევანტურ რეგულაციებთან შესაბამისობის უზრუნველყოფა;
                </li>
                <li>
                  ფინანსური დანაშაულის წინააღმდეგ მიმართული ღონისძიებების
                  შემუშავება და გაუმჯობესება, ასევე ამ თვალსაზრისით კანონისმიერი
                  მოვალეობების შესრულება
                </li>
                <li>
                  პროდუქრებისა და მომსახურების განვითარების მიზნით კვლევების
                  ჩატარება.
                </li>
              </ul>
              <h3>მონაცემთა დამუშავების ჩვენი სამართლებრივი საფუძველი:</h3>
              <ul>
                <li>თქვენი თანხმობა;</li>
                <li>სახელშეკრულებო ვალდებულებათა შესრულება;</li>
                <li>ჩვენი კანონიერი ინტერესები;</li>
                <li>ჩვენი კანონისმიერი ვალდებულება;</li>
              </ul>
              <h3>წყაროები, საიდანაც მოვიპოვებთ პერსონალურ მონაცემებს</h3>
              <p>
                თქვენს შესახებ პერსონალური ინფორმაციის მოპოვება შეგვიძლია
                კომპანიისთვის თქვენ მიერ მოწოდებული და ქვემოთ ჩამოთვლილი
                წყაროებიდან. თქვენი მონაცემების მიღება ხდება შემდეგ
                შემთხვევებში:
              </p>
              <ul>
                <li>როდესაც ხდებით ჩვენი მომხმარებელი;</li>
                <li>
                  როდესაც რეგისტრირდებით ჩვენს ონლაინ
                  სერვისებზე/პლატფორმებზე/აპლიკაციაში;
                </li>
                <li>როდესაც იყენებთ ჩვენ პროდუქტებსა&nbsp;თუ მომსახურებებს;</li>
                <li>
                  როდესაც ჩვენი სერვისების/პროდუქტების/პლატფორმების შესახებ დებთ
                  განცხადებებს/კომენტარებს სოციალურ ქსელში, მათ შორის და
                  არამხოლოდ კომპანიის პლატფორმების გვერდებზე/აპლიკაციაში და სხვა
                  წყაროებში.
                </li>
                <li>სატელეფონო კომუნიკაციისას&nbsp;ან კომპანიაში ვიზიტისას;</li>
                <li>მომსახურების გაწევის დროს;</li>
                <li>
                  როდესაც იყენებთ ჩვენს ვებგვერდ/ებ/ს/პლატფორმას, მობილურ
                  აპლიკაციას და ონლაინ საკონსულტაციო ფუნქციონალს (ონლაინ ჩათს);
                </li>
                <li>
                  როდესაც გვიგზავნით წერილებს/დოკუმენტებს/შეტყობინებებს ფოსტით,
                  ელ. ფოსტით ან სოციალური ქსელის საშუალებით;
                </li>
                <li>
                  როდესაც მოგვმართავთ ჩვენი პროდუქტებისა თუ მომსახურების
                  მისაღებად პლატფორმაზე/ონლაინ ჩატით თუ სატელეფონო კომუნიკაციის
                  საშუალებით;
                </li>
                <li>შეკვეთის/სერვისის მოწოდების დროს;</li>
                <li>
                  როდესაც სარგებლობთ კომპანიის/კომპანიის პლატფორმების/აპლიკაციის
                  ან კომპანიასთან დაკავშირებული მომსახურებით;
                </li>
                <li>როდესაც მოგვმართავთ დასაქმების მიზნით;</li>
                <li>როდესაც აფიქსირებთ პრეტენზიას;</li>
                <li>
                  თქვენს მონაცემებს ასევე ვაგროვებთ ისეთი ორგანიზაციებიდან,
                  როგორებიცაა საჯარო რეესტრი, საგადახდო მომსახურების
                  პროვაიდერები, ფინანსური ინსტიტუტები, საჯარო ორგანიზაციები.
                </li>
                <li>
                  თქვენს მონაცემებს თქვენი ნებაყოფლობითი თანხმობით ან
                  კანონისმიერი საფუძვლით ასევე ვაგროვებთ პარტნიორი
                  კომპანიებისგან შემდეგ შემთხვევაში:
                  <ul>
                    <li>
                      მათ პლატფორმაზე რეგისტრაციისას თქვენ მიერ მითითებული
                      მონაცემების (მ.შ პერსონალური ინფორმაციის) შესახებ;
                    </li>
                    <li>
                      თქვენი პირადი ანგარიშით/პროფილით განხორციელებული
                      ოპერაციების შესახებ;
                    </li>
                    <li>
                      მათ პლატფორმაზე თქვენ მიერ განხორციელებული სხვა
                      აქტივობების შესახებ;
                    </li>
                    <li>
                      მათსა და თქვენს შორის კომპანიის
                      მომსახურებასთან/სერვისებთან დაკავშირებით განხორციელებული
                      წერილობითი თუ ზეპირი კომუნიკაციის შესახებ;
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>ვის ვუზიარებთ თქვენს პერსონალურ მონაცემებს</h3>
              <p>
                თქვენი პერსონალური მონაცემების გაზიარება შეიძლება დაგვჭირდეს
                საქართველოს კანონმდებლობით გათვალისწინებულ შემთხვევებში ან იმ
                ორგანიზაციებთან/პირებთან, რომლებმაც თქვენს მიერ შერჩეული
                პროდუქტი თუ მომსახურება უნდა მოგაწოდონ, მაგალითად მონაცემების
                გაზიარება შეიძლება მოხდეს:&nbsp;&nbsp;
              </p>
              <ul>
                <li>
                  თუ თქვენ შეიძინეთ ღონისძიების ბილეთი - ღონისძიების
                  ორგანიზატორთან;
                </li>
                <li>
                  თუ თქვენ შეიძინეთ ნივთი/პროდუქტი პლატფორმაზე - მოვაჭრესთან;
                </li>
                <li>
                  თუ თქვენ შეიძინეთ ფასდაკლების ვაუჩერი გარკვეულ სერვისზე -
                  სერვისის გამწევ ორგანიზაციასთან/პირთან;
                </li>
                <li>
                  თუ თქვენს შეძენილ ნივთს ესაჭიროება სერვის ცენტრის სერვისი -
                  სერვის ცენტრსა და მოვაჭრეს;
                </li>
                <li>
                  თუ პროდუქტის/სერვისის შეძენისთვის ირჩევთ სესხით გადახდას -
                  შესაბამის საფინანსო დაწესებულებას;
                </li>
                <li>
                  თუ თქვენ დაზღვევას, სესხის ან სხვა სახის მოთხოვნას ჩვენი
                  მეშვეობით ახორციელებთ, ჩვენ შესაძლოა თქვენი პერსონალური თუ
                  ბიზნეს მონაცემები გადავცეთ სადაზღვევო კომპანიას/ნებისმიერ
                  გადამზღვევს/მომსახურების გამწევ პირებს ან/და საფინანსო
                  დაწესებულებებს.
                </li>
                <li>
                  მოკლე ტექსტური შეტყობინებებით შეთავაზებების გაგზავნის
                  მიზნებისთვის პარტნიორ სატელეკომუნიკაციო (მობილურ)
                  ოპერატორ(ებ)ს.
                </li>
                <li>
                  თუ თქვენ ჩაერთვებით ჩვენსა და ჩვენი პარტნიორი კომპანიის
                  მარკეტინგულ აქტივობებში (გათამაშება, საჩუქრის მიღება და ა.შ.),
                  რომლის ფარგლებშიც მარკეტინგული აქტივობა უნდა აწარმოოს და
                  ბენეფიტი გადმოგცეთ პარტნიორმა კოპანიამ - აღნიშნულ აქტივობაში
                  ჩართულ პარტნიორ კომპანიასთან.
                </li>
              </ul>
              <p>
                იმ შემთხვევაში, როდესაც&nbsp; ჩვენი ძირითადი&nbsp; საქმიანობის
                ფარგლებში ვიყენებთ მესამე მხარეების, სხვა პროვაიდერის
                მომსახურებას, შესაძლებელია, დაგვჭირდეს მათთვის თქვენი
                პერსონალური მონაცემების გაზიარება კონკრეტული დავალებების
                შესასრულებლად. მომსახურების მომწოდებელ მესამე პირებთან
                თანამშრომლობის მაგალითებია:
              </p>
              <ul>
                <li>
                  ვებ-ინსტრუმენტების ან/და აპლიკაციების დიზაინი, მიმდინარე
                  საექსპლუატაციო მომსახურება;
                </li>
                <li>
                  IT სერვისის პროვაიდერები, რომლებსაც შეუძლიათ უზრუნველყონ
                  აპლიკაციის ან ინფრასტრუქტურის(მაგ., „cloud") სერვისები;
                </li>
                <li>
                  იურიდიული, აუდიტორული და სხვა პროფესიული მომსახურება,
                  რომლებსაც გვაწვდიან იურისტები, ნოტარიუსები, მინდობილი პირები,
                  აუდიტორული კომპანიები და სხვ.;
                </li>
                <li>
                  თაღლითობის და სხვა სახის დანაშაულისა თუ გადაცდომის გამოვლენა,
                  მოკვლევა და პრევენცია, რასაც ახორციელებენ სპეციალიზებული
                  კომპანიები;
                </li>
                <li>
                  საკურიერო სერვისის პროვაიდერები, რომლებმაც უნდა უზრუნველყონ
                  თქვენთან ნივთის ტრანსპორტირება;
                </li>
              </ul>
              <p>
                თქვენი პერსონალური მონაცემების გაზიარება შეიძლება მოხდეს იმ
                შემთხვევაშიც თუ თუ სამომავლოდ შეიცვლება კომპანიის
                სტრუქტურა.&nbsp; მაგალითად:&nbsp;
              </p>
              <ul>
                <li>
                  იმ შემთხვევაში თუ კომპანია გადაწყეტს გაყიდოს ან გაასხვისოს
                  საკუთარი აქტივები, ან განახორციელოს შერწყმის ოპერაცია.
                </li>
                <li>
                  ზემოხსენებული რომელიმე პროცესის განხორციელების შემთხვევაში
                  ჩვენ შესაძლოა გავაზიაროთ თქვენი მონაცემები შესაბამის
                  მხარესთან, იმ პირობით, რომ ხსენებული მხარე წინასწარ
                  სავალდებულოდ იკისრებს თქვენი მონაცემების უსაფრთხოებისა და
                  კონფიდენციალურობის დაცვის ვალდებულებას.
                </li>
              </ul>
              <p>
                თუ კომპანიის სტრუქტურის შეცვლის შემთხვევაში სხვა მხარეებს
                შეუძლიათ გამოიყენონ თქვენი მონაცემები, აღნიშნული უნდა
                განხორციელდეს ამ წინამდებარე პოლიტიკითა და კანონით განსაზღვრული
                წესითა და ფარგლებში.
              </p>
              <p>
                ნებისმიერ იმ შემთხვევაში, როდესაც&nbsp; ხდება თქვენი მონაცემების
                მესამე მხარისთვის გაზიარება,&nbsp; ჩვენ ვიღებთ ყველა ზომას იმ
                მიზნით, რომ უზრუნველყოფილ იყოს მათი დაცულობა.
              </p>
              <p>
                ჩვენ უფლებას ვიტოვებთ კანონით მკაცრად განსაზღვრულ შემთხვევებში
                სამართალდამცავ თუ სხვა სახის ორგანოებს/ორგანიზაციებს მივცეთ
                წვდომა თქვენს პირად მონაცემებზე/გადავცეთ მონაცემები დანაშაულის
                დადგენის, გამოძიების, აღკვეთის თუ სხვა სამართლებრივი მიზნით.
                ჩვენ, ასევე, ვიტოვებთ უფლებას, გავცეთ თქვენი ინფორმაცია
                მომხმარებლების ან სხვათა უფლებების, საკუთრების ან უსაფრთხოების
                დაცვის მიზნით.
              </p>
              <p>
                თუ არ ისურვებთ პირადი ინფორმაციის გაცემას, შესაძლოა, შეფერხდეს
                ან შეუძლებელი გახდეს ჩვენ მიერ თქვენ წინაშე ნაკისრი
                ვალდებულებების შესრულება, მათ შორის ანგარიშის/პროფილის
                გამოყენება ან შესაბამისი მომსახურებების მიღება.
              </p>
              <p></p>
              <p>
                <strong>მესამე პირები (აპლიკაციები/სერვისები):</strong>თქვენი
                ინფორმაცია მოწყობილობის ნომრის, მოდელის, ბრენდის, სახელის,
                საოპერაციო სისტემის ვერსიისა და აპლიკაციის ვერსიის შესახებ
                გადაეცემა ისეთ აპლიკაციებს, როგორიცაა: Google Analytics,
                Matomo.org, Facebook Pixel და Hotjar.com, ჩვენ ვიყენებთ{" "}
                <a href="https://vwo.com/platform/">VWO Analyze tool</a>, რომლის
                საშუალებითაც ვამუშავებთ ინფორმაციას ვებგვერდზე/აპლიკაციაში
                თქვენი ქცევის შესახებ ჩვენი მომსახურების გაუმჯობესების მიზნით
                <strong>.</strong>
              </p>
              <h3>GDPR-ის საფუძველზე არსებული სპეციალური ღონისძიებები</h3>
              <p>
                იმ შემთხვევაში, თუ ხდება თქვენი პერსონალური მონაცემების
                გაზიარება ევროკავშირისა და ევროპის ეკონომიკური სივრცის მიღმა,
                კომპანია გადადგამს ყველა ნაბიჯს მონაცემების უსაფრთხოდ და ამ
                კონფიდენციალურობის პოლიტიკის შესაბამისად დამუშავების
                უზრუნველსაყოფად და მონაცემთა საერთაშორისო გადაცემას კომპანია
                განახორციელებს კანონმდებლობის დაცვით.
              </p>
              <p>
                აღნიშნული შესაძლოა განხორციელდეს სხვადასხვა გზით, მაგალითად:
              </p>
              <ul>
                <li>
                  ქვეყანა, რომელთანაც ხდება ინფორმაციის გაზიარება სარგებლობს
                  დაცვის სათანადო გარანტიებით ევროკომისიის გადაწყვეტილების
                  შესაბამისად;
                </li>
                <li>
                  ევროკომისიის მიერ გადაწყვეტილების არარსებობის შემთხვევაში,
                  პერსონალური მონაცემები შეიძლება გადავცეთ მესამე ქვეყანას ან
                  საერთაშორისო ორგანიზაციას მხოლოდ იმ შემთხვევაში თუ მივიღებთ
                  შესაბამის ზომებს GDPR-ის შესაბამისად. ამასთან, თქვენ შეგიძლიათ
                  მიიღოთ ინფორმაცია შესაბამისი ზომების თაობაზე წინამდებარე
                  პოლიტიკაში არსებული საკომუნიკაციო არხების მეშვეობით
                </li>
              </ul>
              <p>
                ევროკომისიის გადაწყვეტილების არარსებობისას ან შესაბამისი
                გარანტიების, მათ შორის, სავალდებულო კორპორატიული წესების
                არარსებობის შემთხვევაში,&nbsp; პერსონალური მონაცემების მესამე
                ქვეყნისთვის ან საერთაშორისო ორგანიზაციისთვის გადაცემა
                ხორციელდება მხოლოდ შემდეგ შემთხვევებში:
              </p>
              <ul>
                <li>
                  მონაცემთა სუბიექტი ცალსახად დაეთანხმა შემოთავაზებულ გადაცემას,
                  მას შემდეგ, იგი ინფორმირებული იქნება გადაცემასთან
                  დაკავშირებული რისკებისა თაობაზე რაც ხორციელედება ევროკომისიის
                  გადაწყვეტილებისა და შესაბამისი დაცვის გარანტიების არარსებობის
                  გამო;
                </li>
                <li>
                  გადაცემა აუცილებელია მონაცემთა სუბიექტსა და მონცემთა
                  დამმუშავებელს შორის არსებული ხელშეკრულების შესასრულებლად ან
                  მონაცემთა სუბიექტის მოთხოვნით წინასწარი სახელშეკრულებო
                  ღონისძიებების განსახორციელებლად;
                </li>
                <li>
                  გადაცემა აუცილებელია მონაცემთა სუბიექტის ინტერესებში დადებული
                  ხელშეკრულების დადების ან შესრულებისთვის, რომელიც დადებულია
                  მონაცემთა დამმუშავებელსა და სხვა ფიზიკურ ან იურიდიულ პირს
                  შორის;
                </li>
                <li>
                  გადაცემა აუცილებელია საზოგადოებრივი ინტერესიდან გამომდინარე;
                </li>
                <li>
                  გადაცემა აუცილებელია სამართლებრივი მოთხოვნების განხორციელების
                  ან დაცვის მიზნით;
                </li>
                <li>
                  გადაცემა აუცილებელია მონაცემთა სუბიექტის ან სხვა პირების
                  სასიცოცხლო ინტერესების დასაცავად და მონაცემთა სუბიექტი
                  მოკლებულია თანხმობის გამოხატვის შესაძლებლობას.
                  <strong>&nbsp;</strong>
                </li>
              </ul>
              <h3>პერსონალური მონაცემების უსაფრთხოება</h3>
              <p>
                ჩვენ განსაკუთრებული პასუხისმგებლობით ვეკიდებით უსაფრთხოების
                საკითხს და ვატარებთ არაერთ ზომას პირადი საიდენტიფიკაციო
                ინფორმაციის დასაცავად.
              </p>
              <p>
                თქვენი პირადი საიდენტიფიკაციო ინფორმაცია ჩვენს ვებგვერდზე
                ხელმისაწვდომია მხოლოდ თქვენი პაროლისა და ელექტრონული ფოსტის
                მისამართის მითითების შემთხვევაში. ეს პაროლი დაშიფრულია. ჩვენ
                გირჩევთ, რომ არავის გაანდოთ თქვენი პაროლი. გარდა ამისა, თქვენი
                პირადი საიდენტიფიკაციო ინფორმაცია ინახება დაცულ სერვერზე,
                რომელზეც მხოლოდ შესაბამის პერსონალსა და კონტრაქტორებს მიუწვდებათ
                ხელი.
              </p>
              <p>
                სამწუხაროდ, მონაცემთა ნებისმიერი გადაცემა ინტერნეტის ან უკაბელო
                ქსელის საშუალებით შეუძლებელია იყოს აბსოლუტურად და გარანტირებულად
                უსაფრთხო. ჩვენ ვატარებთ კომერციულად გონივრულ უსაფრთხოების
                ზომებს, რათა დავიცვათ მონაცემები და ასევე ვცდილობთ კავშირები
                დავამყაროთ მხოლოდ ისეთ ორგანიზაციებთან, რომლებიც იგივენაირად
                უდგებიან ამ საკითხს. თუმცა ჩვენ ვერ გავცემთ რაიმე გარანტიას
                ვებგვერდისათვის ან ვებგვერდიდან გადაცემული ინფორმაციის
                უსაფრთხოებაზე და არ ვაგებთ პასუხს მესამე მხარის ქმედებაზე,
                რომელმაც შეიძლება უნებართვოდ მიიღოს მსგავსი ინფორმაცია.
              </p>
              <h3>თაღლითობის გამოვლენა</h3>
              <p>
                თქვენი პერსონალური მონაცემები გვეხმარება, დავადგინოთ, შესაძლოა
                თუ არა თქვენი ანგარიში/პროფილი გამოყენებულ იქნას თაღლითობის თუ
                ფულის გათეთრების მიზნებისათვის. ჩვენ მიერ თაღლითობის რისკის
                დაფიქსირების შემთხვევაში, უფლებას ვიტოვებთ, თქვენივე
                უსაფრთხოებისთვის დროებით შევაჩეროთ ტრანზაქციები/შეკვეთები ან
                უარი განვაცხდოთ შესაბამისი მომსახურების გაწევაზე.
              </p>
              <h3>პერსონალური მონაცემების დაცვის სხვადასხვა საკითხი</h3>
              <p>
                18 წლამდე არასრულწლოვან პირებს ეკრძალებათ კომპანიის
                პლატფორმ/ებ/ით/ვებგვერდებით/აპლიკაციებით&nbsp; სარგებლობა. ჩვენ
                განზრახ არ ვინახავთ ან ვაგროვებთ ინფორმაციას 18 წლამდე
                პირებისაგან. არასრულწლოვანთა პირადი ინფორმაციის დაცვა ჩვენთვის
                ძალიან მნიშვნელოვანია. შესაბამისად, თუ ჩვენ გავიგებთ, რომ
                მომხმარებელი არის 18 წლამდე ასაკის პირი, ჩვენ გავატარებთ
                შესაბამის ღონისძიებებს, რათა წავშალოთ ამ მომხმარებლის პირადი
                ინფორმაცია ჩვენი მონაცემთა ბაზიდან.&nbsp; სარგებლობთ რა შპს
                „თინეთის“ პლატფორმით/ვებგვერდებით თქვენ აქვე ადასტურებთ, რომ
                ხართ სულ მცირე 18 წლის.
              </p>
              <h3 id="1">საჯარო ადგილები</h3>
              <p>
                ჩვენ შეგვიძლია ჩვენს ვებგვერდზე გამოვყოთ ადგილები, სადაც თქვენ
                შეძლებთ თქვენ შესახებ ინფორმაციის საჯაროდ განთავსებას, ასევე
                სხვებთან კომუნიკაციას ფორუმების ან ბლოგების მეშვეობით,
                პროდუქტების დათვალიერებას, კონტენტის გაგზავნას და ა.შ.. ეს
                ინფორმაცია ხელმისაწვდომი იქნება სხვა მომხმარებლებისა და
                ორგანიზაციებისათვის და შეიძლება, ასევე, გამოჩნდეს სხვა
                ვებგვერდებსა და ინტერნეტის საძიებო სისტემებში. შესაბამისად, ეს
                ინფორმაცია შეიძლება ხელმისაწვდომი გახდეს, შეგროვდეს და
                გამოყენებულ იქნეს სხვების მიერ. მაგალითად, თუ თქვენ მიუთითებთ
                თქვენს ელექტრონულ ფოსტას რომელიმე პროდუქტთან ან/და
                მომსახურებასთან დაკავშირებით საჯარო კომენტარის გაკეთებისას,
                თქვენ შეიძლება მიიღოთ არასასურველი ელექტრონული ფოსტა. ჩვენ ვერ
                ვახორციელებთ კონტროლს იმაზე, თუ ვინ კითხულობს თქვენ მიერ
                ვებგვერდზე საჯაროდ და ნებაყოფლობით დადებულ ინფორმაციას და ვინ
                როგორ გამოიყენებს მას. ამიტომ, გთხოვთ, გამოიჩინოთ სიფრთხილე
                თქვენი ნებისმიერი პირადი ინფორმაციის ვებგვერდზე საჯაროდ
                განთავსებისას. როგორც წესი, ჩვენ მოვიპოვებთ ნებართვას ჩვენი
                მომხმარებლისაგან, სანამ მის სახელს მივუთითებთ მისი კომენტარის
                გასწვრივ.
              </p>
              <h3>„Facebook ავტორიზაცია“</h3>
              <p>
                კომპანიის ვებგვერდი შესაძლებელს ხდის მომხმარებლებისათვის
                „Facebook“-ზე შესვლას, რათა მათ იურთიერთონ მეგობრებთან და
                გაუზიარონ მათ ინფორმაცია „Facebook“-ის კედლისა და მეგობართა
                სიახლეების დაფის მეშვეობით. თუ თქვენ დარეგისტრირებული ხართ
                კომპანიის ვებგვერდზე და „Facebook“-ზე და დააჭერთ „Facebook
                ავტორიზაციას“-ს, თქვენი ორივე პროფილი შეერთდება, გარდა იმ
                შემთხვევისა, თუ “Facebook”-სა და შპს „თინეთზე“ რეგისტრაციისას
                მითითებული თქვენი ელექტრონული ფოსტის მისამართები არ დაემთხვევა
                ერთმანეთს. თუ თქვენი ელექტრონული ფოსტის მისამართები ერთმანეთს არ
                ემთხვევა, ჩვენ გკითხავთ, გსურთ თუ არა მათი შეერთება. მაშინ თქვენ
                შეიყვანთ თქვენს პაროლს, რათა დაადასტუროთ, რომ „Facebook“
                აკონტროლებს ამ ანგარიშს/პროფილს. თუ თქვენ დარეგისტრირებული ხართ
                კომპანიის ვებგვერდზე, მაგრამ არა „Facebook“-ზე და დააჭერთ
                „Facebook ავტორიზაციას“-ს, თქვენ შემოგთავაზებენ „Facebook“
                მანდატის შექმნას ან „Facebook“–ზე დარეგისტრირებას. თუ თქვენ
                გააგრძელებთ, ხელმისაწვდომს გახდით თქვენს ინფორმაციას კომპანიის
                ვებგვერდისათვის და ამავე დროს კომპანიის ვებგვერდების
                გამოყენებისას დაეთანხმებით „Facebook“–ით სარგებლობის პირობებს.
              </p>
              <p>
                მომხმარებელმა უნდა გაითვალისწინოს, რომ საიტზე სამუშაოს
                დასრულების შემდეგ არ მოხდება სისტემიდან ავტომატურად გამოსვლა და
                სესიის დასრულებამდე მისი მონაცემები იქნება ხელმისაწვდომი მესამე
                პირისთვის. აღნიშნულთან დაკავშირებით ნებისმიერი პასუხისმგებლობა
                სრულად დაეკისრება მომხმარებელს.
              </p>
              <h3>„Google ავტორიზაცია“</h3>
              <p>
                კომპანიის ვებგვერდი შესაძლებელს ხდის მომხმარებლებისათვის
                „Google“-ის ელექტრონულ ფოსტაზე შესვლას, რათა მათ იურთიერთონ
                მეგობრებთან და გაუზიარონ მათ ინფორმაცია „Google“-ის ელექტრონული
                ფოსტის მეშვეობით. თუ თქვენ დარეგისტრირებული ხართ კომპანიის
                ვებგვერდზე და გაქვთ „Google“-ის ელექტრონული ფოსტა და დააჭერთ
                „Google“-ს, თქვენი ორივე პროფილი შეერთდება, გარდა იმ
                შემთხვევისა, თუ “Google”-სა და შპს „თინეთზე“ რეგისტრაციისას
                მითითებული თქვენი ელექტრონული ფოსტის მისამართები არ დაემთხვევა
                ერთმანეთს. თუ თქვენი ელექტრონული ფოსტის მისამართები ერთმანეთს არ
                ემთხვევა, ჩვენ გკითხავთ, გსურთ თუ არა მათი შეერთება. მაშინ თქვენ
                შეიყვანთ თქვენს პაროლს, რათა დაადასტუროთ, რომ „Google“
                აკონტროლებს ამ ანგარიშს/პროფილს. თუ თქვენ დარეგისტრირებული ხართ
                კომპანიის ვებგვერდზე, მაგრამ არა „Google“-ზე და დააჭერთ „Google
                ავტორიზაციას“, თქვენ შემოგთავაზებენ „Google“ მანდატის შექმნას ან
                „Google“–ის ელექტრონული ფოსტის დარეგისტრირებას. თუ თქვენ
                გააგრძელებთ, ხელმისაწვდომს გახდით თქვენს ინფორმაციას კომპანიის
                ვებგვერდისათვის და ამავე დროს კომპანიის ვებგვერდების
                გამოყენებისას დაეთანხმებით „Google“–ით სარგებლობის პირობებს.
              </p>
              <p>
                მომხმარებელმა უნდა გაითვალისწინოს, რომ საიტზე სამუშაოს
                დასრულების შემდეგ არ მოხდება სისტემიდან ავტომატურად გამოსვლა და
                სესიის დასრულებამდე მისი მონაცემები იქნება ხელმისაწვდომი მესამე
                პირისთვის. აღნიშნულთან დაკავშირებით ნებისმიერი პასუხისმგებლობა
                სრულად დაეკისრება მომხმარებელს.
              </p>
              <h3>პერსონალური მონაცემების შენახვის ვადა</h3>
              <p>
                თქვენს პირად მონაცემებს ვინახავთ თქვენი მომსახურების მთელი
                პერიოდის განმავლობაში. აგრეთვე, მომსახურების დასრულების შემდეგ
                10 წლის ვადით შემდეგი მიზეზების გამო:
              </p>
              <ul>
                <li>კითხვებსა და საჩივრებზე პასუხის გასაცემად;</li>
                <li>თქვენ მიმართ სამართლიანი მოპყრობის დადასტურების მიზნით;</li>
                <li>
                  საქმისწარმოების განხორციელების მიზნებისთვის ჩვენ
                  საქმიანობასთან მიმართებით არსებული რეგულაციის ფარგლებში;
                </li>
                <li>
                  კანონმდებლობით განსაზღვრული ვალდებულებების შესასრულებლად.
                </li>
              </ul>
              <p>
                თქვენი მონაცემების შენახვა შესაძლებელია მოხდეს 10 წელზე მეტი
                ვადით იმ შემთხვევაში, თუ მოკლებული ვართ მისი წაშლის
                შესაძლებლობას შესაბამისი სამართლებრივი საფუძვლების გამო.
              </p>
              <h3>მონაცემთა პირდაპირი მარკეტინგის მიზნისთვის დამუშავება</h3>
              <p>
                თქვენს პერსონალურ მონაცემებს ვიყენებთ მარკეტინგული
                მიზნებისთვისაც - მათი ცოდნა გვჭირდება თქვენზე მორგებული ჩვენი
                ან/და ჩვენი პარტნიორი ორგანიზაციების მომსახურების ან/და
                პროდუქტის შეთავაზების თქვენთვის გასაცნობად.
              </p>
              <p>
                წინამდებარე კონფიდენციალობის პოლიტიკის ფარგლებში „პარტნიორი
                ორგანიზაცია“ წარმოადგენს იურიდიულ ან ფიზიკურ პირს, რომელიც
                უზრუნველყოფს/ხელს უწყობს თქვენთვის სერვისის/პროდუქტის
                შემოთავაზებასა და მომსახურების გაწევას. კომპანიის პარტნიორი
                ორგანიზაციები შეიძლება იყოს შემდეგი კატეგორიის:
              </p>
              <ul>
                <li>საყოფაცხოვრებო ნივთებით მოვაჭრე ორგანიზაციები;</li>
                <li>არასაყოფაცხოვრებო ნივთებით მოვაჭრე ორგანიზაციები;</li>
                <li>ფინანსური დაწესებულებები;</li>
                <li>სადაზღვევო დაწესებულებები;</li>
                <li>ტანსაცმელით და აქსესუარებით მოვაჭრე ორგანიზაციები;</li>
                <li>ტექნიკით მოვაჭრე ორგანიზაციები;</li>
                <li>დეველოპერული ორგანიზაციები;</li>
                <li>კვების პროდუქტის/სერვისის ორგანიზაციები;</li>
                <li>ავტობიზნესის ან/და ავტონაწილებით მოვაჭრე ორგანიზაციები;</li>
                <li>სუპერმარკეტები;</li>
                <li>
                  სპორტული პროდუქტების/სერვისების/ღონისძიებების მომწოდებელი
                  ორგანიზაციები;
                </li>
                <li>კულტურული ან გასართობი ღონისძიებების ორგანიზატორები;</li>
                <li>სხვა სახის ორგანიზაციები.</li>
              </ul>
              <h3>
                მონაცემთა პირდაპირი მარკეტინგის მიზნით შეწყვეტის
                მოთხოვნა/თანხმობის გახმობა
              </h3>
              <p>
                თქვენ უფლება გაქვთ ნებისმიერ დროს მოგვწეროთ და გვთხოვოთ
                მარკეტინგული შეტყობინებების გამოგზავნის შეწყვეტა. ჩვენ პატივს
                ვცემთ თქვენს სურვილებს და ასეთი შეტყობინების მიღებისთანავე
                კანონით განსაზღვრულ ვადაში შევწყვეტთ თქვენი პირადი მონაცემების
                გამოყენებას პირდაპირი მარკეტინგის მიზნებისათვის. კერძოდ:
              </p>
              <ul>
                <li>
                  თუ თქვენ არ გსურთ მიიღოთ კომპანიის პარტნიორი ორგანიზაციების
                  მარკეტინგული შეთავაზებები, მოგვმართეთ ელექტრონულ ფოსტაზე:{" "}
                  <a href="mailto:info@tnet.ge">PersonalData@tnet.ge</a>;
                  წერილობითი შეტყობინებით PARTNEROFFERS-
                </li>
                <li>
                  თუ თქვენ არ გსურთ მიიღოთ კომპანიის პლატფორმების მარკეტინგული
                  შეთავაზებები კომპანიის რომელიმე პლატფორმიდან/აპლიკაციიდან,
                  მოგვმართეთ ელექტრონულ ფოსტაზე:{" "}
                  <a href="mailto:info@tnet.ge">PersonalData@tnet.ge</a>;
                  წერილობითი შეტყობინებით და მიუთითეთ “OFF” და ის პლატფორმა,
                  რომლის შეთავაზების მიღებაც აღარ გსურთ: მაგ. GE- OFF ;
                  MYJOBS.GE-OFF და ა.შ.
                </li>
                <li>
                  თუ თქვენ საერთოდ არ გსურთ მიიღოთ კომპანიის რომელიმე პლატფორმის
                  მარკეტინგული შეთავაზება - მოგვმართეთ ელექტრონულ ფოსტაზე:{" "}
                  <a href="mailto:info@tnet.ge">PersonalData@tnet.ge</a>;
                  წერილობითი შეტყობინებით და მიუთითეთ PLATFORMOFFERS-OFF.
                </li>
                <li>
                  თუ თქვენ არ გსურთ მიიღოთ კომპანიის ან კომპანიის პარტნიორი
                  ორგანიზაციების მარკეტინგული შეთავაზებები, მოგვმართეთ
                  ელექტრონულ ფოსტაზე:{" "}
                  <a href="mailto:info@tnet.ge">PersonalData@tnet.ge</a>;
                  წერილობითი შეტყობინებით და მიუთითეთ ALLOFFERS- OFF.
                </li>
              </ul>
              <p>
                ჩვენ წვდომა გვაქვს თქვენს პირად მონაცემებზე იმ ფარგლებში, რასაც
                თავად გვიზიარებთ ან ჩვენ ვაგროვებთ თქვენთვის მომსახურების
                გაწევის პროცესში ჩვენს ხელთ არსებული საშუალებებით.
              </p>
              <p>
                ჩვენ შევისწავლით თქვენს მონაცემებს, რათა გავიგოთ, რა გჭირდებათ,
                რა გაინტერესებთ, ან რა სურვილები შეიძლება გქონდეთ. ასე ვწყვეტთ,
                რომელი პროდუქტი, მომსახურება თუ შეთავაზება შეიძლება იყოს
                თქვენთვის შესაფერისი.
              </p>
              <p>
                ჩვენ მხოლოდ მაშინ ვიყენებთ თქვენს პირად მონაცემებს და გიგზავნით
                მარკეტინგულ შეტყობინებებს, როცა გვაქვს ამის ლეგიტიმური ინტერესი.
              </p>
              <h3>როგორ გაითხოვოთ თქვენი თანხმობა</h3>
              <ul>
                <li>
                  თანხმობის გათხოვა შეგიძლიათ ნებისმიერ დროს, თუ ეს არ
                  ეწინააღმდეგება კანონის მოთხოვნებს.
                </li>
                <li>
                  თანხმობის გახმობისთვის დაგვიკავშირდით, თუ ამგვარ
                  გადაწყვეტილებას მიიღებთ.
                </li>
                <li>
                  გამოთხოვის უფლება მაშინ არის შესაძლებელი( ან რელევანტური),
                  როცა დამუშავების საფუძველი არის თქვენი თანხმობა.
                </li>
                <li>
                  თანხმობის გათხოვის შემთხვევაში, ჩვენ შესაძლოა ვერ შევძლოთ
                  თქვენთვის სრულყოფილი მომსახურების გაწევა.
                </li>
                <li>
                  თუ თქვენ ხართ კომპანიის სხვადასხვა
                  პლატფორმის/ვებგვერდის/აპლიკაციის (
                  <a href="http://www.vendoo.ge/">ge</a>;{" "}
                  <a href="http://www.livo.ge/">livo.ge</a>;{" "}
                  <a href="http://www.swoop.ge/">swoop.ge</a>;{" "}
                  <a href="http://www.mymarket.ge/">mymarket.ge</a>;
                  <a href="http://www.myshop.ge/"> myshop.ge</a>; myauto.ge;
                  <a href="http://www.myparts.ge/"> myparts.ge</a>;
                  <a href="http://www.myhome.ge/"> myhome.ge</a>; myads.ge;
                  myjobs.ge; tkt.ge და აპლიკაციას Super App (
                  <a href="https://superapp.tnet.ge/">
                    https://superapp.tnet.ge/
                  </a>
                  )-ს. და სხვ.)&nbsp; მომხმარებელი და გსურთ წერილობითი
                  შეტყობინებით უარი განაცხადოთ ან შეზღუდოთ ჩვენ მიერ თქვენი
                  პერსონალური მონაცემების დამუშავებაზე, შეტყობინებაში მიუთითეთ
                  დეტალური ინფორმაცია თუ კომპანიის რომელ
                  პლატფორმაზე/ვებგვერდზე/აპლიკაციაში რომელ პერსონალური მონაცემის
                  დამუშავებაზე აცხადებთ უარს და რა სახით გსურთ ინფორმაციის
                  დამუშავების შეზღუდვა.
                </li>
                <li>
                  თუ თქვენ ხართ შპს „თინეთის“ სხვადასხვა
                  პლატფორმის/ვებგვერდის/აპლიკაციის მომხმარებელი თქვენ შეგიძლიათ
                  მოითხოვოთ თქვენი მომხმარებლის ანგარიშის/პროფილის გაუქმება
                  კომპანიის წესებისა და პირობებით{" "}
                  <strong>(ანგარიშის/პროფილის დახურვის პროცედურა)</strong>{" "}
                  განსაზღვრული წესის დაცვით.
                </li>
              </ul>
              <h3>კონფიდენციალობის პოლიტიკის ცვლილება</h3>
              <p>
                კონფიდენციალობის პოლიტიკის დოკუმენტი შეიძლება შეიცვალოს
                საკანონმდებლო ან/და ჩვენი პერსონალურ მონაცემთა დამუშავების წესის
                ცვლილების შესაბამისად. კონფიდენციალობის პოლიტიკაში
                განხორციელებული ნებისმიერი ცვლილება დაუყოვნებლივ გამოქვეყნდება
                კომპანიის პლატფორმა/ებ/ზე /აპლიკაციაში. ამასთან,
                კონფიდენციალობის პოლიტიკაში შეტანილი ნებისმიერი მნიშვნელოვანი
                ცვლილების შესახებ გეცნობებათ ელექტრონული ფოსტის ან თქვენი
                პროფილის გვერდზე შეტყობინებების სივრცეში ამ ჩვენს მთავარ გვერდზე
                შესაბამისი შეტყობინების გამოქვეყნების გზით.
              </p>
              <h3>როგორ დაგვიკავშირდეთ</h3>
              <p>
                იმ შემთხვევაში, თუ თქვენ მოითხოვთ თქვენი უფლებების რელიაზებას
                (მონაცემთა შესწორება, განახლება, დამატება, დაბლოკვა, წაშლა,
                განადგურება და ა.შ.), შეგიძლიათ მოგვწეროთ ელ.ფოსტაზე:{" "}
                <a href="mailto:Personaldata@tnet.ge">Personaldata@tnet.ge</a>.
              </p>
              <p>
                გარდა ამისა, პერსონალური მონაცემებთან დაკავშირებულ საკითხებთან
                მიმართებით შეგიძლიათ მიმართოთ პერსონალურ მონაცემთა დაცვის
                ოფიცერს:
              </p>
              <p>პერსონალურ მონაცემთა დაცვის ოფიცერი: მარიამ მიქელთაძე</p>
              <p>
                ელექტრონული ფოსტა:{" "}
                <a href="mailto:Personaldata@tnet.ge">Personaldata@tnet.ge</a>.
              </p>
              <p>ტელეფონი: 514600080</p>
              <p>&nbsp;</p>
              <p>
                <em>
                  კონფიდენციალობის პოლიტიკის ელექტრონულად მონიშვნით/აღიარებით
                  თქვენ ადასტურებთ, თქვენ მიერ მოწოდებული ინფორმაცია წარმოადგენს
                  ზუსტ და უტყუარ მონაცემებს. ინფორმაციის მოწოდება მოხდა თქვენი
                  საკუთარი სურვილით და ამისათვის თქვენ გაგაჩნიათ კანონმდებლობით
                  გათვალისწინებული ყველა უფლება და ნებართვა.
                </em>
              </p>
              <p>
                <em>
                  ასევე, წინამდებარე პირობებზე თანხმობის ელექტრონულად
                  მონიშვნით/აღიარებით თანხმობას აცხადებთ, რომ თქვენ
                  უფლებამოსილებას გვანიჭებთ ჩვენ და თანხმობას აცხადებთ მასზედ,
                  რომ ჩვენ მიერ საკუთარი შეხედულებისამებრ და მოქმედი
                  კანონმდებლობის ფარგლებში, ნებისმიერ დროს და ნებისმიერი
                  რაოდენობით, ნებისმიერი წყაროდან გამოთხოვნილ და მიღებულ იქნას
                  ინფორმაცია თქვენ შესახებ, მათ შორის პერსონალური მონაცემები,
                  მათი გაცნობის, გადამოწმების, შედარების, ანალიზის, შენახვის და
                  საკანონმდებლო ან/და სახელშეკრულებო ვალდებულებ(ებ)ის შესრულების
                  მიზნებისთვის.
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
