import axiosInstance from "../axiosApi/api";
import { handleServerError } from "./errors";
import {
  GET_BANNERS,
  GET_WORKERS,
  GET_AD_POSTS,
  CLEAR_AD_POSTS,
} from "./types";

export const getBannersAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/management/banners/");
      dispatch({
        type: GET_BANNERS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getWorkersAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/users/workers/");
      dispatch({
        type: GET_WORKERS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getAdPostsAction = (postType) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/management/ad/post/${postType}/`);
      dispatch({
        type: GET_AD_POSTS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const clearAdPostsAction = () => ({
  type: CLEAR_AD_POSTS,
  payload: null,
});
