import React, { useState } from "react";
import useComponentVisible from "../hooks/useComponentVisible";
import { useTranslation } from "react-i18next";

const DropDownInput = ({
  name,
  fieldName,
  options,
  item,
  handleOptionClick,
  search = false,
  backendValues = [],
  customClassNameSelectBtn = "",
  customClassNameSelecMenuDropDown = "",
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  const [dropDownRef, isVisable, setIsVisable] = useComponentVisible(false);
  const [selectedOption, setSelectedOption] = useState(name);

  const changeActiveState = () => {
    if (isVisable === "active") {
      setIsVisable("");
    } else {
      setIsVisable("active");
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const renderOptions = () => {
    const filteredOptions = options.filter((item) =>
      t(item).toLowerCase().includes(searchValue.toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return (
        <div className="filter-default-text">{t("filter default text")}</div>
      );
    }
    return filteredOptions.map((option, index) => {
      return (
        <li
          key={option}
          onClick={() => {
            if (backendValues.length > index) {
              handleOptionClick(fieldName, backendValues[index]);
            } else {
              handleOptionClick(fieldName, option);
            }
            setSelectedOption(option);
          }}
          readOnly={true}
        >
          {t(option)}
          {fieldName === "age" ? ` ${t("month")}` : t(item)}
        </li>
      );
    });
  };
  return (
    <div ref={dropDownRef} className="wrapper" onClick={changeActiveState}>
      <div className={`select-button ${isVisable} ${customClassNameSelectBtn}`}>
        <div>{t(selectedOption)}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="user-arrow"
          viewBox="0 0 8 6"
        >
          <path
            stroke="#BEBFC3"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M1 1.5l3 3 3-3"
          ></path>
        </svg>
      </div>

      <div
        className={`select-menu-dropdown ${isVisable} ${customClassNameSelecMenuDropDown}`}
      >
        {search ? (
          <div className="select-search">
            <input
              type="text"
              placeholder={t("search")}
              onChange={handleSearchChange}
            />
          </div>
        ) : (
          <></>
        )}
        <ul className="options">{renderOptions()}</ul>
      </div>
    </div>
  );
};

export default DropDownInput;
