import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Filter from "../components/Filter";
import SecondFilter from "../components/SecondFilter";
import RightContianer from "../components/RightContainer";
import {
  getAdPostsAction,
  clearAdPostsAction,
} from "../actions/managementActions";
import AdPostCard from "../components/AdPostCard";

const AdPost = ({ getAdPostsAction, adPosts, clearAdPostsAction }) => {
  const { t } = useTranslation();
  const { postType } = useParams();
  useEffect(() => {
    getAdPostsAction(postType);

    // Clean up function to clear clinics when the component unmounts
    return () => {
      clearAdPostsAction();
    };
  }, [postType]);

  const renderAdPosts = () => {
    if (adPosts) {
      return adPosts.map((adPost) => {
        return <AdPostCard data={adPost} />;
      });
    }
  };
  <Filter />;
  return (
    <>
      <div className="parent-container-short">
        <div className="main-container">
          <div className="left-container flex">
            <SecondFilter />
            <div className="company-container">
              <div className="card-3-container">{renderAdPosts()}</div>
            </div>

            <div className="sticky-banner-container">
              <img src="../../img/sticky-banner.jpg" alt="sticky-banner" />
            </div>
          </div>
          <RightContianer />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    adPosts: state.management.adPosts,
  };
};

export default connect(mapStateToProps, {
  getAdPostsAction,
  clearAdPostsAction,
})(AdPost);
