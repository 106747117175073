import React from "react";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-container">
      <div className="contact-info">
        <h3>{t("contact")}</h3>
        <div>
          <div className="contact-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5">
                <path d="M14 2s2.2.2 5 3 3 5 3 5M14.207 5.536s.99.282 2.475 1.767c1.485 1.485 1.768 2.475 1.768 2.475M10.038 5.316l.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0 0 0 0 0s-1.12 1.119.91 3.148c2.027 2.027 3.146.91 3.147.91 0 0 0 0 0 0 .923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.149-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4.043-1.133.694-2.159 1.53-2.996 1.394-1.393 3.616-1.206 4.5.38z"></path>
              </g>
            </svg>
          </div>
          <div className="contact-text">
            <p>{t("contact_phone")}</p>
            <p>tel, tel</p>
          </div>
        </div>

        <div>
          <div className="contact-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g stroke="#1C274C" strokeWidth="1.5">
                <path d="M18 6.102c1.3.127 2.175.416 2.828 1.07C22 8.343 22 10.229 22 14c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14c0-3.771 0-5.657 1.172-6.828C3.825 6.518 4.7 6.229 6 6.102"></path>
                <path
                  strokeLinecap="round"
                  d="M10 6h4M11 9h2M8.159 11.8l-.72-.6c-.707-.59-1.061-.885-1.25-1.288C6 9.508 6 9.048 6 8.126V7c0-2.357 0-3.536.732-4.268C7.464 2 8.643 2 11 2h2c2.357 0 3.535 0 4.268.732C18 3.464 18 4.643 18 7v1.127c0 .92 0 1.381-.189 1.785-.189.403-.543.698-1.25 1.287l-.72.6c-1.836 1.53-2.755 2.296-3.841 2.296-1.086 0-2.004-.765-3.841-2.296z"
                ></path>
                <path
                  strokeLinecap="round"
                  d="M6 10l2.159 1.8c1.837 1.53 2.755 2.295 3.841 2.295 1.086 0 2.005-.765 3.841-2.296L18 10"
                ></path>
              </g>
            </svg>
          </div>
          <div className="contact-text">
            <p>{t("email")}:</p>
            <p>Info@pethub.ge</p>
          </div>
        </div>

        <div>
          <div className="contact-img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g stroke="#fff" strokeWidth="1.5">
                <path d="M4 10.143C4 5.646 7.582 2 12 2s8 3.646 8 8.143c0 4.462-2.553 9.67-6.537 11.531a3.45 3.45 0 01-2.926 0C6.553 19.812 4 14.605 4 10.144z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </g>
            </svg>
          </div>
          <div className="contact-text">
            <p>{t("address")}:</p>
            <p>{t("contact_address")}</p>
          </div>
        </div>
      </div>

      <iframe
        className="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d186.51340796705193!2d44.75112291931962!3d41.72575275427055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044731c00148307%3A0x7b14dccd00a97e12!2z4YOi4YOU4YOl4YOc4YOd4YOh4YOU4YOg4YOV4YOY4YOh4YOY!5e0!3m2!1ska!2sge!4v1684177885860!5m2!1ska!2sge"
        width="600"
        height="400"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contact;
