import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import UserPanel from "../components/UserPanel";
import QRCollarContainer from "../components/QRCollarContainer";
import { getMyQrsAction } from "../actions/orderActions";

const GenerateQR = ({ getMyQrsAction, myQRS }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getMyQrsAction();
  }, []);

  const renderQRS = () => {
    if (myQRS !== null) {
      return myQRS.map((qr) => {
        return <QRCollarContainer qr={qr} />;
      });
    }
    return (
      <div className="empty-container">
        <img src="/img/empty1.png" alt="" />
        <p>{t("you have not purchased tags")}</p>
      </div>
    );
  };
  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="generate-qr-container">
          {/* <div className="qr-collar-order-buttons-container">
            <Link to="/order/qrcollar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                >
                  <path d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9M15 2h2.5C19.99 2 22 4.01 22 6.5V9M22 16v1.5c0 2.49-2.01 4.5-4.5 4.5H16M9 22H6.5C4.01 22 2 19.99 2 17.5V15M8.5 11a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM7.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.5 18a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                </g>
              </svg>
              {t("buy now")}
            </Link>

            <Link to="/order/qrcollar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="1.5"
                >
                  <path d="M19.97 10h-16v8c0 3 1 4 4 4h8c3 0 4-1 4-4v-8zM21.5 7v1c0 1.1-.53 2-2 2h-15c-1.53 0-2-.9-2-2V7c0-1.1.47-2 2-2h15c1.47 0 2 .9 2 2zM11.64 5H6.12a.936.936 0 01.03-1.3l1.42-1.42a.96.96 0 011.35 0L11.64 5zM17.87 5h-5.52l2.72-2.72a.96.96 0 011.35 0l1.42 1.42c.36.36.37.93.03 1.3z"></path>
                  <path d="M8.94 10v5.14c0 .8.88 1.27 1.55.84l.94-.62a1 1 0 011.1 0l.89.6a.997.997 0 001.55-.83V10H8.94z"></path>
                </g>
              </svg>
              {t("gift a friend")}
            </Link>
          </div> */}

          <div className="purchased-collars">
            <div className="purchased-collars-header">
              <p>{t("purchased collars")}</p>
            </div>

            <div className="purchased-collars-cards-container">
              {renderQRS()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    myQRS: state.orders.myQRS,
  };
};
export default connect(mapStateToProps, { getMyQrsAction })(GenerateQR);
