import {
  GET_CHAT_USERS,
  GET_CHAT_USER_MESSAGES,
  ADD_NEW_CHAT_USER,
  CLEAR_CHAT_USERS,
  SEND_MESSAGE,
  CHANGE_CURRENT_CHAT_USER_ID
} from "../actions/types";

const INITIAL_STATE = {
  chatUsers: [],
  currentChatMessages: [],
  newChatUser: {},
  currentChatUserID: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHAT_USERS:
      action.payload.sort((a, b) => {
        if (a.has_unseen_conversation && !b.has_unseen_conversation) {
          return -1; // a comes before b
        }
        if (!a.has_unseen_conversation && b.has_unseen_conversation) {
          return 1; // b comes before a
        }
        return 0; // no change in ordering
      });
      if (Object.keys(state.newChatUser).length > 0) {
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].id === state.newChatUser.id) {
            return { ...state, chatUsers: action.payload };
          }
        }
        return { ...state, chatUsers: [state.newChatUser, ...action.payload] };
      }
      return {
        ...state,
        chatUsers: action.payload,
        currentChatUserID:
          action.payload.length > 0 ? action.payload[0].id : null,
      };
    case GET_CHAT_USER_MESSAGES:
      return { ...state, currentChatMessages: action.payload };
    case ADD_NEW_CHAT_USER:
      return {
        ...state,
        newChatUser: action.payload,
        currentChatUserID: action.payload.id,
      };
    case CLEAR_CHAT_USERS:
      return { ...state, chatUsers: [] };
    case CHANGE_CURRENT_CHAT_USER_ID:
      return { ...state, currentChatUserID: action.payload }
    case SEND_MESSAGE:
      return {
        ...state,
        currentChatMessages: [...state.currentChatMessages, action.payload],
      };
    default:
      return state;
  }
};
