import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterField from "./FilterField";
import useComponentVisible from "../hooks/useComponentVisible";
import { CITIES } from "../utils";

const SecondFilter = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [cityRef, isCityVisable, setIsCityVisable] = useComponentVisible("");

  const handleOptionSelect = (field, option) => {
    const selectedOptionsForField = selectedOptions[field] || [];
    const isSelected = selectedOptionsForField.includes(option);

    if (isSelected) {
      // Remove the option if already selected
      const updatedOptions = selectedOptionsForField.filter(
        (item) => item !== option
      );
      setSelectedOptions((prevState) => {
        const newState = {
          ...prevState,
          [field]: updatedOptions,
        };
        if (updatedOptions.length === 0) {
          delete newState[field];
        }
        return newState;
      });
    } else {
      // Add the option if not selected
      setSelectedOptions((prevState) => ({
        ...prevState,
        [field]: [...selectedOptionsForField, option],
      }));
    }
  };

  return (
    <div className="filter-of-company">
      <form className="search-box" action="">
        <input type="search" name="search" placeholder={t("head search")} />
        <input type="submit" value="search" />
      </form>

      <div className="keyword-container">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <g stroke="#292D32" strokeLinecap="round" strokeWidth="1.5">
            <path
              strokeLinejoin="round"
              d="M4.17 15.3l4.53 4.53a4.78 4.78 0 006.75 0l4.39-4.39a4.78 4.78 0 000-6.75L15.3 4.17a4.75 4.75 0 00-3.6-1.39l-5 .24c-2 .09-3.59 1.68-3.69 3.67l-.24 5c-.06 1.35.45 2.66 1.4 3.61z"
            ></path>
            <path d="M9.5 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
          </g>
        </svg>

        <button class="keyword">24 / 7</button>
        <button class="keyword">{t("on call")}</button>
      </div>

      <div className="city-and-district-filter">
        <FilterField
          selectedOptions={selectedOptions["city"]}
          FiledRef={cityRef}
          isVisable={isCityVisable}
          setIsVisable={setIsCityVisable}
          field_name="city"
          options={CITIES}
          handleOptionClick={handleOptionSelect}
          search={true}
        />

        <FilterField
          selectedOptions={selectedOptions["city"]}
          FiledRef={cityRef}
          isVisable={isCityVisable}
          setIsVisable={setIsCityVisable}
          field_name="district"
          options={CITIES}
          handleOptionClick={handleOptionSelect}
          search={true}
        />
      </div>
    </div>
  );
};

export default SecondFilter;
