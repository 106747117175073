import axiosInstance from "../axiosApi/api";
import { toast } from "react-toastify";
import { generate_unique_file_names } from "../utils";
import { handleServerError } from "./errors";
import { getTransactionUrl } from "./orderActions";
import {
  DELETE_SINGLE_POST,
  GET_ACTIVE_POSTS,
  GET_CURRANCY_RATE,
  GET_POSTS,
  GET_SAVED_POSTS,
  GET_SAVED_POSTS_FOR_FAVORITE_PAGE,
  POSTS_FOR_CONTAINER,
  GET_SINGEL_ACTIVE_POST,
} from "./types";

const handlePostPrice = (formValues) => {
  if (formValues.price) {
    if (formValues.currency === "USD") {
      formValues.price_in_USD = formValues.price;
    } else {
      formValues.price_in_GEL = formValues.price;
    }
  }
  return formValues;
};

export const addNewPostAction = (formValues) => {
  return async (dispatch) => {
    try {
      var vip_days = null;
      formValues = handlePostPrice(formValues);
      const images = formValues.images;
      formValues.images = generate_unique_file_names(formValues.images);

      if (formValues.post_type !== "standard") {
        const arr = formValues.post_type.split(":");
        vip_days = arr[1];
        formValues.post_type = "VIP";
      }
      const toast_id = toast.loading("Please wait");
      const res = await axiosInstance.post("/posts/", formValues);
      for (let i = 0; i < images.length; i++) {
        await fetch(res.data.pre_signed_urls[i], {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: images[i],
        });
      }
      if (formValues.post_type === "VIP") {
        dispatch(
          getTransactionUrl(
            { days: vip_days, post: res.data.post_id },
            "vip-post",
          ),
        );
      }
      toast.update(toast_id, {
        render: "All is good",
        type: "success",
        isLoading: false,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getPostsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/posts/home/");
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getFilteredPosts = (formValues) => {
  return async (dispatch) => {
    try {
      const pageNumber = formValues.page || 1;
      const res = await axiosInstance.post(
        `/posts/filter/?page=${pageNumber}`,
        formValues,
      );
      dispatch({
        type: POSTS_FOR_CONTAINER,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getPostsByDepartment = (department, page) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(
        `/posts/filter/${department}/?page=${page}`,
      );
      dispatch({
        type: POSTS_FOR_CONTAINER,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const singlePostAction = (postID, method, ACTION_TYPE) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.request({
        method: method,
        url: `/posts/${postID}/`,
      });
      dispatch({
        type: ACTION_TYPE,
        payload: ACTION_TYPE === DELETE_SINGLE_POST ? postID : res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getSavedPostAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/posts/save/");
      dispatch({
        type: GET_SAVED_POSTS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const updateSavedPostsIDs = (postID, method, ACTION_TYPE) => {
  return async (dispatch) => {
    try {
      await axiosInstance({ method: method, url: `/posts/save/${postID}/` });
      dispatch({
        type: ACTION_TYPE,
        payload: postID,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getUserActivePostsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/posts/active/");
      dispatch({
        type: GET_ACTIVE_POSTS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getFavoritePostsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/posts/favorite/");
      dispatch({
        type: GET_SAVED_POSTS_FOR_FAVORITE_PAGE,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getCurrancyRate = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/users/currency/");
      dispatch({
        type: GET_CURRANCY_RATE,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const updatePostAction = (postID, formValues) => {
  return async (dispatch) => {
    try {
      if (formValues.department === "for_sale") {
        formValues = handlePostPrice(formValues);
      } else {
        delete formValues.price;
        delete formValues.currency;
        formValues.price_in_GEL = null;
        formValues.price_in_USD = null;
      }
      await axiosInstance.put(`/posts/active/${postID}/`, formValues);
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getSingleActivePost = (postID) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/posts/active/single/${postID}/`);
      if (res.data.price_in_USD) {
        res.data.price = res.data.price_in_USD;
        res.data.currency = "USD";
      } else if (res.data.price_in_GEL) {
        res.data.price = res.data.price_in_GEL;
        res.data.currency = "GEL";
      }
      dispatch({
        type: GET_SINGEL_ACTIVE_POST,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};
