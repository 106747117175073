import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FilterField = ({
  selectedOptions,
  FiledRef,
  isVisable,
  setIsVisable,
  field_name,
  options,
  handleOptionClick,
  search = false,
  customClassName = "",
  customDropDownClassName = "",
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  const changeActiveState = () => {
    if (isVisable === "active") {
      setIsVisable("");
    } else {
      setIsVisable("active");
    }
  };

  const renderCheckedOption = (item) => {
    if (selectedOptions) {
      return selectedOptions.includes(item);
    }
    return false;
  };

  const renderOptions = () => {
    const filteredOptions = options.filter((item) =>
      t(item).toLowerCase().includes(searchValue.toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return (
        <div className="filter-default-text">{t("filter default text")}</div>
      );
    }
    return filteredOptions.map((item, i) => {
      return (
        <label key={item}>
          <input
            className="search-checkbox"
            type="checkbox"
            onClick={() => handleOptionClick(field_name, item)}
            checked={renderCheckedOption(item)}
            readOnly={true}
          />
          {field_name === "age" ? `${item} ${t("month")}` : t(item)}
        </label>
      );
    });
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const renderSelectedOptions = () => {
    if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
      // If selectedOptions is an array, join the values with a comma
      return selectedOptions.map((option) => t(option)).join(", ");
    }
    return t(field_name);
  };

  return (
    <div className="wrapper" ref={FiledRef}>
      <div
        className={`select-button ${isVisable} ${customClassName}`}
        onClick={changeActiveState}
      >
        <div>{renderSelectedOptions()}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="user-arrow"
          viewBox="0 0 8 6"
        >
          <path
            stroke="#BEBFC3"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M1 1.5l3 3 3-3"
          ></path>
        </svg>
      </div>

      <div
        className={`select-menu-dropdown  ${isVisable} ${customDropDownClassName}`}
      >
        {search ? (
          <div className="select-search">
            <input
              type="text"
              placeholder={t("search")}
              onChange={handleSearchChange}
            />
          </div>
        ) : (
          <></>
        )}

        <ul className="options">{renderOptions()}</ul>
      </div>
    </div>
  );
};

export default FilterField;
