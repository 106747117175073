import React from "react";
import { connect } from "react-redux";

import { submitRatingAction } from "../actions/authActions";
import Image from "./Image";

import { useTranslation } from "react-i18next";

const WorkerCard = ({ data, ratedIDs, submitRatingAction }) => {
  const { t } = useTranslation();
  const determineWorkerType = () => {
    if (data.worker_type === "BOTH") {
      return "petwalker / petsitter - for card";
    } else if (data.worker_type === "PET WALKER") {
      return "petwalker";
    } else {
      return "petsitter";
    }
  };

  const ratingData = ratedIDs.find(
    (rating) => rating.rated_user_id === data.id
  );
  const submitRating = (rating) => {
    submitRatingAction({ rating: rating, rated_user_id: data.id });
  };

  const renderStar = (index) => {
    if (ratingData !== undefined && index <= ratingData.rating) {
      return <i className="bi bi-star-fill active"></i>;
    } else if (!ratingData) {
      return (
        <i className="bi bi-star-fill" onClick={() => submitRating(index)}></i>
      );
    } else {
      return <i className="bi bi-star-fill disabled"></i>;
    }
  };

  return (
    <div className="card-4">
      <div className="walker-img">
        <Image src={data.profile_pic} />{" "}
      </div>
      <a className="walker-name">{t(determineWorkerType())}</a>
      <p className="walker-sitter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#000"
          className="icon flat-color"
          data-name="Flat Color"
          viewBox="0 0 24 24"
        >
          <g>
            <path
              fill="#000"
              d="M21 19a3 3 0 01-3 3H6a3 3 0 01-3-3 5 5 0 015-5h8a5 5 0 015 5zm-9-7a5 5 0 10-5-5 5 5 0 005 5z"
            ></path>
          </g>
        </svg>
        {t(data.first_name + " " + data.last_name)}
      </p>
      <p className="walker-phone">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path
            stroke="#292D32"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M21.97 18.33c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 01-3.28-2.8 28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
          ></path>
        </svg>
        {data.phone_number}
      </p>
      <p className="walker-email">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <g>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#212121" fillRule="nonzero">
                <path d="M43 16.976V33.75c0 2.9-2.35 5.25-5.25 5.25h-27.5A5.25 5.25 0 015 33.75V16.976l18.398 10.12c.375.206.83.206 1.204 0L43 16.975zM37.75 9a5.25 5.25 0 015.249 5.124L24 24.574 5.002 14.123l.002-.09A5.25 5.25 0 0110.25 9h27.5z"></path>
              </g>
            </g>
          </g>
        </svg>
        {data.email}
      </p>

      <hr />

      <div className="rating-stars">
        {/* <p>{t("rate")}</p> */}

        <span>
          {renderStar(1)}
          {renderStar(2)}
          {renderStar(3)}
          {renderStar(4)}
          {renderStar(5)}
        </span>

        {/* <p> - </p> */}

        <p> {data.avg_rating}</p>
      </div>
    </div>
  );
};

export default connect(null, { submitRatingAction })(WorkerCard);
