import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { logoutAction } from "../actions/authActions";
import Image from "./Image";

const UserPanel = ({ userData, logoutAction }) => {
  const { t } = useTranslation();
  const renderUserInfo = () => {
    if (userData) {
      return (
        <>
          <Image src={userData.profile_pic} />
          <Link className="user-fullname">
            {`${userData.first_name} ${userData.last_name}`}

            <br />

            <span>{t(`ID - ${userData.id ? userData.id : "..."}`)}</span>
          </Link>
        </>
      );
    }
  };
  return (
    <div className="userpanel-left-container">
      <div className="user-info-mini-container2">{renderUserInfo()}</div>

      <div className="left-menu">
        <ul className="center">
          <li>
            <Link to="/add/new/post">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M8 12h8M12 16V8M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"></path>
                </g>
              </svg>
              {t("Add post")}
            </Link>
          </li>

          <li>
            <Link to="/active/posts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"></path>
                  <path d="M18 7.75v6.75c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2V7.75c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2zM19 15.75h-1M6 15.75H5"></path>
                  <path d="M18 14v-3c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v3"></path>
                  <path d="M18 14.5v1.25h-3.5a2.5 2.5 0 01-5 0H6V14.5c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2z"></path>
                </g>
              </svg>
              {t("Active Posts")}
            </Link>
          </li>

          <li>
            <Link to="/me">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M8 2v3M16 2v3M7 13h8M7 17h5M16 3.5c3.33.18 5 1.45 5 6.15v6.18c0 4.12-1 6.18-6 6.18H9c-5 0-6-2.06-6-6.18V9.65c0-4.7 1.67-5.96 5-6.15h8z"></path>
                </g>
              </svg>
              {t("My Profile")}
            </Link>
          </li>

          <li>
            <Link to="/generateqr">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9M15 2h2.5C19.99 2 22 4.01 22 6.5V9M22 16v1.5c0 2.49-2.01 4.5-4.5 4.5H16M9 22H6.5C4.01 22 2 19.99 2 17.5V15M8.5 11a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM7.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.5 18a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                </g>
              </svg>
              {t("My QR-Collar")}
            </Link>
          </li>

          <li>
            <Link to="/favorite">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#5E5E5E"
                  d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
                ></path>
              </svg>
              {t("Favorite")}
            </Link>
          </li>

          <li>
            <Link to="/messages">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M8.5 10.5h7M7 18.43h4l4.45 2.96a.997.997 0 001.55-.83v-2.13c3 0 5-2 5-5v-6c0-3-2-5-5-5H7c-3 0-5 2-5 5v6c0 3 2 5 5 5z"></path>
                </g>
              </svg>
              {t("Messages")}
            </Link>
          </li>

          <li>
            <Link onClick={() => logoutAction()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M8.9 7.56c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M15 12H3.62M5.85 8.65L2.5 12l3.35 3.35"></path>
                </g>
              </svg>
              {t("logout")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData.userData,
  };
};
export default connect(mapStateToProps, { logoutAction })(UserPanel);
