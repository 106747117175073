import React from "react";
import { useTranslation } from "react-i18next";


const AfterSignup = () => {
    const {t} = useTranslation()
    return (
        <div>
            <h1 className='user-massage-from-server'>{t("success_signup_message")}</h1>
        </div>
    )
}

export default AfterSignup;