import React, { useState, useEffect } from "react";

const PostCardImageContainer = ({ data, ImageRenderer }) => {
  const [currentActiveImage, setCurrentActiveImage] = useState(null);
  const MAX_DOTS = 5;
  const MAX_IMAGES = 4;

  const countRemainingPhotos = () => {
    const remainingPhotosCount = data.images.length - MAX_IMAGES;
    if (remainingPhotosCount > 0) {
    }
    return data.images.length - 4;
  };

  useEffect(() => {
    if (data.preview_img) {
      setCurrentActiveImage(data.preview_img);
      data.images.unshift({ image: data.preview_img });
    }
  }, [data]);

  const renderImageDots = () => {
    let imageCount = 0;
    if (data !== null && data.images.length > 0) {
      return data.images.map((image) => {
        imageCount++;
        if (imageCount > MAX_DOTS) {
          return <></>;
        }
        if (imageCount === MAX_DOTS) {
          return (
            <span
              className="dot-button"
              onMouseOver={() => setCurrentActiveImage(image.image)}
            >
              <span class="dot"></span>
              <div className="more-photos">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  >
                    <path d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"></path>
                    <path d="M9 10a2 2 0 100-4 2 2 0 000 4zM2.67 18.95l4.93-3.31c.79-.53 1.93-.47 2.64.14l.33.29c.78.67 2.04.67 2.82 0l4.16-3.57c.78-.67 2.04-.67 2.82 0L22 13.9"></path>
                  </g>
                </svg>

                <p>+{countRemainingPhotos()} Photos</p>
              </div>
            </span>
          );
        }
        return (
          <span
            className="dot-button"
            onMouseEnter={() => setCurrentActiveImage(image.image)}
          >
            <span class="dot"></span>
          </span>
        );
      });
    }
  };
  return (
    <>
      <ImageRenderer src={currentActiveImage} />

      <div
        className="dots-container"
        onMouseLeave={() => setCurrentActiveImage(data.images[0].image)}
      >
        {renderImageDots()}
      </div>
    </>
  );
};

export default PostCardImageContainer;
