import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { getBannersAction } from "../actions/managementActions";

const Banner = ({ getBannersAction, banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    getBannersAction();
  }, []);
  useEffect(() => {
    if (banners && banners.length > 0) {
      const interval = setInterval(() => {
        // Calculate the index of the next slide
        const nextSlide = (currentSlide + 1) % banners.length;
        setCurrentSlide(nextSlide);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [currentSlide, banners]);

  return (
    <div id="banner" className="head-banner">
      <div className="slideshow-container">
        {banners.map((banner, index) => (
          <div
            key={index}
            className={`mySlides fade ${
              index === currentSlide ? "active" : ""
            }`}
          >
            <a href={banner.url} target="_blank">
              <img
                src={banner.banner_img}
                style={{ width: "100%" }}
                alt={`Banner ${index + 1}`}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.management.headerBanners,
  };
};

export default connect(mapStateToProps, { getBannersAction })(Banner);
