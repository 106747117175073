import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ReturnPolicy = () => {
  return (
    <>
      <div className="rules-parent-container">
        <div className="rules-parent-container-header"></div>
        <div className="rules-inside-container">
          <ul className="table-of-content">
            <li>დაბრუნებაზე პასუხისმგებელი პირები</li>
            <li>რა შემთხვევაში შემიძლია პროდუქტის დაბრუნება?</li>
            <li>როგორ დააბრუნოთ პროდუქტი როცა მოვაჭრე არ არის Pethub.ge?</li>
            <li>რამდენ დღეში შემიძლია ნივთის დაბრუნება?</li>
            <li>
              როგორ უნდა დავაფიქსირო მოთხოვნა პროდუქტის დაბრუნება/გადაცვლაზე?
            </li>
            <li>რა პროცედურა უნდა დავიცვა თანხის ანაზღაურებისთვის?</li>
            <li>რამდენ დღეში მოხდება პროდუქტის დაბრუნება?</li>
            <li>როგორ მოხდება თანხის ანაზღაურება?</li>
            <li>როგორ მოხდება დაბრუნებული პროდუქტის თანხის ანაზღაურება?</li>
            <li>ტრანსპორტირების მომსახურების ღირებულების ანაზღაურების წესი</li>
            <li>დაბრუნების უფლების/ინიცირების შეზღუდვა</li>
          </ul>

          <div className="editor">
            <h2 className="">დაბრუნების/გაუქმების პირობები</h2>
            <div className="read-rules">
              <p>
                დაბრუნების პირობები წარმოადგენს ვებგვერდით სარგებლობის წესებისა
                და პირობების განუყოფელ ნაწილს. ვებგვერდით სარგებლობის წესებსა და
                პირობებზე დათანხმებისას თქვენ ეთანხმებით წინამდებარე დაბრუნების
                პირობებსაც.
              </p>
              <h3>დაბრუნებაზე პასუხისმგებელი პირები</h3>
              <ul>
                <li>
                  Pethub.ge პლატფორმაზე განთავსებული თითოეული პროდუქტის
                  დაბრუნების პირობას განსაზღვრავს პროდუქტის გვერდზე მითითებული
                  მოვაჭრეები (გამყიდველი, შემსრულებელი, მერჩანტი). ისინი თავად
                  ადგენენ დაბრუნების, პროდუქტის შეფასებისა და მომხმარებლისთვის
                  ანაზღაურების წესს.
                </li>
                <li>
                  Pethub-ი არ არის პასუხისმგებელი Pethub.ge პლატფორმაზე
                  პროდუქტის გვერდზე მითითებული მოვაჭრეების (გამყიდველი,
                  შემსრულებელი, მერჩანტი) მიერ განსაზღვრულ წესებზე და პირობებზე
                  და მოვაჭრის მიერ დადგენილი წესების შესრულებასა თუ
                  შეუსრულებლობით გამოწვეულ შედეგებზე (გამონაკლისია, როცა
                  Pethub-ი თავად არის მოვაჭრე).
                </li>
                <li>
                  Pethub-ი პასუხისმგებელია მხოლოდ Pethub.ge პლატფორმაზე
                  განთავსებული ისეთი პროდუქტის დაბრუნებაზე, პროდუქტის შეფასებასა
                  და მომხმარებლისთვის თანხის ანაზღაურებაზე, სადაც
                  მოვაჭრის/გამყიდველის ველში მითითებულია თავად შპს „ფეთჰაბი“ ან
                  თავად Pethub.ge პლათფორმა.
                </li>
              </ul>
              <h3 id="1">რა შემთხვევაში შემიძლია პროდუქტის დაბრუნება?</h3>
              <p style={{ fontFamily: "TBCXMedium" }}>
                Pethub პროდუქტის დაბრუნების პოლიტიკის გათვალისწინებით, გაძლევთ
                შესაძლებლობას უკან დააბრუნოთ შეძენილი პროდუქტი, თუ:
              </p>

              <ul>
                <li>პროდუქტი დაზიანებულია</li>
                <li>პროდუქტი პროგრამულად გაუმართავია</li>
                <li>
                  პროდუქტი არ შეესაბამება შეკვეთას ან ვებგვერდზე განთავსებულ
                  ტექნიკურ მახასიათებლებს და მითითებულ სერვისს
                </li>
                <li>პროდუქტს აქვს ქარხნული წუნი</li>
                <li>მიწოდების შეთანხმებული დრო დაირღვა Pethub-ის მხრიდან</li>
              </ul>

              <p style={{ fontFamily: "TBCXMedium" }}>
                პროდუქტი უკან დაბრუნებას არ ექვემდებარება იმ შემთხვევაში თუ:
              </p>
              <ul>
                <li>
                  პროდუქტი არის დალუქული აუდიო-ვიდეო ჩანაწერი ან პროგრამული
                  უზრუნველყოფა და მისი ლუქი მიწოდების შემდეგ დაირღვა
                </li>
                <li>
                  მომხმარებლის მიერ დაზიანებულია პროდუქტის შეფუთვა (ყუთი, პარკი,
                  ლუქი) ან დოკუმენტაცია ასეთის არსებობის შემთხვევაში
                </li>
                <li>პროდუქტზე აღინიშნება გამოყენების კვალი</li>
                <li>
                  თუ პროდუქტი ან/და პროდუქტის თანმხლები ნაწილები/დოკუმენტები
                  არის გამოყენებული/დაზიანებული, აღენიშნება ცვლილება ან/და
                  შენარჩუნებული არ აქვს სარეალიზაციო იერსახე, კომპანია უფლებას
                  იტოვებს ეჭვქვეშ დააყენოს მისი ნამდვილობა/უნაკლოობა. შემოწმების
                  პროცედურის გავლის შემდეგ თუ დადგინდება პროდუქტზე ზემოაღნიშნული
                  ცვლილებები/ნაკლი, კომპანია უფლებას იტოვებს არ დაიბრუნოს
                  პროდუქტი.
                </li>
                <li>
                  პროდუქტს არ ახლავს შეძენის დროს გადაცემული ყველა თანმხლები
                  ნაწილი და დოკუმენტი
                </li>
              </ul>
              <h3>როგორ დააბრუნოთ პროდუქტი როცა მოვაჭრე არ არის Pethub.ge?</h3>
              <ul>
                <li>
                  ონლაინ პროდუქტის შეძენის შემთხვევაში თქვენ, დაბრუნების
                  ინიცირება უნდა მოახდინოთ Pethub.ge პლატფორმაზე არსებული
                  დაბრუნების პოლიტიკის მეთოდებით ან/და პროდუქტის გვერდზე
                  მითითებული მოვაჭრის წესების დაცვით.
                </li>
                <p style={{ fontFamily: "TBCXMedium" }}>გაითვალისწინეთ, რომ:</p>
                <ul>
                  <li>
                    პროდუქტის დაბრუნება შესაძლებელია მხოლოდ გამყიდველის მიერ
                    მითითებული წესით.
                  </li>
                  <li>
                    ოფლაინ პროდუქტის/პროდუქტის შეძენის შემთხვევაში დაბრუნების
                    ინიცირება უნდა მოახდინოთ მხოლოდ მოვაჭრესთან პირდაპირი
                    კომუნიკაციის გზით, Pethub-ის გარეშე.
                  </li>
                </ul>
                <p style={{ fontFamily: "TBCXMedium" }}>
                  გაითვალისწინეთ, რომ ონლაინ ვაჭრობისას, როცა გამყიდველი არ არის
                  Pethub.ge, ჩვენ ასეთ დროს ვართ შუამავალი თქვენსა და გამყიდველს
                  შორის. ჩვენ, პლატფორმაზე შესაძლოა შემოგთავაზოთ დაბრუნების
                  ფორმა და დაბრუნების მართვის სერვისი. ასეთ შემთხვევაში, ჩვენ
                  ვიღებთ პასუხისმგებლობას თქვენს მიერ დაფიქსირებული პრეტენზია
                  ვაცნობოთ გამყიდველს, ხოლო გამყიდველის პასუხი პრეტენზიაზე
                  გაცნობოთ თქვენ. ამასთანავე, გაითვალისწინეთ, რომ თქვენს
                  პრეტენზიასთან დაკავშირებით გადაწყვეტილებას იღებს გამყიდველი.
                  გამყიდველი ერთპიროვნულად არის პასუხისმგებელი მის მიერ მიღებულ
                  გადაწყვეტილებებზე. აღნიშნული გულისხმობს, რომ დაბრუნების
                  პოლიტიკით განსაზღვრული წესით დაბრუნების ინიცირების შემდეგ,
                  ჩვენ მოვახდენთ:
                </p>
                <ul>
                  <li>
                    გამყიდველთან კომუნიკაციას თქვენს მიერ ინიცირებული დაბრუნების
                    თაობაზე და მისთვის დაბრუნების შესახებ სრული ინფორმაციის
                    მიწოდებას
                  </li>
                  <li>
                    მოვაჭრის (გამყიდველის)/სერვის-ცენტრის მიერ პროდუქტის
                    შეფასების შემდეგ, ჩვენ თქვენ მოგაწვდით გამყიდველის პროდუქტის
                    შეფასების დასკვნას ან გამყიდველის გადაწყვეტილებას
                  </li>
                </ul>
                <li>
                  თუ თქვენ ჩათვლით, რომ მოვაჭრის (გამყიდველის)/სერვის-ცენტრის
                  მიერ გაცემული დასკვნა არღვევს თქვენს უფლებებს, პრეტენზია
                  შეგიძლია დააფიქსიროთ კონკურენციის ეროვნულ სააგენტში
                </li>
              </ul>
              <h3 id="2">რამდენ დღეში შემიძლია ნივთის დაბრუნება?</h3>
              <ul>
                <li>
                  ქიუარ თაგის უკან დაბრუნების სურვილის შემთხვევაში, საჭიროა
                  პროდუქტის მიღებიდან 7 დღის განმავლობაში დააფიქსიროთ მოთხოვნა.
                </li>
                <li>
                  ჯიპეს მოწყობილობის უკან დაბრუნების სურვილის შემთხვევაში,
                  საჭიროა პროდუქტის მიღებიდან 3 დღის განმავლობაში დააფიქსიროთ
                  მოთხოვნა.
                </li>
              </ul>
              <p>
                ზემოთ აღნიშნული ვადების გასვლის შემდგომ მოთხოვნა ნივთის უკან
                დაბრუნებაზე აღარ მიიღება
              </p>
              <h3 id="3">
                როგორ უნდა დავაფიქსირო მოთხოვნა პროდუქტის დაბრუნება/გადაცვლაზე?
              </h3>
              <ul>
                <li>
                  შეგიძლიათ განაცხადოთ მოთხოვნა წერილობითი სახით .....
                  დამატებითი ინფორმაციის მიღების მიზნით შეგიძლიათ მოგვწეროთ
                  ჩატში ან/და დაგვიკავშირდეთ ქოლ ცენტრის საშუალებით ნომერზე 0322
                  560505.
                </li>
              </ul>
              <p>
                Pethub-ის წარმომადგენლები მოთხოვნის მიღებიდან მაქსიმუმ 24 საათის
                განმავლობაში დაგიკავშირდებიან ნივთის დაბრუნების პროცედურასთან
                დაკავშირებით.
              </p>
              <h3 id="4">რა პროცედურა უნდა დავიცვა თანხის ანაზღაურებისთვის?</h3>
              <ul>
                <li>
                  თუ გსურთ პროდუქტის დაბრუნება, საჭიროა პროდუქტს ახლდეს შეძენის
                  დროს გადაცემული ყველა თანმხლები ნაწილები და დოკუმენტი ასეთის
                  არსებობის შემთხვევაში. საგარანტიო ფურცელი, ტექნიკური პასპორტი,
                  ნივთზე თანმხლები სხვა დოკუმენტები, ნივთის დაუზიანებელი ყუთი,
                  აქსესუარები და სხვა.
                </li>
              </ul>
              <h3 id="5">რამდენ დღეში მოხდება პროდუქტის დაბრუნება?</h3>
              <ul>
                <li>
                  თბილისის მასშტაბით ნივთის დაბრუნება სავალდებულოა მოთხოვნის
                  დაფიქსირებიდან მაქსიმუმ 24 საათში
                </li>
                <li>
                  დიდი და პატარა რეგიონების შემთხვევაში, ნივთის დაბრუნება
                  სავალდებულოა მოთხოვნის დაფიქსირებიდან 5 სამუშაო დღეში.
                  მაღალმთიანი რეგიონის შემთხვევაში, 7-10 სამუშაო დღეში.
                </li>
              </ul>
              <h3 id="6">როგორ მოხდება თანხის ანაზღაურება?</h3>
              <ul>
                <li>
                  Pethub ნივთის უკან დაბრუნების შემდეგ, 24 საათის განმავლობაში,
                  შეგატყობინებთ გადაწყვეტილებას პროდუქტის ანაზღაურება/უკან
                  დაბრუნების სტატუსის შესახებ.
                </li>
                <li>
                  ქარხნული წუნის შემთხვევაში, თანხის უკან დაბრუნების ან ნივთის
                  ჩანაცვლების შესახებ გადაწყვეტილებას გაიგებთ 3 დღის
                  განმავლობაში.
                </li>
                <li>
                  დადებითი გადაწყვეტილების შემთხვევაში თანხის ანაზღაურება
                  მოხდება შეტყობინების მიღებიდან მაქსიმუმ 5 სამუშაო დღეში.
                </li>
                <li>
                  უარყოფითი გადაწყვეტილების შემთხვევაში, ნივთი დაგიბრუნდებათ
                  უკან
                </li>
              </ul>
              <h3 id="7">
                როგორ მოხდება დაბრუნებული პროდუქტის თანხის ანაზღაურება?
              </h3>
              <ul>
                <li>
                  თანხის დაბრუნება შესაძლებელია მხოლოდ უნაღდო ანგარიშსწორებით,
                  მომხმარებელს იმ საბარათო ანგარიშზე ჩარიცხვის გზით, რომლითაც
                  განხორციელდა ნივთის შესყიდვა.
                </li>
              </ul>
              <h3 id="8">
                ტრანსპორტირების მომსახურების ღირებულების ანაზღაურების წესი
              </h3>
              <ul>
                <li>
                  ნივთის შემოწმებისას დადებითი გადაწყვეტილების შემთხვევაში
                  ტრანსპორტირების ღირებულებულების დაფარვა ეკისრება კომპანიას.
                </li>
                <li>
                  ნივთის შემოწმებისას უარყოფითი გადაწყვეტილების შემთხვევაში
                  ტრანსპორტირების ორივე გზის ღირებულებულების დაფარვა ეკისრება
                  მომხმარებელს.
                </li>
              </ul>
              <h3>დაბრუნების უფლების/ინიცირების შეზღუდვა</h3>
              <ul>
                <li>
                  მომხმარებელს არ აქვს უფლება, უარი თქვას ხელშეკრულებაზე და
                  დააბრუნოს პროდუქტი
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnPolicy;
