import axios from 'axios';
import { logoutAction } from '../actions/authActions';

const baseURL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        "Authorization": localStorage.getItem('access_token') ? "Bearer " + localStorage.getItem('access_token') : null,
        "Content-Type": "application/json",
    },
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        // Prevent infinite loops early
        if (error.response.status === 401 && originalRequest.url === '/users/token/refresh/') {
            logoutAction()
            return Promise.reject(error);
        }
        if (error.response.data.code === "token_not_valid" &&
            error.response.status === 401) 
            {
                const refreshToken = localStorage.getItem('refresh_token');
                if (refreshToken) {
                    return axiosInstance
                    .post('/users/token/refresh/', {refresh: refreshToken})
                    .then((response) => {
                                    
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
        
                        axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                        originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                        return axiosInstance(originalRequest);
                    })
                    .catch(err => {
                        logoutAction()
                    });
                }
        }
      // specific error handling done elsewhere
      return Promise.reject(error);
  }
);


export default axiosInstance;
