import React from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="aboutus">
      <div className="aboutus-information-container">
        <div className="aboutus-information-box-1 none">
          {/* <svg height="0" width="0">
            <clipPath id="svgPath">
              <path
                d="M24.6,-43.8C29.8,-39.6,30.7,-28.9,42.4,-20.5C54,-12.1,76.5,-6.1,83.8,4.2C91.1,14.5,83.1,28.9,73.3,40.5C63.5,52.1,59.8,60.7,39.2,59.2C26.7,57.7,13.4,46.1,-0.4,46.8C-14.2,47.5,-28.3,60.5,-41.2,62.2C-54,63.9,-65.6,54.2,-66.1,41.9C-66.5,29.7,-55.9,14.8,-56.4,-0.3C-57,-15.4,-68.6,-30.9,-65.8,-39C-62.9,-47.2,-45.6,-48.1,-32.2,-47.7C-18.9,-47.2,-9.4,-45.4,0.1,-45.6C9.7,-45.8,19.3,-48,24.6,-43.8Z"
                transform="translate(200 150)"
              />
            </clipPath>
          </svg> */}
          <img src="../img/partnership.png" />
          {/* <p>{t("our_qr_collar_text")}</p> */}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac
            est et sapien blandit eleifend vitae eu nunc. Aenean magna lectus,
            rhoncus quis tincidunt ut, viverra id magna. Aenean et sem lacus.
            Phasellus venenatis, urna id sodales fringilla, tellus lectus
            scelerisque purus, vitae mattis orci nibh at mi. Suspendisse aliquet
            blandit imperdiet. Nunc neque magna, accumsan ut felis eget, congue
            interdum nisl. Maecenas accumsan, felis ac gravida dictum, metus
            tellus volutpat nibh, non consectetur nisi purus eu sem. Sed luctus
            hendrerit lacus fermentum imperdiet. Aliquam dapibus massa in ligula
            consequat convallis. Vestibulum in posuere sapien, a porta mi.
            Aenean hendrerit tellus et convallis convallis. Nullam cursus
            consequat orci et vulputate. Duis eget malesuada purus, a mattis
            eros. In hac habitasse platea dictumst. Class aptent taciti sociosqu
            ad litora torquent per conubia nostra, per inceptos himenaeos.
            Vestibulum gravida luctus condimentum. Aenean id mauris maximus nunc
            commodo ullamcorper. Mauris dapibus eros in dui bibendum, vel
            condimentum ipsum condimentum. Vestibulum in scelerisque orci. Ut
            non sem vitae nisl malesuada ullamcorper sit amet non odio. Sed
            efficitur interdum sem id tincidunt. Duis auctor feugiat nisl non
          </p>
        </div>

        <div className="aboutus-information-box-1 box-2">
          <img src="../img/partnership.png" />
          {/* <p>{t("partners_text")}</p> */}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac
            est et sapien blandit eleifend vitae eu nunc. Aenean magna lectus,
            rhoncus quis tincidunt ut, viverra id magna. Aenean et sem lacus.
            Phasellus venenatis, urna id sodales fringilla, tellus lectus
            scelerisque purus, vitae mattis orci nibh at mi. Suspendisse aliquet
            blandit imperdiet. Nunc neque magna, accumsan ut felis eget, congue
            interdum nisl. Maecenas accumsan, felis ac gravida dictum, metus
            tellus volutpat nibh, non consectetur nisi purus eu sem. Sed luctus
            hendrerit lacus fermentum imperdiet. Aliquam dapibus massa in ligula
            consequat convallis. Vestibulum in posuere sapien, a porta mi.
            Aenean hendrerit tellus et convallis convallis. Nullam cursus
            consequat orci et vulputate. Duis eget malesuada purus, a mattis
            eros. In hac habitasse platea dictumst. Class aptent taciti sociosqu
            ad litora torquent per conubia nostra, per inceptos himenaeos.
            Vestibulum gravida luctus condimentum. Aenean id mauris maximus nunc
            commodo ullamcorper. Mauris dapibus eros in dui bibendum, vel
            condimentum ipsum condimentum. Vestibulum in scelerisque orci. Ut
            non sem vitae nisl malesuada ullamcorper sit amet non odio.
          </p>
          <img className="float-right" src="../img/partnership.png" />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac
            est et sapien blandit eleifend vitae eu nunc. Aenean magna lectus,
            rhoncus quis tincidunt ut, viverra id magna. Aenean et sem lacus.
            Phasellus venenatis, urna id sodales fringilla, tellus lectus
            scelerisque purus, vitae mattis orci nibh at mi. Suspendisse aliquet
            blandit imperdiet. Nunc neque magna, accumsan ut felis eget, congue
            interdum nisl. Maecenas accumsan, felis ac gravida dictum, metus
            tellus volutpat nibh, non consectetur nisi purus eu sem. Sed luctus
            hendrerit lacus fermentum imperdiet. Aliquam dapibus massa in ligula
            consequat convallis. Vestibulum in posuere sapien, a porta mi.
            Aenean hendrerit tellus et convallis convallis. Nullam cursus
            consequat orci et vulputate. Duis eget malesuada purus, a mattis
            eros. In hac habitasse platea dictumst. Class aptent taciti sociosqu
            ad litora torquent per conubia nostra, per inceptos himenaeos.
            Vestibulum gravida luctus condimentum. Aenean id mauris maximus nunc
            commodo ullamcorper. Mauris dapibus eros in dui bibendum, vel
            condimentum ipsum condimentum. Vestibulum in scelerisque orci. Ut
            non sem vitae nisl malesuada ullamcorper sit amet non odio.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
