import { combineReducers } from "redux";
import userDataReducer from "./userDataReducer";
import postReducer from "./postReducer";
import errorsReducer from "./errorsReducer";
import chatReducers from "./chatReducers";
import managementReducers from "./managementReducers";
import ordersReducers from "./ordersReducers";

export default combineReducers({
  userData: userDataReducer,
  posts: postReducer,
  errors: errorsReducer,
  chat: chatReducers,
  management: managementReducers,
  orders: ordersReducers,
});
