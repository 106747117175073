import { useState } from "react";

const ImageSliderHook = (images, startIndex = 0) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(startIndex);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  return {
    currentImageIndex,
    setCurrentImageIndex,
    handleNextImage,
    handlePreviousImage,
  };
};

export default ImageSliderHook;
