import React from "react";
import { useSearchParams } from "react-router-dom";

import PostCardContainer from "../components/PostCardContainer";

const Search = () => {
  const [searchParams] = useSearchParams();

  return (
    <>
      <PostCardContainer activePage={Number(searchParams.get("page") || 1)} />
    </>
  );
};

export default Search;
