import React from "react";

const ImageForPostCard = ({ src, imgClassName = "" }) => {
  if (src === null) {
    return <img src="/img/defaultphoto15.jpg" className={imgClassName} />;
  } else {
    return <img src={src} className={imgClassName} />;
  }
};

export default ImageForPostCard;
