import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="parent-container-short">
      <h1 className="not-found-page">
        <img className="not-found-img" src="/img/404.png" alt="" />
        <p>{t("sorry page not found")}</p>
      </h1>
    </div>
  );
};

export default NotFound;
