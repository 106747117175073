import {
  GET_BANNERS,
  GET_AD_POSTS,
  GET_WORKERS,
  CLEAR_AD_POSTS,
  GET_ALL_RATED_IDS,
  ADD_NEW_USER_RATE_ID
} from "../actions/types";

const INITIAL_STATE = {
  headerBanners: [],
  stickyBanner: null,
  searchBanner: null,
  workers: null,
  adPosts: null,
  userRatedIDs: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS:
      const headerBanners = [];
      let stickyBanner = null;
      let searchBanner = null;
      action.payload.forEach((banner) => {
        if (banner.banner_type === "HEADER") {
          headerBanners.push(banner);
        }
        if (stickyBanner === null && banner.banner_type === "STICKY") {
          stickyBanner = banner;
        }
        if (searchBanner === null && banner.banner_type === "SEARCH_STICKY") {
          searchBanner = banner;
        }
      });
      return {
        ...state,
        headerBanners: headerBanners,
        stickyBanner: stickyBanner,
        searchBanner: searchBanner,
      };
    case GET_WORKERS:
      return { ...state, workers: action.payload };
    case GET_AD_POSTS:
      return { ...state, adPosts: action.payload };
    case CLEAR_AD_POSTS:
      return { ...state, adPosts: action.payload };
    case GET_ALL_RATED_IDS:
      return { ...state, userRatedIDs: action.payload }
    case ADD_NEW_USER_RATE_ID:
      return { ...state, userRatedIDs: [...state.userRatedIDs, action.payload] }
    default:
      return state;
  }
};
