import React, { useEffect } from "react";
import { connect } from "react-redux";

import RightContainer from "../components/RightContainer";
import WorkerCard from "../components/WorkerCard";
import { getWorkersAction } from "../actions/managementActions";
import { getRatedUserIdsAction } from "../actions/authActions";

const Workers = ({
  getWorkersAction,
  workers,
  ratedIDs,
  getRatedUserIdsAction,
}) => {
  useEffect(() => {
    getWorkersAction();
    getRatedUserIdsAction();
  }, []);
  const renderWorkers = () => {
    if (workers) {
      return workers.map((worker) => {
        return (
          <WorkerCard key={worker.email} data={worker} ratedIDs={ratedIDs} />
        );
      });
    }
  };
  return (
    <>
      <div className="parent-container-short">
        <div className="main-container">
          <div className="left-container flex">
            <div className="card-4-container">{renderWorkers()}</div>
            {/* <div className="sticky-banner-container">
                <img src="../img/sticky-banner.jpg" alt="sticky-banner" />
            </div> */}
          </div>
          <RightContainer />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workers: state.management.workers,
    ratedIDs: state.management.userRatedIDs,
  };
};
export default connect(mapStateToProps, {
  getWorkersAction,
  getRatedUserIdsAction,
})(Workers);
