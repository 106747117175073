import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { clearServerErrors } from "../actions/errors";

import DropDownInput from "../components/DropDownInput";

import { getSingleActivePost, updatePostAction } from "../actions/postActions";
import { DOG_BREEDS, CAT_BREEDS, CITIES } from "../utils";
import { GET_SINGLE_POST } from "../actions/types";
import UserPanel from "../components/UserPanel";

const UpdatePost = ({ getSingleActivePost, postData, updatePostAction }) => {
  const { t } = useTranslation();
  const { postID } = useParams();

  const AddNewArtWorkSchema = yup.object().shape({
    department: yup.string().required(),
    animal_type: yup.string().required(),
    breed: yup
      .string()
      .test("not-required-if-birds-rebbit", "required_field", function (value) {
        const animal_type = this.resolve(yup.ref("animal_type"));
        if (["cat", "dog"].includes(animal_type)) {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    gender: yup.string().required(),
    age: yup
      .string()
      .test("not-required-if-for-found", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        if (department !== "found") {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    city: yup.string().required(),
    price: yup
      .mixed()
      .test("required-if-for-sale", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        if (department === "for_sale") {
          return yup.number().required().isValidSync(value);
        }
        return true;
      }),
    currency: yup
      .string()
      .test("required-if-for-sale", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        if (department === "for_sale") {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    documents: yup
      .string()
      .test(
        "not-required-if-missing-and-found",
        { key: "required_field" },
        function (value) {
          const department = this.resolve(yup.ref("department"));
          if (!["found", "missing"].includes(department)) {
            return yup.string().required().isValidSync(value);
          }
          return true;
        }
      ),
    description: yup.string(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(AddNewArtWorkSchema),
  });

  useEffect(() => {
    if (postData) {
      reset(postData);
    } else {
      clearServerErrors();
      getSingleActivePost(postID);
    }
  }, [postData]);

  const renderPriceAndCurrency = () => {
    const department = watch("department");
    const price_with_deal = watch("price_with_deal");
    if (price_with_deal === true) {
      return (
        <>
          <div className="wrapper2 for-currency-price-deal">
            <input
              type="number"
              name="price"
              placeholder={t("price")}
              {...register("price")}
              disabled
            />

            <DropDownInput
              name="currency"
              fieldName="currency"
              customClassNameSelectBtn="none"
              customClassNameSelecMenuDropDown="currency-disabled"
              options={["GEL", "USD"]}
              handleOptionClick={setValue}
            />

            <button
              {...register("price_with_deal")}
              type="button"
              className="price-deal active"
              onClick={() => {
                setValue("price_with_deal", !getValues("price_with_deal"));
              }}
            >
              {" "}
              {t("price with deal")}
            </button>
          </div>
        </>
      );
    }
    if (department === "for_sale" && price_with_deal === false) {
      return (
        <>
          <div className="wrapper2 for-currency-price-deal">
            <input
              type="number"
              name="price"
              placeholder={t("price")}
              {...register("price")}
            />
            {errors.price && (
              <p className="error-text">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                </svg>
                {t(errors.price?.message)}
              </p>
            )}

            <DropDownInput
              name="currency"
              fieldName="currency"
              customClassNameSelectBtn="none"
              customClassNameSelecMenuDropDown="currency-enable"
              options={["GEL", "USD"]}
              handleOptionClick={setValue}
            />

            <button
              {...register("price_with_deal")}
              type="button"
              className="price-deal"
              onClick={() => {
                setValue("price_with_deal", !getValues("price_with_deal"));
              }}
            >
              {" "}
              {t("price with deal")}
            </button>
          </div>
        </>
      );
    }
  };

  const renderBreedOptions = () => {
    const breeds = { dog: DOG_BREEDS, cat: CAT_BREEDS, bird: ["qwe", "qwe"] };
    const petType = watch("animal_type", []);
    if (["dog", "cat", "bird"].includes(petType)) {
      return breeds[petType];
    }
    return [];
  };

  const renderAdoptFromshelter = () => {
    const department = watch("department");
    if (department === "adopt") {
      return (
        <div className="wrapper2">
          <DropDownInput
            name="adopt_from_shelter"
            fieldName="adopt_from_shelter"
            options={["yes", "no"]}
            backendValues={[true, false]}
            handleOptionClick={setValue}
          />
        </div>
      );
    }
  };

  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="add-post-container">
          <form
            className="add-post"
            onSubmit={handleSubmit((data) => updatePostAction(postID, data))}
          >
            <div className="fields-container">
              <div className="wrapper2">
                <DropDownInput
                  name="department"
                  fieldName="department"
                  options={["for_sale", "adopt", "missing", "found"]}
                  handleOptionClick={setValue}
                />
                {errors.department && (
                  <a className="error-text">
                    {t(errors.department?.message.key)}
                  </a>
                )}
              </div>

              {renderPriceAndCurrency()}
              {renderAdoptFromshelter()}

              <div className="wrapper2">
                <DropDownInput
                  name="animal_type"
                  fieldName="animal_type"
                  options={["dog", "cat", "bird"]}
                  handleOptionClick={setValue}
                />
                {errors.animal_type && (
                  <a className="error-text">
                    {t(errors.animal_type?.message.key)}
                  </a>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="breed"
                  fieldName="breed"
                  options={renderBreedOptions()}
                  handleOptionClick={setValue}
                  search={true}
                />
                {errors.breed && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.breed?.message)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="gender"
                  fieldName="gender"
                  options={["male", "female"]}
                  handleOptionClick={setValue}
                />
                {errors.gender && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.gender?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="age"
                  fieldName="age"
                  options={[1, 2, 3, 4, 5, 6, 7]}
                  handleOptionClick={setValue}
                />
                {errors.age && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.age?.message)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="documents"
                  fieldName="documents"
                  options={["yes", "no"]}
                  handleOptionClick={setValue}
                />
                {errors.documents && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.documents?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="is_vaccinated"
                  fieldName="is_vaccinated"
                  options={["yes", "no"]}
                  backendValues={[true, false]}
                  handleOptionClick={setValue}
                />
                {errors.is_vaccinated && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.is_vaccinated?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="city"
                  fieldName="city"
                  options={CITIES}
                  handleOptionClick={setValue}
                  search={true}
                />
                {errors.city && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.city?.message.key)}
                  </p>
                )}
              </div>

              <textarea
                {...register("description")}
                id="description"
                name="description"
                placeholder={t("Description")}
                rows="4"
                cols="50"
              ></textarea>

              <br />

              <input type="submit" value={t("Add post")} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
    postData: state.posts.singleActivePost,
  };
};

export default connect(mapStateToProps, {
  clearServerErrors,
  getSingleActivePost,
  updatePostAction,
})(UpdatePost);
