import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ImageForPostCard from "./ImageForPostCard";
import router from "../router";

import { updateSavedPostsIDs } from "../actions/postActions";
import { changeUserCurrency } from "../actions/authActions";
import { clearChatUsers, addNewChatUser } from "../actions/chatActions";
import { calculatePrice, formatDate } from "../utils";
import { SAVE_POST, DELETE_SAVED_POST } from "../actions/types";
import PostCardImageContainer from "./PostCardImageContainer";

const BigPostCard = ({
  data,
  updateSavedPostsIDs,
  userCurrency,
  USD_RATE,
  changeUserCurrency,
  clearChatUsers,
  addNewChatUser,
  savedPosts,
  price_with_deal,
}) => {
  const { t } = useTranslation();
  const renderDocuments = () => {
    if (data.documents === true) {
      return (
        <p className="documentation green">
          <i className="bi bi-check-circle-fill yes"></i> {t("with documents")}
        </p>
      );
    }
    return (
      <p className="documentation red">
        <i className="bi bi-x-circle no"></i> {t("without documents")}
      </p>
    );
  };

  const renderSaveIcon = () => {
    if (savedPosts.includes(data.id)) {
      return (
        <button className="save-post">
          <svg
            className="save-button active"
            onClick={() =>
              updateSavedPostsIDs(data.id, "delete", DELETE_SAVED_POST)
            }
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
            </svg>
          </svg>
        </button>
      );
    }
    return (
      <button className="save-post">
        <svg
          className="save-button"
          onClick={() => updateSavedPostsIDs(data.id, "post", SAVE_POST)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
          </svg>
        </svg>
      </button>
    );
  };

  const renderDepartment = () => {
    if (data.department === "for_sale" && data.price_with_deal === true) {
      return <p className="price-with-deal">{t("price with deal")}</p>;
    } else if (
      data.department === "for_sale" &&
      data.price_with_deal === false
    ) {
      return (
        <span className="card-2-price-rate">
          <span className="price-currency">
            <p className="price">
              {calculatePrice(
                data.price_in_GEL,
                data.price_in_USD,
                data.price_with_deal,
                userCurrency,
                USD_RATE
              )}{" "}
              {userCurrency === "GEL" ? "₾" : "$"}
            </p>
            {/* <div id="currency" className="currency">
              <span
                className={
                  "currency-switch " + (userCurrency === "GEL" ? "active" : "")
                }
                onClick={() => changeUserCurrency("GEL")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="lari"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 20h-8.5A6.5 6.5 0 018 8v4a1 1 0 002 0V7.18A6.69 6.69 0 0111.5 7h.5v5a1 1 0 002 0V7.53a6.5 6.5 0 013.87 4.67 1 1 0 002-.4A8.53 8.53 0 0014 5.39V3a1 1 0 00-2 0v2h-.5a8.29 8.29 0 00-1.5.14V3a1 1 0 00-2 0v2.76A8.49 8.49 0 006 20H4a1 1 0 000 2h16a1 1 0 000-2z"></path>
                </svg>
              </span>

              <span
                className={
                  "currency-switch " + (userCurrency === "USD" ? "active" : "")
                }
                onClick={() => changeUserCurrency("USD")}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    fill="#5e5e5e"
                    d="M13 3.5a1 1 0 10-2 0v.56c-1.17.14-2.247.525-3.104 1.117C6.796 5.937 6 7.09 6 8.5c0 1.274.492 2.457 1.578 3.293.856.659 2.01 1.046 3.422 1.166v4.963c-.784-.126-1.46-.394-1.967-.745C8.323 16.687 8 16.09 8 15.5a1 1 0 10-2 0c0 1.41.796 2.563 1.896 3.323.857.592 1.934.978 3.104 1.118v.559a1 1 0 102 0v-.556c1.162-.134 2.238-.502 3.097-1.085C17.212 18.1 18 16.944 18 15.5c0-1.307-.486-2.498-1.584-3.329-.859-.65-2.012-1.018-3.416-1.132v-4.96c.784.125 1.46.393 1.967.744C15.677 7.313 16 7.91 16 8.5a1 1 0 102 0c0-1.41-.796-2.563-1.896-3.323C15.247 4.585 14.17 4.2 13 4.06V3.5zm-2 2.578c-.784.126-1.46.394-1.967.745C8.323 7.313 8 7.91 8 8.5c0 .726.258 1.293.797 1.707.446.343 1.15.631 2.203.743V6.078zm2 6.969v4.88c.791-.12 1.468-.38 1.974-.723C15.668 16.732 16 16.14 16 15.5c0-.777-.264-1.336-.79-1.734-.444-.335-1.148-.612-2.21-.719z"
                  ></path>
                </svg>
              </span>
            </div> */}
          </span>
        </span>
      );
    } else if (data.department === "found") {
      return (
        <span className="card-2-found">
          <p className="found">{t("found")}</p>
        </span>
      );
    } else if (data.department === "missing") {
      return (
        <span className="card-2-missing">
          <p className="missing">{t("missing")}</p>
        </span>
      );
    } else if (data.department === "adopt") {
      return (
        <span className="card-2-pet-adopt">
          <p className="adopt">{t("adopt")}</p>
        </span>
      );
    }
  };

  const renderPostType = () => {
    if (data.post_type === "standard") {
      return null;
    }
    return (
      <div className="card-2-vip-status">
        <span>{t("VIP")}</span>
      </div>
    );
  };

  // useEffect(() => {
  //   const petsCardHeaderColors = document.querySelector(".color");

  //   if (data.department === "for_sale") {
  //     petsCardHeaderColors.style.color = "grey";
  //   } else if (data.department === "found") {
  //     petsCardHeaderColors.style.color = "green";
  //   } else if (data.department === "missing") {
  //     petsCardHeaderColors.style.color = "red";
  //   } else if (data.department === "adopt") {
  //     petsCardHeaderColors.style.color = "orange";
  //   }
  // }, []);

  return (
    <div className="card-2">
      <div className="slide-img-box-for-card2">
        <Link to={`/post/${data.id}`}>
          <PostCardImageContainer
            data={data}
            ImageRenderer={ImageForPostCard}
          />
        </Link>
      </div>

      <div className="card-2-info-container">
        <span className="card-2-breed-city">
          <Link className="breed" to={`/post/${data.id}`}>
            {t(data.breed)}
          </Link>
          {renderPostType()}
        </span>

        <p>{renderDepartment()}</p>

        <span className="card-2-age-gender-doc">
          <p>{t(data.city)}</p>
          <p>
            {data.age} {t("month")}
          </p>
          <p>{t(data.gender)}</p>
          {renderDocuments()}
          {data.is_vaccinated ? (
            <>
              <span className="vaccine green">{t("vaccinated")}</span>
            </>
          ) : (
            <>
              <span className="vaccine red">{t("not vaccinated")}</span>
            </>
          )}
        </span>

        <span className="viewpost-and-buttons">
          <span className="postdate-view">
            <p className="post-date">{formatDate(data.created_at)}</p>-
            <p className="view-post">
              {data.views} {t("views")}
            </p>
          </span>

          <span className="post-buttons">
            {renderSaveIcon()}

            {/* <button
              className="message-user"
              onClick={() => {
                clearChatUsers();
                addNewChatUser(data.user);
                router.navigate("/messages", { replace: true });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="message"
                viewBox="0 0 24 24"
              >
                <g stroke="#5E5E5E">
                  <path d="M8.5 10.5h7M7 18.43h4l4.45 2.96a.997.997 0 001.55-.83v-2.13c3 0 5-2 5-5v-6c0-3-2-5-5-5H7c-3 0-5 2-5 5v6c0 3 2 5 5 5z"></path>
                </g>
              </svg>
            </button> */}
          </span>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedPosts: state.posts.savedPosts,
    userCurrency: state.userData.userCurrency,
    USD_RATE: state.userData.USD_RATE,
  };
};

export default connect(mapStateToProps, {
  updateSavedPostsIDs,
  changeUserCurrency,
  clearChatUsers,
  addNewChatUser,
})(BigPostCard);
