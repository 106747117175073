import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axiosInstance from "../../axiosApi/api";

const VerifyAccount = () => {
  const { t } = useTranslation();
  const [verifyStatus, setVerifyStatus] = useState(null);
  const { uid64, token } = useParams();

  const verifyToken = async () => {
    try {
      await axiosInstance.post(`/users/activate/${uid64}/${token}/`);
      setVerifyStatus(true);
    } catch {
      setVerifyStatus(false);
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);

  const renderVerificationStatus = () => {
    if (verifyStatus === true) {
      return (
        <div className="parent-container-short">
          <span className="verify-account">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"></path>
                <path d="M7.75 12l2.83 2.83 5.67-5.66"></path>
              </g>
            </svg>
            <h1>{t("account_activation_success")}</h1>
          </span>
        </div>
      );
    } else if (verifyStatus === false) {
      return (
        <div className="parent-container-short">
          <p className="verify-account failed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"></path>
              </g>
            </svg>
            <h1>{t("account_activation_failed")}</h1>
          </p>
        </div>
      );
    } else {
      return (
        <div className="spinner">
          <img src="./public/img/loading.gif" alt="loading" />
        </div>
      );
    }
  };

  return <div>{renderVerificationStatus()}</div>;
};

export default VerifyAccount;
