import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({
  modalRef,
  isVisable,
  setIsVisable,
  onConfirm,
}) => {
  const { t } = useTranslation();
  if (isVisable === false) return <></>;
  return (
    <div className="confirmation-background-container">
      <div className="confirmation-inside-container" ref={modalRef}>
        <p>{t("confirmation text")}</p>
        <div className="confirmation-buttons-container">
          <button onClick={() => onConfirm()}>{t("confirm")}</button>
          <button onClick={() => setIsVisable(false)}>{t("cancel")}</button>
        </div>
        <div className="confirmation-warning-text">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
          </svg>
          {t("confirmation warning text")}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
