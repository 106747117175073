import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { resetPasswordAction } from "../../actions/authActions";
import { clearServerErrors } from "../../actions/errors";

const ResetPassword = ({
  resetPasswordAction,
  serverErrors,
  clearServerErrors,
}) => {
  const { t } = useTranslation();
  const { uid64, token } = useParams();
  const ResetPasswordSchema = yup.object().shape({
    password: yup.string().required(),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "passwords_must_match"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", resolver: yupResolver(ResetPasswordSchema) });
  useEffect(() => {
    clearServerErrors();
  }, []);

  const onSubmit = (data) => {
    resetPasswordAction(data, { uid64, token });
  };

  return (
    <div className="parent-container-short">
      <div className="email-reset-password">
        <p className="email-reset-title">{t("recover password")}</p>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <input
            className="input"
            placeholder={t("new_password")}
            type="password"
            {...register("password")}
          />

          {errors.password && (
            <p className="help is-danger error-text">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
              </svg>
              {t(errors.password?.message.key)}
            </p>
          )}

          <input
            className="input"
            placeholder={t("confirm_password")}
            type="password"
            {...register("confirm_password")}
          />

          {errors.confirm_password && (
            <p className="help is-danger confirm-error-text">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
              </svg>
              {t(errors.confirm_password?.message)}
            </p>
          )}

          {serverErrors.error && (
            <p className="help is-danger confirm-error-text">
              {t(serverErrors.error)}
            </p>
          )}

          <div className="check-code">
            <img src="../../../img/code.png" alt="" />
            <input type="text" placeholder={t("code")} />
          </div>

          <button className="button is-primary">{t("confirm")}</button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  resetPasswordAction,
  clearServerErrors,
})(ResetPassword);
