import React from "react";
import Image from "./Image";

import { useTranslation } from "react-i18next";

const AdPostCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="card-3">
      <div className="company-img-box">
        <Image src={data.ad_post_img} />
        <a className="prev">❮</a>
        <a className="next">❯</a>
      </div>

      <div className="info-of-company-outside-container">
        <a className="company-name">
          {data.name}
          <span>
            <span> {t("open")} &nbsp; 24 / 7</span>
          </span>
        </a>
        <div className="info-of-company">
          <p className="company-district">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000"
              version="1.1"
              viewBox="0 0 120 120"
              xmlSpace="preserve"
            >
              <path d="M60.5-.12c-24.778 0-44.865 20.087-44.865 44.865 0 41.909 44.865 75.405 44.865 75.405s44.865-33.494 44.865-75.405C105.365 19.966 85.277-.121 60.5-.12zm.001 64.688c-10.948 0-19.823-8.875-19.823-19.823s8.875-19.823 19.823-19.823 19.823 8.875 19.823 19.823-8.875 19.823-19.823 19.823z"></path>
            </svg>
            {data.address}
          </p>

          <p className="company-phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#1C274C"
                d="M10.038 5.316l.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0-1.12 1.119.91 3.148 2.027 2.027 3.146.91 3.147.91.923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.149-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4.043-1.133.694-2.159 1.53-2.996 1.394-1.393 3.616-1.206 4.5.38z"
              ></path>
            </svg>
            {data.phone_numbers}
          </p>
          <p className="company-hours">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g>
                <path
                  fill="#5e5e5e"
                  d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M12 7.25a.75.75 0 01.75.75v3.69l2.28 2.28a.75.75 0 11-1.06 1.06l-2.5-2.5a.75.75 0 01-.22-.53V8a.75.75 0 01.75-.75z"
                  clipRule="evenodd"
                ></path>
              </g>
            </svg>
            <span className="green">{t("open")}</span> ⋅ {t("will close")} 10{" "}
            {t("am")}
          </p>
        </div>

        <div className="company-contact-buttons">
          <p className="on-call">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M5.293 5.293a1 1 0 011.414 0L12 10.586l5.293-5.293a1 1 0 111.414 1.414L13.414 12l5.293 5.293a1 1 0 01-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 01-1.414-1.414L10.586 12 5.293 6.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>{t("on call")}</span>
          </p>

          <button>
            ვიზიტის დაჯავშნა
            <img src="../../svg/soon.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdPostCard;
