import { SERVER_ERROR, CLEAR_ERRORS } from "../actions/types";

const INITIAL_STATE = {
    errors: {}
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case SERVER_ERROR:
            return {...state, errors: action.payload}
        case CLEAR_ERRORS:
            return {...state, errors: {}}
        default:
            return state
    }
}