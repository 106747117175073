import {useState, useEffect, useRef} from "react";


const useComponentVisible = (initialIsVisible) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideModal = (event) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleHideModal, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideModal, true);
            document.removeEventListener('click', handleClickOutside, true);
        }
    })

    return [
        ref,
        isComponentVisible,
        setIsComponentVisible,
    ]
}

export default useComponentVisible;