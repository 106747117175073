import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useComponentVisible from "../../hooks/useComponentVisible";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import "swiper/css/pagination";

import {
  loginAction,
  changeSignupModalDisplay,
} from "../../actions/authActions";
import { clearServerErrors } from "../../actions/errors";

const lngs = ["ka", "en", "ru"];
const lngsName = { ka: "ქართული", en: "English", ru: "Русский" };

const Login = ({
  loginAction,
  serverErrors,
  clearServerErrors,
  loginModalDisplay,
  setLoginModalDisplay,
  changeSignupModalDisplay,
}) => {
  const [langSwitcherRef, isLangSwicherVisalbe, setIsLangSwicherVisable] =
    useComponentVisible(false);
  const { t, i18n } = useTranslation();
  const LoginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onSubmit", resolver: yupResolver(LoginSchema) });

  useEffect(() => {
    clearServerErrors();
  }, []);

  const userPass = document.querySelector(".input-user-password");

  function showMePass() {
    if (userPass.hasAttribute("type", "password")) {
      userPass.removeAttribute("type");
    } else {
      userPass.setAttribute("type", "password");
    }
  }

  return (
    <div
      style={{ display: loginModalDisplay }}
      id="myModalL"
      className="modal-background"
    >
      <div className="login-signup-parent-modal-container">
        <div className="login-container animate__slideInDown">
          <div className="logo-and-language-switcher-container">
            <span
              className="close_login"
              onClick={() => setLoginModalDisplay("none")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g>
                  <g>
                    <path
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 18l-6-6m0 0L6 6m6 6l6-6m-6 6l-6 6"
                    ></path>
                  </g>
                </g>
              </svg>

              {t("close modal")}
            </span>

            <div
              className={
                "lang-switcher-container " +
                (isLangSwicherVisalbe ? "active" : "")
              }
              onClick={() => setIsLangSwicherVisable(!isLangSwicherVisalbe)}
              ref={langSwitcherRef}
            >
              <button className="lang-list-btn">
                <img src={`/img/${localStorage.getItem("i18nextLng")}.png`} />
                <svg
                  className="earth"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  fill="none"
                  viewBox="0 0 21 20"
                >
                  <path
                    fill="#272A37"
                    d="M10.49 0C4.97 0 .5 4.48.5 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10S16.02 0 10.49 0zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0117.42 6zM10.5 2.04c.83 1.2 1.48 2.53 1.91 3.96H8.59c.43-1.43 1.08-2.76 1.91-3.96zM2.76 12c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38C6.06 8.66 6 9.32 6 10c0 .68.06 1.34.14 2H2.76zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 013.58 14zm2.95-8H3.58a7.987 7.987 0 014.33-3.56A15.65 15.65 0 006.53 6zm3.97 11.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM12.84 12H8.16c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM14.86 12c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                  ></path>
                </svg>
                <span>{lngsName[localStorage.getItem("i18nextLng")]}</span>
                <svg
                  className="language-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 8 6"
                >
                  <path
                    stroke="#BEBFC3"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1.5l3 3 3-3"
                  ></path>
                </svg>
              </button>

              <div
                className={
                  "lang-list-container " +
                  (isLangSwicherVisalbe ? "active" : "")
                }
              >
                <ul className="">
                  <h3>{t("language")}</h3>
                  {lngs.map((lng) => {
                    if (lng === localStorage.getItem("i18nextLng")) {
                      return;
                    } else {
                      return (
                        <>
                          <li
                            className="lang-list-button"
                            type="submit"
                            key={lng}
                            onClick={() => i18n.changeLanguage(lng)}
                            disabled={i18n.resolvedLanguage === lng}
                          >
                            <img src={`/img/${lng}.png`} />
                            <p>{lngsName[lng]}</p>
                          </li>
                        </>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(loginAction)} noValidate autoComplete="">
            <h4 className="login-title">{t("login")}</h4>
            <label>
              <input
                type="email"
                placeholder={t("Enter your email")}
                {...register("email")}
              />
            </label>
            {errors.email && (
              <a className="error-text">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                </svg>
                {t(errors.email?.message.key)}
              </a>
            )}

            <label>
              <input
                className="input-user-password"
                type="password"
                placeholder={t("Enter password")}
                {...register("password")}
              />

              <div className="visible-notvisible">
                <input
                  className="showhidepass"
                  onClick={showMePass}
                  type="checkbox"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="pass-notvisible"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  >
                    <path d="M15.58 12c0 1.98-1.6 3.58-3.58 3.58S8.42 13.98 8.42 12s1.6-3.58 3.58-3.58 3.58 1.6 3.58 3.58z"></path>
                    <path d="M12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-2.29-3.6-5.58-5.68-9.11-5.68-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19 2.29 3.6 5.58 5.68 9.11 5.68z"></path>
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="pass-visible"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  >
                    <path d="M14.53 9.47l-5.06 5.06a3.576 3.576 0 115.06-5.06z"></path>
                    <path d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73c-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19.79 1.24 1.71 2.31 2.71 3.17M8.42 19.53c1.14.48 2.35.74 3.58.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-.33-.52-.69-1.01-1.06-1.47M15.51 12.7a3.565 3.565 0 01-2.82 2.82M9.47 14.53L2 22M22 2l-7.47 7.47"></path>
                  </g>
                </svg>
              </div>
            </label>

            <div className="forgetpass-container">
              <a href="/request/reset/password">{t("forgot_password")}</a>
            </div>
            {errors.password && (
              <a className="error-text">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                </svg>
                {t(errors.password?.message.key)}
              </a>
            )}
            {serverErrors.error && (
              <a className="server-error-text">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                </svg>
                {t(serverErrors.error)}
              </a>
            )}
            <input type="submit" value={t("login")} />
            <div className="social-login-container">
              <button>
                <img src="./img/facebook.png" />
                {t("login with facebook")}
              </button>
              <button>
                <img src="./img/google.png" />
                {t("login with google")}
              </button>
            </div>
          </form>

          <div className="login-signup-switcher">
            <p
              className="create-account"
              onClick={() => {
                setLoginModalDisplay("none");
                changeSignupModalDisplay("block");
              }}
            >
              {t("don't have an account?")} <a>-</a> <span>{t("sign_up")}</span>
            </p>
          </div>
        </div>
        <div className="login-advertise-container">
          <Swiper
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img className="" src="img/cattt.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="" src="img/back22.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="" src="img/hug.jpg" alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="advertise-container">
          <div className="vertical-lane move-down">
            <img src="img/ad.jpg" alt="" />
            <img src="img/back25.jpg" alt="" />
            <img src="img/back23.jpg" alt="" />
            <img src="img/canecorso.jpg" alt="" />
            <img src="img/eee.jpg" alt="" />
            <img src="img/aibo.jpg" alt="" />
          </div>
          <div className="vertical-lane move-up">
            <img src="img/ad.jpg" alt="" />
            <img src="img/ad.jpg" alt="" />
            <img src="img/ad.jpg" alt="" />
          </div>
          <div className="vertical-lane-box"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  changeSignupModalDisplay,
  loginAction,
  clearServerErrors,
})(Login);
