import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ImageForPostCard from "./ImageForPostCard";
import PostCardImageContainer from "./PostCardImageContainer";
import { useTranslation } from "react-i18next";

import { updateSavedPostsIDs } from "../actions/postActions";
import { DELETE_SAVED_POST, SAVE_POST } from "../actions/types";
import { calculatePrice } from "../utils";

const PostCard = ({
  data,
  updateSavedPostsIDs,
  savedPosts,
  USD_RATE,
  userCurrency,
  price_with_deal,
}) => {
  const { t } = useTranslation();
  const renderSaveIcond = () => {
    if (savedPosts.includes(data.id)) {
      return (
        <svg
          className="save-button active"
          onClick={() =>
            updateSavedPostsIDs(data.id, "delete", DELETE_SAVED_POST)
          }
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
          </svg>
        </svg>
      );
    }
    return (
      <svg
        className="save-button"
        onClick={() => updateSavedPostsIDs(data.id, "post", SAVE_POST)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
        </svg>
      </svg>
    );
  };

  const renderDepartmentAndPrice = () => {
    if (data.department === "for_sale" && data.price_with_deal === true) {
      return <p className="card-1-price-with-deal">{t("price with deal")}</p>;
    } else if (
      data.department === "for_sale" &&
      data.price_with_deal === false
    ) {
      return (
        <p>
          {calculatePrice(
            data.price_in_GEL,
            data.price_in_USD,
            data.price_with_deal,
            userCurrency,
            USD_RATE
          )}{" "}
          {userCurrency === "GEL" ? "₾" : "$"}
        </p>
      );
    } else if (data.department === "adopt") {
      return <span className="adopt">{t("adopt")}</span>;
    }
  };

  const renderVaccineIcon = () => {
    if ((data.is_vaccinated = true)) {
      return (
        <span className="vaccine-icon-container green">{t("vaccinated")}</span>
      );
    } else if ((data.is_vaccinated = false)) {
      return (
        <span className="vaccine-icon-container red">
          {t("not vaccinated")}
        </span>
      );
    }
  };

  return (
    <div className="card">
      <div className="slide-img-box-for-postcard">
        <Link to={`/post/${data.id}`}>
          <PostCardImageContainer
            data={data}
            ImageRenderer={ImageForPostCard}
          />
        </Link>
      </div>

      <div className="card-info-container">
        <div className="breed">
          <Link className="breed-text" to={`/post/${data.id}`}>
            {t(data.breed)}
          </Link>
          <div className="save-button-container">{renderSaveIcond()}</div>
        </div>

        <div className="age-city">
          <a className="gender">{t(data.gender)}</a>
          <a className="age">
            {data.age} {t("month")}
          </a>
          {/* <a className="city">{t(data.city)}</a> */}
        </div>

        <div className="price">{renderDepartmentAndPrice()}</div>

        <hr />

        <div className="vaccine-documents">
          {data.is_vaccinated ? (
            <>
              <span className="vaccine-icon-container" title={t("vaccinated")}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#1C274C"
                    fillRule="evenodd"
                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm4.03-13.03a.75.75 0 010 1.06l-5 5a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 011.06 0z"
                    clipRule="evenodd"
                  ></path>
                </svg> */}
                {t("vaccinated")}
              </span>
            </>
          ) : (
            <>
              <span
                className="vaccine-icon-container not"
                title={t("not vaccinated")}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#1C274C"
                    fillRule="evenodd"
                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zM8.97 8.97a.75.75 0 011.06 0L12 10.94l1.97-1.97a.75.75 0 011.06 1.06L13.06 12l1.97 1.97a.75.75 0 11-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 11-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  ></path>
                </svg> */}
                {t("not vaccinated")}
              </span>
            </>
          )}

          {data.documents ? (
            <>
              <span className="documents" title={t("with documents")}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#1C274C"
                    fillRule="evenodd"
                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm4.03-13.03a.75.75 0 010 1.06l-5 5a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 011.06 0z"
                    clipRule="evenodd"
                  ></path>
                </svg> */}
                {t("with documents")}
              </span>
            </>
          ) : (
            <>
              <span className="documents not" title={t("without documents")}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#1C274C"
                    fillRule="evenodd"
                    d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zM8.97 8.97a.75.75 0 011.06 0L12 10.94l1.97-1.97a.75.75 0 011.06 1.06L13.06 12l1.97 1.97a.75.75 0 11-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 11-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  ></path>
                </svg> */}
                {t("without documents")}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedPosts: state.posts.savedPosts,
    userCurrency: state.userData.userCurrency,
    USD_RATE: state.userData.USD_RATE,
  };
};

export default connect(mapStateToProps, { updateSavedPostsIDs })(PostCard);
