import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { clearServerErrors } from "../actions/errors";

import DropDownInput from "../components/DropDownInput";

import { addNewPostAction } from "../actions/postActions";
import { DOG_BREEDS, CAT_BREEDS, CITIES } from "../utils";
import UserPanel from "../components/UserPanel";

const AddNewPost = ({ addNewPostAction }) => {
  const { t } = useTranslation();
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    clearServerErrors();
  }, []);

  const AddNewArtWorkSchema = yup.object().shape({
    department: yup.string().required(),
    animal_type: yup.string().required(),
    breed: yup
      .string()
      .test("not-required-if-birds-rebbit", "required_field", function (value) {
        const animal_type = this.resolve(yup.ref("animal_type"));
        if (["cat", "dog"].includes(animal_type)) {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    gender: yup.string().required(),
    age: yup
      .string()
      .test("not-required-if-for-found", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        if (department !== "found") {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    city: yup.string().required(),
    post_type: yup.string().required(),
    price_with_deal: yup.bool().default(false),
    price: yup
      .mixed()
      .test("required-if-for-sale", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        const price_with_deal = this.resolve(yup.ref("price_with_deal"));
        if (department === "for_sale" && price_with_deal === true) {
          return true;
        }
        if (department === "for_sale") {
          return yup.number().required().isValidSync(value);
        }
        return true;
      }),
    currency: yup
      .string()
      .test("required-if-for-sale", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        const price_with_deal = this.resolve(yup.ref("price_with_deal"));
        if (department === "for_sale" && price_with_deal === true) {
          return true;
        }
        if (department === "for_sale") {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    adopt_from_shelter: yup
      .bool()
      .test("required-if-adopt", "required_field", function (value) {
        const department = this.resolve(yup.ref("department"));
        if (department === "adopt") {
          return yup.string().required().isValidSync(value);
        }
        return true;
      }),
    documents: yup
      .string()
      .test(
        "not-required-if-missing-and-found",
        { key: "required_field" },
        function (value) {
          const department = this.resolve(yup.ref("department"));
          if (!["found", "missing"].includes(department)) {
            return yup.string().required().isValidSync(value);
          }
          return true;
        }
      ),
    is_vaccinated: yup.bool().required(),
    description: yup.string(),
    images: yup.array(),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(AddNewArtWorkSchema),
    defaultValues: {
      price_with_deal: false,
    },
  });

  const handleSubmitClick = (data) => {
    addNewPostAction(data);
    reset();
  };

  const renderPriceAndCurrency = () => {
    const department = watch("department");
    const price_with_deal = watch("price_with_deal");
    if (price_with_deal === true) {
      return (
        <>
          <div className="wrapper2 for-currency-price-deal">
            <input
              type="number"
              name="price"
              placeholder={t("price")}
              {...register("price")}
              disabled
            />

            <DropDownInput
              name="currency"
              fieldName="currency"
              customClassNameSelectBtn="none"
              customClassNameSelecMenuDropDown="currency-disabled"
              options={["GEL", "USD"]}
              handleOptionClick={setValue}
            />

            <button
              {...register("price_with_deal")}
              type="button"
              className="price-deal active"
              onClick={() => {
                setValue("price_with_deal", !getValues("price_with_deal"));
              }}
            >
              {" "}
              {t("price with deal")}
            </button>
          </div>
        </>
      );
    }
    if (department === "for_sale" && price_with_deal === false) {
      return (
        <>
          <div className="wrapper2 for-currency-price-deal">
            <input
              type="number"
              name="price"
              placeholder={t("price")}
              {...register("price")}
            />
            {errors.price && (
              <p className="error-text">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                  <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                </svg>
                {t(errors.price?.message)}
              </p>
            )}

            <DropDownInput
              name="currency"
              fieldName="currency"
              customClassNameSelectBtn="none"
              customClassNameSelecMenuDropDown="currency-enable"
              options={["GEL", "USD"]}
              handleOptionClick={setValue}
            />

            <button
              {...register("price_with_deal")}
              type="button"
              className="price-deal"
              onClick={() => {
                setValue("price_with_deal", !getValues("price_with_deal"));
              }}
            >
              {" "}
              {t("price with deal")}
            </button>
          </div>
        </>
      );
    }
  };

  const renderAdoptFromshelter = () => {
    const department = watch("department");
    if (department === "adopt") {
      return (
        <div className="wrapper2">
          <DropDownInput
            name="adopt_from_shelter"
            fieldName="adopt_from_shelter"
            options={["yes", "no"]}
            backendValues={[true, false]}
            handleOptionClick={setValue}
          />
        </div>
      );
    }
  };

  const renderBreedOptions = () => {
    const breeds = { dog: DOG_BREEDS, cat: CAT_BREEDS, bird: ["qwe", "qwe"] };
    const petType = watch("animal_type", []);
    if (["dog", "cat", "bird"].includes(petType)) {
      return breeds[petType];
    }
    return [];
  };

  const handleImageChange = (e) => {
    const fileList = e.target.files;
    let images = getValues("images");
    if (images !== undefined) {
      images = [...images, ...fileList];
    } else {
      images = [...fileList];
    }

    setValue("images", images);
    // Generate previews for selected images
    const previews = [];
    for (let i = 0; i < images.length; i++) {
      previews.push(URL.createObjectURL(images[i]));
    }
    setImagePreviews(previews);
  };

  const removeImage = (index) => {
    // Remove the image at the specified index
    setImagePreviews((images) => images.filter((_, i) => i !== index));
    let images = getValues("images");
    const filterImages = images.filter((_, i) => i !== index);
    setValue("images", filterImages);
  };

  const renderImageInputs = () => {
    const imageInputs = [];
    for (let i = 0; i < 7; i++) {
      imageInputs.push(
        <>
          <span key={i} className="upload-photo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid"
              viewBox="0 -3 32 32"
            >
              <path d="M29 26H3c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h4.381L9.102.554A.996.996 0 019.996 0h12.01a1 1 0 01.895.554L24.619 4H29c1.654 0 3 1.346 3 3v16c0 1.654-1.346 3-3 3zm1-19c0-.551-.449-1-1-1h-5c-.05 0-.093-.021-.141-.028-.071-.011-.142-.017-.21-.043-.061-.023-.112-.06-.167-.095-.054-.033-.109-.061-.156-.105-.053-.049-.091-.109-.132-.169-.028-.04-.067-.069-.089-.114L21.387 2H10.615l-1.72 3.446a.986.986 0 01-.18.249c-.014.015-.031.024-.046.038a.984.984 0 01-.325.198c-.11.04-.224.068-.342.069H3c-.551 0-1 .449-1 1v16c0 .551.449 1 1 1h26c.551 0 1-.449 1-1V7zM16 21c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"></path>
            </svg>
            <p>{t("upload photo")}</p>
            <input
              type="file"
              name={`images`}
              id={`images`}
              placeholder=""
              onChange={handleImageChange}
            />
          </span>

          {imagePreviews[i] && (
            <span className="uploaded-photo-box">
              <img src={imagePreviews[i]} alt={`Preview-${i}`} />
              <span className="delete-uploaded-photo-hover">
                <span
                  className="delete-uploaded-photo"
                  onClick={() => removeImage(i)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#1C274C">
                      <path
                        fillRule="evenodd"
                        d="M10.31 2.25h3.38c.217 0 .406 0 .584.028a2.25 2.25 0 011.64 1.183c.084.16.143.339.212.544l.111.335a1.25 1.25 0 001.263.91h3a.75.75 0 010 1.5h-17a.75.75 0 010-1.5h3.09a1.25 1.25 0 001.173-.91l.112-.335c.068-.205.127-.384.21-.544a2.25 2.25 0 011.641-1.183c.178-.028.367-.028.583-.028zm-1.302 3a2.757 2.757 0 00.175-.428l.1-.3c.091-.273.112-.328.133-.368a.75.75 0 01.547-.395c.045-.007.104-.009.392-.009h3.29c.288 0 .348.002.392.01a.75.75 0 01.547.394c.021.04.042.095.133.369l.1.3.039.112c.039.11.085.214.136.315H9.008z"
                        clipRule="evenodd"
                      ></path>
                      <path d="M5.915 8.45a.75.75 0 10-1.497.1l.464 6.952c.085 1.282.154 2.318.316 3.132.169.845.455 1.551 1.047 2.104.591.554 1.315.793 2.17.904.822.108 1.86.108 3.146.108h.879c1.285 0 2.324 0 3.146-.108.854-.111 1.578-.35 2.17-.904.591-.553.877-1.26 1.046-2.104.162-.813.23-1.85.316-3.132l.464-6.952a.75.75 0 00-1.497-.1l-.46 6.9c-.09 1.347-.154 2.285-.294 2.99-.137.685-.327 1.047-.6 1.303-.274.256-.648.422-1.34.512-.713.093-1.653.095-3.004.095h-.774c-1.35 0-2.29-.002-3.004-.095-.692-.09-1.066-.256-1.34-.512-.273-.256-.463-.618-.6-1.302-.14-.706-.204-1.644-.294-2.992l-.46-6.899z"></path>
                      <path d="M9.425 10.254a.75.75 0 01.821.671l.5 5a.75.75 0 01-1.492.15l-.5-5a.75.75 0 01.671-.821zM14.575 10.254a.75.75 0 01.671.82l-.5 5a.75.75 0 01-1.492-.149l.5-5a.75.75 0 01.82-.671z"></path>
                    </g>
                  </svg>
                </span>
              </span>
            </span>
          )}
        </>
      );
    }
    return imageInputs;
  };

  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="add-post-container">
          <form className="add-post" onSubmit={handleSubmit(handleSubmitClick)}>
            <div className="fields-container">
              <div className="wrapper2">
                <DropDownInput
                  name="department"
                  fieldName="department"
                  options={["for_sale", "adopt", "missing", "found"]}
                  handleOptionClick={setValue}
                />
                {errors.department && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.department?.message.key)}
                  </p>
                )}
              </div>

              {renderPriceAndCurrency()}
              {renderAdoptFromshelter()}

              <div className="wrapper2">
                <DropDownInput
                  name="animal_type"
                  fieldName="animal_type"
                  options={["dog", "cat", "bird"]}
                  handleOptionClick={setValue}
                />
                {errors.animal_type && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.animal_type?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="breed"
                  fieldName="breed"
                  options={renderBreedOptions()}
                  handleOptionClick={setValue}
                  search={true}
                />
                {errors.breed && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.breed?.message)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="gender"
                  fieldName="gender"
                  options={["male", "female"]}
                  handleOptionClick={setValue}
                />
                {errors.gender && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.gender?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="age"
                  fieldName="age"
                  options={[1, 2, 3, 4, 5, 6, 7]}
                  handleOptionClick={setValue}
                />
                {errors.age && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.age?.message)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="documents"
                  fieldName="documents"
                  options={["yes", "no"]}
                  handleOptionClick={setValue}
                />
                {errors.documents && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.documents?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="is_vaccinated"
                  fieldName="is_vaccinated"
                  options={["yes", "no"]}
                  backendValues={[true, false]}
                  handleOptionClick={setValue}
                />
                {errors.is_vaccinated && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.is_vaccinated?.message.key)}
                  </p>
                )}
              </div>
              <div className="wrapper2">
                <DropDownInput
                  name="city"
                  fieldName="city"
                  options={CITIES}
                  handleOptionClick={setValue}
                  search={true}
                />
                {errors.city && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.city?.message.key)}
                  </p>
                )}
              </div>

              <div className="wrapper2">
                <DropDownInput
                  name="post_type"
                  fieldName="post_type"
                  options={[
                    "vip1",
                    "vip2",
                    "vip3",
                    "vip4",
                    "vip5",
                    "vip6",
                    "vip7",
                    "i dont want",
                  ]}
                  backendValues={[
                    "VIP:1",
                    "VIP:2",
                    "VIP:3",
                    "VIP:4",
                    "VIP:5",
                    "VIP:6",
                    "VIP:7",
                    "standard",
                  ]}
                  handleOptionClick={setValue}
                />
                {errors.post_type && (
                  <p className="error-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                    </svg>
                    {t(errors.post_type?.message.key)}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="upload-post-img">{renderImageInputs()}</div>
            <br />

            {errors.images && (
              <p className="error-text">{t(errors.images.message)}</p>
            )}
            <br />

            <textarea
              {...register("description")}
              id="description"
              name="description"
              placeholder={t("Description")}
              rows="4"
              cols="50"
            ></textarea>

            <br />

            <input type="submit" value={t("Add post")} />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  clearServerErrors,
  addNewPostAction,
})(AddNewPost);
