import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfirmationDialog from "../components/ConfirmationDialog";
import AddVIPStatusModal from "./AddVIPStatus";
import useComponentVisible from "../hooks/useComponentVisible";
import ImageForPostCard from "../components/ImageForPostCard";
import { DELETE_SINGLE_POST } from "../actions/types";
import { calculatePrice, formatDate } from "../utils";

const ActivePostsCard = ({
  post,
  USD_RATE,
  userCurrency,
  singlePostAction,
}) => {
  const { t } = useTranslation();
  const [
    confirmationModalRef,
    isConfirmationVisable,
    setIsConfirmationVisable,
  ] = useComponentVisible(false);

  const [
    AddVIPStatusRef,
    isAddVIPStatusModalVisable,
    setIsAddVIPStatusVisable,
  ] = useComponentVisible(false);

  const renderVIPStatus = () => {
    if (post.post_type === "standard") {
      return t("no");
    }
    return t("yes");
  };
  const renderAddVIPButton = () => {
    if (post.post_type === "standard") {
      return (
        <a
          className="add-vip"
          name={t("add vip")}
          onClick={() => setIsAddVIPStatusVisable(!isAddVIPStatusModalVisable)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-192 -192)">
                  <g transform="translate(192 192)">
                    <path
                      fillRule="nonzero"
                      d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                    ></path>
                    <path
                      fill="#000"
                      d="M12.987 5.74a2 2 0 10-1.97.002l-.01.018c-.655 1.367-1.565 3.325-2.97 4.06-1.154.603-2.81.3-4.04.074a1.5 1.5 0 10-1.772 1.58l2.948 7.61A3 3 0 007.97 21h8.06a3 3 0 002.797-1.916l2.947-7.61a1.5 1.5 0 10-1.767-1.624c-1.259.163-2.882.371-4.044-.236-1.377-.72-2.3-2.543-2.976-3.874zm-.962 2.367c.768 1.246 1.689 2.587 3.012 3.28 1.327.693 2.96.658 4.42.533l-2.495 6.441a1 1 0 01-.932.639H7.97a1 1 0 01-.932-.639l-2.461-6.353c1.439.184 3.067.274 4.386-.416 1.378-.72 2.3-2.172 3.062-3.485z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      );
    }
  };
  return (
    <>
      <ConfirmationDialog
        modalRef={confirmationModalRef}
        isVisable={isConfirmationVisable}
        setIsVisable={setIsConfirmationVisable}
        onConfirm={() =>
          singlePostAction(post.id, "delete", DELETE_SINGLE_POST)
        }
      />
      <AddVIPStatusModal
        modalRef={AddVIPStatusRef}
        isVisable={isAddVIPStatusModalVisable}
        setIsVisable={setIsAddVIPStatusVisable}
        post={post}
      />

      <div className="myactive-post-lit-cont">
        <div className="act-post-img">
          <ImageForPostCard src={post.preview_img} />
        </div>

        <div className="myactive-post-info-container">
          <div className="myactive-post-info-inside-container">
            <ul className="active-post-options right-border">
              <li className="bold">
                <span>{t(post.department)}</span>
              </li>
              <li className="bold">
                <span>{t("id")} </span>
                <span>-</span>
                <span>{post.id}</span>
              </li>
              <li>
                <span>{t("vip")}</span>
                <span>-</span>
                <span>{renderVIPStatus()}</span>
              </li>
              <li>
                <span>{t("views")}</span>
                <span>-</span>
                <span>{post.views}</span>
              </li>
            </ul>

            <hr />

            <ul className="">
              <span>{t(post.breed)}</span>
              <span>{t(post.animal_type)}</span>
              <span>{t(post.gender)}</span>
              <span>
                {t(post.age)} {t("month")}
              </span>
            </ul>

            <hr />

            <ul className="pet-options-last-container">
              {post.is_vaccinated ? (
                <>
                  <span className="vaccine green">{t("vaccinated")}</span>
                </>
              ) : (
                <>
                  <span className="vaccine red">{t("not vaccinated")}</span>
                </>
              )}
              <span>
                {post.documents ? (
                  <>
                    <span className="vaccine green">{t("with documents")}</span>
                  </>
                ) : (
                  <>
                    <span className="vaccine red">
                      {t("without documents")}
                    </span>
                  </>
                )}
              </span>
              <span>{t(post.city)}</span>
              {/* <span>{t(post.user_phone_number)}</span> */}
              <li>
                <span>{t("date")}</span>
                <span>-</span>
                <span>{formatDate(post.created_at)}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="vip-edit-delete-container">
          <div className="edit-delete">
            {renderAddVIPButton()}
            <Link
              to={`/update/post/${post.id}`}
              className="edit-post"
              name={t("Edit")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g>
                  <g>
                    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
                      <path d="M4.337 16.071l-.394 3.618a.5.5 0 00.55.551l3.619-.394a1.5 1.5 0 00.898-.43L20 8.423a1.5 1.5 0 000-2.121L17.88 4.182a1.5 1.5 0 00-2.121 0L4.766 15.172a1.5 1.5 0 00-.43.899zM18.94 3.121l2.12 2.121a3 3 0 010 4.243l-10.99 10.991a3 3 0 01-1.797.861l-3.618.394a2 2 0 01-2.204-2.205l.394-3.617a3 3 0 01.86-1.797L14.699 3.121a3 3 0 014.242 0z"></path>
                      <path d="M20.001 6.303L17.88 4.182a1.5 1.5 0 00-2.121 0l-1.273 1.272 4.242 4.243 1.273-1.273a1.5 1.5 0 000-2.121zm-1.273 5.515l-6.364-6.364 2.334-2.333a3 3 0 014.242 0l2.122 2.121a3 3 0 010 4.243l-2.334 2.333z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
            <a
              className="red delete-post"
              onClick={() => setIsConfirmationVisable(true)}
              name={t("Delete")}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="none"
                    strokeWidth="1"
                  >
                    <g transform="translate(-288 -48)">
                      <g transform="translate(288 48)">
                        <path
                          fillRule="nonzero"
                          d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                        ></path>
                        <path
                          fill="#c70000"
                          d="M12 14.121l5.303 5.304a1.5 1.5 0 002.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 10-2.122-2.121L12 9.879 6.697 4.576a1.5 1.5 0 10-2.122 2.12L9.88 12l-5.304 5.303a1.5 1.5 0 102.122 2.122L12 14.12z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePostsCard;
