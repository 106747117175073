import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Rules = () => {
  return (
    <>
      <div className="rules-parent-container">
        <div className="rules-parent-container-header"></div>
        <div className="rules-inside-container">
          <ul className="table-of-content">
            <li>
              <a href="#1">როგორ ვიყენებთ თქვენს პერსონალურ მონაცემებს</a>
            </li>
            <li>
              <a href="#2">პერსონალურ მონაცემთა დაცვის დაპირება</a>
            </li>
            <li>
              <a href="#3">ვინ ვართ ჩვენ</a>
            </li>
            <li>
              <a href="#4">როგორ გიცავთ კანონი და მომხმარებლის უფლებები</a>
            </li>
            <li>
              <a href="#5">
                შეგროვებული მონაცემების სახეობა და მისი გამოყენება
              </a>
            </li>
            <li>
              <a href="#6">„მზა ჩანაწერები“ (Cookies)</a>
            </li>
            <li>
              <a href="#7">
                როგორ ვიყენებთ თქვენს მონაცემებს ავტომატური გადაწყვეტილებების
                მიღების პროცესში
              </a>
            </li>
            <li>
              <a href="#8">პერსონალური მონაცემების ჯგუფები</a>
            </li>
            <li>
              <a href="#9">
                რისთვის ვიყენებთ თქვენს პერსონალურ მონაცემებს და ჩვენი მიზანი
              </a>
            </li>
            <li>
              <a href="#10">
                მონაცემთა დამუშავების ჩვენი ლეგიტიმური ინტერესები:
              </a>
            </li>
            <li>
              <a href="#11">
                მონაცემთა დამუშავების ჩვენი სამართლებრივი საფუძველი:
              </a>
            </li>
            <li>
              <a href="#12">
                წყაროები, საიდანაც მოვიპოვებთ პერსონალურ მონაცემებს
              </a>
            </li>
            <li>
              <a href="#13">ვის ვუზიარებთ თქვენს პერსონალურ მონაცემებს</a>
            </li>
            <li>
              <a href="#14">
                GDPR-ის საფუძველზე არსებული სპეციალური ღონისძიებები
              </a>
            </li>
            <li>
              <a href="#15">პერსონალური მონაცემების უსაფრთხოება</a>
            </li>
            <li>
              <a href="#16">თაღლითობის გამოვლენა</a>
            </li>
            <li>
              <a href="#17">
                პერსონალური მონაცემების დაცვის სხვადასხვა საკითხი
              </a>
            </li>
            <li>
              <a href="#18">საჯარო ადგილები</a>
            </li>
            <li>
              <a href="#19">„Facebook ავტორიზაცია“</a>
            </li>
            <li>
              <a href="#2o">„Google ავტორიზაცია“</a>
            </li>
            <li>
              <a href="#21">პერსონალური მონაცემების შენახვის ვადა</a>
            </li>
            <li>
              <a href="#22">
                მონაცემთა პირდაპირი მარკეტინგის მიზნისთვის დამუშავება
              </a>
            </li>
            <li>
              <a href="#23">
                მონაცემთა პირდაპირი მარკეტინგის მიზნით შეწყვეტის
                მოთხოვნა/თანხმობის გახმობა
              </a>
            </li>
            <li>
              <a href="#24">როგორ გაითხოვოთ თქვენი თანხმობა</a>
            </li>
            <li>
              <a href="#25">კონფიდენციალობის პოლიტიკის ცვლილება</a>
            </li>
            <li>
              <a href="#26">როგორ დაგვიკავშირდეთ</a>
            </li>
          </ul>

          <div className="editor">
            <h2>წესები და პირობები - 29.02.2024</h2>
            <div className="read-rules">
              <p>rules here</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
