import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { requestResetPasswordAction } from "../../actions/authActions";
import { clearServerErrors } from "../../actions/errors";

const RequestResetPassword = ({
  requestResetPasswordAction,
  serverErrors,
  clearServerErrors,
}) => {
  const { t } = useTranslation();
  const RequestResetPasswordSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(RequestResetPasswordSchema),
  });

  useEffect(() => {
    clearServerErrors();
  }, []);

  return (
    <div className="parent-container-short">
      <div className="recover-password">
        <p className="recover-password-title">{t("recover password")}</p>
        {/* <p className="has-text-centered">{t("reset_password_page_hint")}</p> */}
        <form
          onSubmit={handleSubmit(requestResetPasswordAction)}
          noValidate
          autoComplete="off"
        >
          <input
            className="input"
            placeholder={t("Enter your email")}
            {...register("email")}
          />
          {errors.email && (
            <p className="help is-danger error-text">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
              </svg>
              {t(errors.email?.message.key)}
            </p>
          )}
          {serverErrors.error && (
            <p className="help is-danger error-text">{t(serverErrors.error)}</p>
          )}

          <div className="check-code">
            <img src="../../img/code.png" alt="" />
            <input type="text" placeholder={t("code")} />
          </div>

          <button type="submit">{t("confirm")}</button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  requestResetPasswordAction,
  clearServerErrors,
})(RequestResetPassword);
