import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ShareModal = ({ isVisible }) => {
  const { t } = useTranslation();
  if (!isVisible) return null;

  const copy = () => {
    const currentUrl = document.createElement("input");
    currentUrl.value = window.location.href;
    document.body.appendChild(currentUrl);
    currentUrl.select();
    document.execCommand("copy");
    currentUrl.remove();
  };

  return (
    <span className="modalbox-for-links">
      <a
        class="fb-share-button fb-xfbml-parse-ignore"
        data-href="https://pethub.ge"
        data-layout=""
        data-size=""
        target="_blank"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpethub.ge%2F&amp;src=sdkpreparse"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 192 192"
        >
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="12"
            d="M96 170c40.869 0 74-33.131 74-74 0-40.87-33.131-74-74-74-40.87 0-74 33.13-74 74 0 40.869 33.13 74 74 74zm0 0v-62m30-48h-10c-11.046 0-20 8.954-20 20v28m0 0H74m22 0h22"
          ></path>
        </svg>
        {t("share on facebook")}
      </a>

      <p onClick={copy()}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <g
            stroke="#292D32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path d="M14.99 17.5h1.51c3.02 0 5.5-2.47 5.5-5.5 0-3.02-2.47-5.5-5.5-5.5h-1.51M9 6.5H7.5A5.51 5.51 0 002 12c0 3.02 2.47 5.5 5.5 5.5H9M8 12h8"></path>
          </g>
        </svg>
        {t("copy link")}
      </p>
    </span>
  );
};

export default ShareModal;
