import React from "react";
import { connect } from "react-redux";

import BigPostCard from "./Card";
import Paginate from "./Paginate";
import RightContianer from "./RightContainer";
import FilterLeftVersion from "./FIlterLeftVersion";

const PostCardContainer = ({
  activePage,
  postsForContainer,
  department = null,
  banner,
}) => {
  const renderPosts = () => {
    if (postsForContainer) {
      return postsForContainer.results.map((item) => {
        return <BigPostCard data={item} key={item.id} />;
      });
    }
  };

  return (
    <>
      {banner ? (
        <div className="sticky-banner-container">
          <span className="close_sticky_banner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#0F1729"
                fillRule="evenodd"
                d="M5.293 5.293a1 1 0 011.414 0L12 10.586l5.293-5.293a1 1 0 111.414 1.414L13.414 12l5.293 5.293a1 1 0 01-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 01-1.414-1.414L10.586 12 5.293 6.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
          <a href={banner.url} target="_blank">
            <img src={banner.banner_img} alt="sticky-banner" />
          </a>
        </div>
      ) : (
        <></>
      )}
      <div className="parent-container-short">
        <div className="main-container">
          <div className="left-container flex">
            <FilterLeftVersion />

            <div className="card-2-container">
              <div className="inside-container">{renderPosts()}</div>
              {postsForContainer ? (
                <Paginate
                  postsCount={postsForContainer.count}
                  activePage={activePage}
                  department={department}
                />
              ) : (
                <></>
              )}
            </div>

            {/* {banner ? (
              <div className="sticky-banner-container">
                <a href={banner.url} target="_blank">
                  <img src={banner.banner_img} alt="sticky-banner" />
                </a>
              </div>
            ) : (
              <></>
            )} */}
          </div>

          <RightContianer />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    postsForContainer: state.posts.posts_for_container,
    banner: state.management.searchBanner,
  };
};

export default connect(mapStateToProps, {})(PostCardContainer);
