import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import UserPanel from "../components/UserPanel";
import ActivePostsCard from "../components/ActivePostsCard";
import {
  getUserActivePostsAction,
  singlePostAction,
} from "../actions/postActions";

const ActivePosts = ({
  getUserActivePostsAction,
  userActivePosts,
  singlePostAction,
  USD_RATE,
  userCurrency,
}) => {
  useEffect(() => {
    getUserActivePostsAction();
  }, []);

  const { t } = useTranslation();
  const renderPosts = () => {
    if (userActivePosts !== null) {
      return userActivePosts.map((post) => {
        return (
          <ActivePostsCard
            post={post}
            USD_RATE={USD_RATE}
            userCurrency={userCurrency}
            singlePostAction={singlePostAction}
          />
        );
      });
    } else {
      return (
        <div className="empty-container">
          <img src="/img/empty1.png" alt="" />
          <p>{t("you have not active posts")}</p>
        </div>
      );
    }
  };
  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="active-post-container">{renderPosts()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userActivePosts: state.posts.userActivePosts,
  };
};

export default connect(mapStateToProps, {
  getUserActivePostsAction,
  singlePostAction,
})(ActivePosts);
