import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ImageForPostCard from "../components/ImageForPostCard";
import { calculatePrice } from "../utils";
import { useTranslation } from "react-i18next";

import UserPanel from "../components/UserPanel";
import {
  getFavoritePostsAction,
  updateSavedPostsIDs,
} from "../actions/postActions.js";
import { DELETE_SAVED_POST } from "../actions/types";

const Favorite = ({
  data,
  getFavoritePostsAction,
  favoritePosts,
  updateSavedPostsIDs,
  USD_RATE,
  userCurrency,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getFavoritePostsAction();
  }, []);

  const renderPriceOrDepartment = (post) => {
    if (post.department === "for_sale" && post.price_with_deal === true) {
      return <p className="card-1-price-with-deal">{t("price with deal")}</p>;
    } else if (
      post.department === "for_sale" &&
      post.price_with_deal === false
    ) {
      return (
        <p>
          {calculatePrice(
            post.price_in_GEL,
            post.price_in_USD,
            post.price_with_deal,
            userCurrency,
            USD_RATE
          )}{" "}
          {userCurrency === "GEL" ? "₾" : "$"}
        </p>
      );
    } else if (post.department === "found") {
      return <span className="found">{t("found")}</span>;
    } else if (post.department === "missing") {
      return <span className="missing">{t("missing")}</span>;
    } else if (post.department === "adopt") {
      return <span className="adopt">{t("adopt")}</span>;
    }
  };

  const renderFavoritePosts = () => {
    if (favoritePosts) {
      return favoritePosts.map((item) => {
        return (
          <div className="card">
            <div className="slide-img-box-for-postcard">
              <Link to={`/post/${item.post.id}`}>
                <ImageForPostCard src={item.post.preview_img} />

                <div className="dots-container">
                  <span className="dot-button">
                    <span class="dot"></span>
                  </span>

                  <span className="dot-button">
                    <span class="dot"></span>
                  </span>

                  <span className="dot-button">
                    <span class="dot"></span>
                  </span>

                  <span className="dot-button">
                    <span class="dot"></span>
                  </span>

                  <span className="dot-button">
                    <span class="dot"></span>

                    <div className="more-photos">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <g
                          stroke="#292D32"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"></path>
                          <path d="M9 10a2 2 0 100-4 2 2 0 000 4zM2.67 18.95l4.93-3.31c.79-.53 1.93-.47 2.64.14l.33.29c.78.67 2.04.67 2.82 0l4.16-3.57c.78-.67 2.04-.67 2.82 0L22 13.9"></path>
                        </g>
                      </svg>

                      <p>+ 5 Photos</p>
                    </div>
                  </span>
                </div>
              </Link>
            </div>

            <div className="card-info-container">
              <div className="breed">
                <a className="breed-text" href="./pages/seemore.html">
                  {t(item.post.breed)}
                </a>
                <div className="save-button-container">
                  <svg
                    className="save-button active"
                    onClick={() =>
                      updateSavedPostsIDs(
                        item.post.id,
                        "delete",
                        DELETE_SAVED_POST
                      )
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
                    </svg>
                  </svg>
                </div>
              </div>

              <div className="age-city">
                <a className="gender">{t(item.post.gender)}</a>
                <a className="age">
                  {item.post.age} {t("month")}
                </a>
                <a className="city">{t(item.post.city)}</a>
              </div>

              <div className="price">{renderPriceOrDepartment(item.post)}</div>

              <hr />

              <div className="vaccine-documents">
                {item.post.is_vaccinated ? (
                  <>
                    <span
                      className="vaccine-icon-container"
                      title={t("vaccinated")}
                    >
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#1C274C"
                        fillRule="evenodd"
                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm4.03-13.03a.75.75 0 010 1.06l-5 5a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 011.06 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg> */}
                      {t("vaccinated")}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="vaccine-icon-container not"
                      title={t("not vaccinated")}
                    >
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#1C274C"
                        fillRule="evenodd"
                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zM8.97 8.97a.75.75 0 011.06 0L12 10.94l1.97-1.97a.75.75 0 011.06 1.06L13.06 12l1.97 1.97a.75.75 0 11-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 11-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      ></path>
                    </svg> */}
                      {t("not vaccinated")}
                    </span>
                  </>
                )}

                {item.post.documents ? (
                  <>
                    <span className="documents" title={t("with documents")}>
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#1C274C"
                        fillRule="evenodd"
                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm4.03-13.03a.75.75 0 010 1.06l-5 5a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 011.06 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg> */}
                      {t("with documents")}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="documents not"
                      title={t("without documents")}
                    >
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#1C274C"
                        fillRule="evenodd"
                        d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zM8.97 8.97a.75.75 0 011.06 0L12 10.94l1.97-1.97a.75.75 0 011.06 1.06L13.06 12l1.97 1.97a.75.75 0 11-1.06 1.06L12 13.06l-1.97 1.97a.75.75 0 11-1.06-1.06L10.94 12l-1.97-1.97a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      ></path>
                    </svg> */}
                      {t("without documents")}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="empty-container">
        <img src="/img/empty1.png" alt="" />
        <p>{t("you have not active posts")}</p>
      </div>
    );
  };
  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="favorite-post-container">
          <div className="favorite-post-inside-container">
            {renderFavoritePosts()}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    favoritePosts: state.posts.favoritePosts,
    userCurrency: state.userData.userCurrency,
    USD_RATE: state.userData.USD_RATE,
  };
};

export default connect(mapStateToProps, {
  getFavoritePostsAction,
  updateSavedPostsIDs,
})(Favorite);
