import { SERVER_ERROR, CLEAR_ERRORS } from "./types";

export const clearServerErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const handleServerError = (dispatch, error) => {
  const errors = {};
  const data = error.response.data;

  for (var key of Object.keys(data)) {
    if (Array.isArray(data[key])) {
      errors[key] = data[key][0];
    } else {
      errors["error"] = data[key];
    }
  }
  dispatch({
    type: SERVER_ERROR,
    payload: errors,
  });
};
