import {
  CHANGE_SIGNUP_MODAL_DISPLAY,
  USER_DATA,
  GET_USER_PROFILE_DATA,
  CHANGE_USER_CURRENCY,
  GET_CURRANCY_RATE,
  CHANGE_LOGIN_MODAL_DISPLAY,
} from "../actions/types";
import { blockAndUlockScroll } from "../utils";

const getUserCurrency = () => {
  const userCurrency = localStorage.getItem("userCurrency");
  if (userCurrency === null) {
    localStorage.setItem("userCurrency", "GEL");
    return "GEL";
  }
  return userCurrency;
};

const INITIAL_STATE = {
  userData: null,
  displaySignupModal: "none",
  userProfileData: null,
  userCurrency: getUserCurrency(),
  USD_RATE: null,
  loginModalDisplay: "none",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_CURRANCY_RATE:
      return { ...state, USD_RATE: action.payload.usd_rate };
    case CHANGE_SIGNUP_MODAL_DISPLAY:
      blockAndUlockScroll(action.payload);
      return { ...state, displaySignupModal: action.payload };
    case GET_USER_PROFILE_DATA:
      return { ...state, userProfileData: action.payload };
    case CHANGE_USER_CURRENCY:
      localStorage.setItem("userCurrency", action.payload);
      return { ...state, userCurrency: action.payload };
    case CHANGE_LOGIN_MODAL_DISPLAY:
      blockAndUlockScroll(action.payload);
      return { ...state, loginModalDisplay: action.payload };
    default:
      return state;
  }
};
