import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import FilterField from "./FilterField";
import { DOG_BREEDS, CAT_BREEDS, CITIES } from "../utils";
import { getFilteredPosts } from "../actions/postActions";
import { changeUserCurrency } from "../actions/authActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import router from "../router";
import useComponentVisible from "../hooks/useComponentVisible";

const Filter = ({ getFilteredPosts, userCurrency, changeUserCurrency }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedAnimal, setSelectedAnimal] = useState("");
  useEffect(() => {
    const queryParams = { currency: userCurrency };
    searchParams.forEach((value, key) => {
      if (["documents", "price_from", "price_to"].includes(key)) {
        queryParams[key] = value;
      } else {
        value = value.split(",");
        queryParams[key] = value;
      }
    });
    getFilteredPosts(queryParams);
    setSelectedOptions(queryParams);
  }, [searchParams]);
  const [departmentRef, isDepartmentVisable, setIsDepartmentVisable] =
    useComponentVisible("");
  const [BreedRef, isBreedVisable, setIsBreedVisable] = useComponentVisible("");
  const [genderRef, isGenderVisable, setIsGenderVisable] =
    useComponentVisible("");
  const [ageRef, isAgeVisable, setIsAgeVisable] = useComponentVisible("");
  const [documentsRef, isDocumentsVisable, setIsDocumentsVisable] =
    useComponentVisible("");
  const [cityRef, isCityVisable, setIsCityVisable] = useComponentVisible("");

  const handleOptionSelect = (field, option) => {
    const selectedOptionsForField = selectedOptions[field] || [];
    const isSelected = selectedOptionsForField.includes(option);

    if (isSelected) {
      // Remove the option if already selected
      const updatedOptions = selectedOptionsForField.filter(
        (item) => item !== option
      );
      setSelectedOptions((prevState) => {
        const newState = {
          ...prevState,
          [field]: updatedOptions,
        };
        if (updatedOptions.length === 0) {
          delete newState[field];
        }
        return newState;
      });
    } else {
      // Add the option if not selected
      setSelectedOptions((prevState) => ({
        ...prevState,
        [field]: [...selectedOptionsForField, option],
      }));
    }
  };

  const handleSingleOptionSelect = (field, option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [field]: option,
    }));
  };

  const getBreeds = () => {
    if (selectedAnimal === "dog") {
      return DOG_BREEDS;
    } else if (selectedAnimal === "cat") {
      return CAT_BREEDS;
    } else if (selectedAnimal === "birds") {
      return ["1", "2"];
    }
    return [];
  };

  return (
    <div className="filter-left-version">
      <div className="filter-left-version-inside-container">
        <form className="search-box" action="">
          <input type="search" name="search" placeholder={t("head search")} />
          <input type="submit" value="search" />
        </form>

        <div className="pets-button-in-filter-container">
          <div
            className={
              "pets-button-container-for-left-version dogs-button " +
              (selectedAnimal === "dog" ? "active" : 0)
            }
            onClick={() => setSelectedAnimal("dog")}
          >
            <button type="button" className="pets-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
                version="1.1"
                viewBox="0 0 392.747 392.747"
                xmlSpace="preserve"
              >
                <path d="M379.498 90.453l-40.104-16.18c-2.449-12.946-12.287-23.311-24.947-26.528l-2.169-24.992a9 9 0 00-15.634-5.266l-10.492 11.572-6.437-22.531a9.001 9.001 0 00-8.341-6.522c-3.898-.121-7.443 2.258-8.774 5.928l-55.536 153.21c-39.95 10.374-74.835 35.938-96.262 70.724-19.626 31.862-25.722 68.366-17.165 102.786 5.001 20.117 14.496 33.373 24.209 42.065h-7.639l-.216.003c-36.135.881-69.622-19.663-85.259-52.307-2.147-4.483-7.522-6.375-12.005-4.229a9 9 0 00-4.228 12.005c18.31 38.226 57.078 62.557 99.286 62.557h171.829a9 9 0 009-9c0-24.226-19.323-35.268-36.377-39.321 7.974-15.546 8.663-29.218 7.759-38.162l2.352-.314 53.943 82.713a9.002 9.002 0 007.539 4.083h32.755a9 9 0 007.614-13.797c-.348-.552-34.166-54.247-52.346-84.309V143.416c2.676.308 5.681.463 8.335.463 26.109 0 50.664-14.73 63.885-40.848.011-.021.35-.729.404-.865a8.999 8.999 0 00-4.979-11.713z"></path>
              </svg>
              <span>{t("dog")}</span>
            </button>
          </div>

          <div
            className={
              "pets-button-container-for-left-version cats-button " +
              (selectedAnimal === "cat" ? "active" : 0)
            }
            onClick={() => setSelectedAnimal("cat")}
          >
            <button type="button" className="pets-button mid-border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
                baseProfile="tiny"
                transform="scale(-1 1)"
                version="1.2"
                viewBox="-1077 923 256 256"
                xmlSpace="preserve"
              >
                <path d="M-827.3 979.5s-2.2-13.1-5.2-17.1c-3.8-5.1-16.4-12.1-16.4-12.1-2.6 3.7 3.6-9.8 7.3-22.7 0 0-24.2 3.2-46.8 27.6-6.4 7-17.8 38.6-17.8 38.6l-44.7 20.5c-31.1 12.9-82.7 26.2-82.7 97.7v30.5c-2.5-1.7-15.7-12.7-15.5-29.9.1-13.6 1.9-27.5 3.6-39.9 3.1-22.7 5.5-47.1-3.7-57.6-4.2-4.8-10.2-7.3-17.7-7.3-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9c2.2 0 3.6.4 4.3 1.2 3.8 4.4 1.5 28.1-.6 43.4-1.7 12.9-3.7 27.5-3.7 42.2 0 16.2 5.6 31.4 15.7 42.8 11.5 12.9 27.7 19.7 46.8 19.7H-915.1c4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9h-19.6l9.5-31.3c0-4.5-1-8.8-2.7-12.6-7.1-12.1-19.9-19.6-34-19.6-6.2 0-12 1.4-17.5 4.1l-2.9-5.8c6.4-3.2 13.2-4.8 20.4-4.8 16.8 0 32.3 9.2 40.3 24 0 0 33.1 50.7 36.4 57.9 0 .1.1.1.1.2.9 2.7 3.1 4.7 5.9 5.3.1 0 .2 0 .3.1.5.1.9.3 1.4.3h9.8c4.4 0 8.1-3.6 8.1-8.1 0-4.4-3.6-8.1-8.1-8.1h.2s-26.6-47.4-21.4-64.2c23.7-37.2 36.5-72.5 36.5-72.5 5.1-11.6 18.2-20.5 20-21.7 4-2.7 9.5-6.1 10-11.3.2-2.3-3.6-7.3-4.9-9.5z"></path>
              </svg>
              <span>{t("cat")}</span>
            </button>
          </div>

          <div
            className={
              "pets-button-container-for-left-version pets-button " +
              (selectedAnimal === "birds" ? "active" : 0)
            }
            onClick={() => setSelectedAnimal("birds")}
          >
            <button
              type="button"
              className={
                "pets-button " + (selectedAnimal === "birds" ? "active" : 0)
              }
              onClick={() => setSelectedAnimal("birds")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
                transform="scale(-1 1)"
                viewBox="0 0 256 256"
              >
                <path d="M236.438 73.344l-23.231-15.489A60.01 60.01 0 0096 76v17.194L1.753 211.002A8 8 0 008 224h104a104.118 104.118 0 00104-104v-19.719l20.438-13.625a8 8 0 000-13.312zm-126.292 67.778l-40 48a8 8 0 01-12.292-10.244l40-48a8 8 0 0112.292 10.244zM164 80a12 12 0 1112-12 12 12 0 01-12 12z"></path>
              </svg>
              <span>{t("birds")}</span>
            </button>
          </div>
        </div>

        <div className="site-main-filtre">
          <FilterField
            selectedOptions={selectedOptions["department"]}
            FiledRef={departmentRef}
            isVisable={isDepartmentVisable}
            setIsVisable={setIsDepartmentVisable}
            field_name="department"
            options={["for_sale", "adopt", "missing", "found"]}
            handleOptionClick={handleOptionSelect}
          />
          <FilterField
            selectedOptions={selectedOptions["breed"]}
            FiledRef={BreedRef}
            isVisable={isBreedVisable}
            setIsVisable={setIsBreedVisable}
            field_name={`breed`}
            options={getBreeds()}
            handleOptionClick={handleOptionSelect}
            search={true}
          />
          <FilterField
            selectedOptions={selectedOptions["gender"]}
            FiledRef={genderRef}
            isVisable={isGenderVisable}
            setIsVisable={setIsGenderVisable}
            field_name="gender"
            options={["male", "female"]}
            handleOptionClick={handleOptionSelect}
          />
          <FilterField
            selectedOptions={selectedOptions["age"]}
            FiledRef={ageRef}
            isVisable={isAgeVisable}
            setIsVisable={setIsAgeVisable}
            field_name="age"
            options={[1, 2, 3, 4, 5, 6, 7]}
            handleOptionClick={handleOptionSelect}
          />
          <FilterField
            selectedOptions={selectedOptions["city"]}
            FiledRef={cityRef}
            isVisable={isCityVisable}
            setIsVisable={setIsCityVisable}
            field_name="city"
            options={CITIES}
            handleOptionClick={handleOptionSelect}
            search={true}
          />
          <FilterField
            selectedOptions={selectedOptions["documents"]}
            FiledRef={documentsRef}
            isVisable={isDocumentsVisable}
            setIsVisable={setIsDocumentsVisable}
            field_name="documents"
            options={["yes", "no"]}
            handleOptionClick={handleOptionSelect}
          />
          <div className="filter-price">
            <input
              className="from"
              type="text"
              placeholder={t("price_from")}
              onChange={(e) =>
                handleSingleOptionSelect("price_from", e.target.value)
              }
            />

            <input
              className="to"
              type="text"
              placeholder={t("price_to")}
              onChange={(e) =>
                handleSingleOptionSelect("price_to", e.target.value)
              }
            />
          </div>

          <button
            className="filtre-search-button"
            onClick={() => {
              router.navigate({
                pathname: "/search",
                replace: true,
                search: new URLSearchParams(selectedOptions).toString(),
              });
            }}
          >
            {t("search")}
          </button>
        </div>

        <div className="clear-filters-container">
          <button
            className="clear-filters"
            type="button"
            onClick={() => {
              setSelectedOptions({});
              setSelectedAnimal("");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M21 5.98c-3.33-.33-6.68-.5-10.02-.5-1.98 0-3.96.1-5.94.3L3 5.98M8.5 4.97l.22-1.31C8.88 2.71 9 2 10.69 2h2.62c1.69 0 1.82.75 1.97 1.67l.22 1.3M18.85 9.14l-.65 10.07C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5h3.33M9.5 12.5h5"></path>
              </g>
            </svg>
            <p>{t("clear filters")}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userCurrency: state.userData.userCurrency,
  };
};
export default connect(mapStateToProps, {
  getFilteredPosts,
  changeUserCurrency,
})(Filter);
