import { connect } from "react-redux";
import { changeLoginModalDisplayAciton } from "./actions/authActions";

import { getToken } from "./utils";

function PrivateRoute({ children, changeLoginModalDisplayAciton, userData }) {
  if (getToken()) {
    return children;
  }
  changeLoginModalDisplayAciton("block");
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData.userData,
  };
};

export default connect(mapStateToProps, { changeLoginModalDisplayAciton })(
  PrivateRoute,
);
