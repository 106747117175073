import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Paginate = ({ postsCount, activePage, department = null }) => {
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const buildURL = (newPage) => {
    const currentSearchParams = new URLSearchParams(currentLocation.search);

    // Get the current value of the "page" parameter (if it exists)
    const currentPage = currentSearchParams.get("page");

    // Set the new value for the "page" parameter
    currentSearchParams.set("page", newPage);

    // If "page" parameter did not exist in the original URL, add it to the end
    if (!currentPage) {
      const newSearch = currentSearchParams.toString();
      const newUrl = `${currentLocation.pathname}?${newSearch}`;
      return newUrl;
    } else {
      // If "page" parameter already existed, replace it with the new value
      const updatedSearch = currentSearchParams
        .toString()
        .replace(`page=${currentPage}`, `page=${newPage}`);
      const newUrl = `${currentLocation.pathname}?${updatedSearch}`;
      return newUrl;
    }
  };

  const generatePaginationLinks = () => {
    const totalPages = Math.ceil(postsCount / 20);
    const paginationLinks = [];

    if (activePage !== 1) {
      // Render the first page
      paginationLinks.push(
        <Link key={1} to={buildURL(1)}>
          1
        </Link>,
      );
    }

    // Render previous 2 pages
    for (let i = Math.max(activePage - 2, 2); i < activePage; i++) {
      paginationLinks.push(
        <Link key={i} to={buildURL(i)}>
          {i}
        </Link>,
      );
    }

    // Render current page
    paginationLinks.push(
      <Link key={activePage} to={buildURL(activePage)} className="active">
        {activePage}
      </Link>,
    );

    // Render next 4 pages
    for (
      let i = activePage + 1;
      i <= Math.min(activePage + 4, totalPages);
      i++
    ) {
      paginationLinks.push(
        <Link key={i} to={buildURL(i)}>
          {i}
        </Link>,
      );
    }

    // Render the last page with ellipsis
    if (activePage < totalPages - 4) {
      paginationLinks.push(<a key="ellipsis">...</a>);
      paginationLinks.push(
        <Link key={totalPages} to={() => buildURL(totalPages)}>
          {totalPages}
        </Link>,
      );
    }

    return paginationLinks;
  };
  return (
    <span className="pagination">
      <a href="#">&laquo;</a>
      {generatePaginationLinks()}
      <a href="#">&raquo;</a>
    </span>
  );
};

export default Paginate;
