
export const validateFileField = (file) => {
    if (file.length > 0) {
        return true;
    }
    return false;
}

export const validatePhoneNumber = (value, ctx) => {
        value = value.toString()
        if (value.length !== 9) {
            return ctx.createError({"message": "phone_number_length"})
        }
        if (!value.startsWith('5')) {
            return ctx.createError({"message": "phone_number_start_with"})
        }
        return true
    }