import { v4 as uuidv4 } from "uuid";

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function getToken() {
  return localStorage.getItem("access_token");
}

export const generate_unique_file_names = (files, single_file = false) => {
  if (single_file) {
    return uuidv4() + "." + files.name.split(".").pop();
  }
  const new_file_names = [];
  for (var i = 0; i < files.length; i++) {
    let file_name = uuidv4() + "." + files[i].name.split(".").pop();
    new_file_names.push(file_name);
  }
  return new_file_names;
};

export const blockAndUlockScroll = (payload) => {
  if (payload === "none") {
    document.body.style.overflow = "auto";
  } else if (payload === "block") {
    document.body.style.overflow = "hidden";
  }
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  // const hour = date.getHours().toString().padStart(2, "0");
  // const minute = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}`; // ${hour}:${minute}
};

export const calculatePrice = (
  priceInGEL,
  priceInUSD,
  priceWithDeal,
  userCurrency,
  USD_RATE
) => {
  if (priceWithDeal) {
    return "Price with deal";
  }
  if (priceInGEL === 0 && priceInUSD === 0) {
    return 0;
  } else if (priceInGEL > 0 && userCurrency === "USD") {
    return Math.floor(priceInGEL / USD_RATE);
  } else if (priceInUSD > 0 && userCurrency === "GEL") {
    return Math.floor(priceInUSD * USD_RATE);
  } else if (priceInGEL > 0 && userCurrency === "GEL") {
    return Math.floor(priceInGEL);
  } else {
    return Math.floor(priceInUSD);
  }
};

export const DOG_BREEDS = [
  "american akita",
  "american bully",
  "american bulldog",
  "american staffordshire terrier",
  "american cocker spaniel",
  "australian shepherd",
  "airedale terrier",
  "belgian malinois",
  "beagle",
  "boxer",
  "brasilian fila",
  "bullterier",
  "bullmastif",
  "brittany spaniel",
  "berner sennenhund",
  "border collie",
  "caucasian sheperd",
  "canecorso",
  "cocker spaniel",
  "chow chow",
  "chihuahua",
  "chinese crested dog",
  "dogo argentino",
  "dogue de bordeaux",
  "doberman",
  "dalmatian",
  "drathaar",
  "dachshund",
  "europian sheperd",
  "english bulldog",
  "english setter",
  "english pointer",
  "estonian hound",
  "foxterrier",
  "french bulldog",
  "georgian shepherd",
  "german dog",
  "german shepherd",
  "german jagdterrier",
  "german shorthaired",
  "golden rottweiler",
  "greyhound",
  "irish wolfhound",
  "irish setter",
  "japanese akita",
  "jack russel terrier",
  "labrador retriever",
  "malamute",
  "mastino",
  "metis",
  "mexican hairless dog",
  "middle east shepherd",
  "miniature pinscher",
  "miniature schnauzer",
  "maltese dog",
  "maltipo",
  "pug dog",
  "pekingese",
  "pit bull terrier",
  "pomeranian spitz",
  "poodle",
  "pembroke welsh corgi",
  "rottweiler",
  "russian laika",
  "russian shepherd",
  "russian toy",
  "rhodesian ridgeback",
  "rough collie",
  "samoyed dog",
  "siberian husky",
  "scottish setter",
  "scottish terrier",
  "scotch shephard",
  "shiba",
  "shar pei",
  "staffordshire bull terrier",
  "st. bernard dog",
  "saluki",
  "spanish dog",
  "shih tzu",
  "thai ridgeback",
  "tibetian mastiff",
  "vizsla",
  "white swiss shepherd",
  "weimaraner",
  "yorkshire terrier",
  "dogs other breeds",
];

export const CAT_BREEDS = [
  "Abyssinian",
  "American Bobtail",
  "American Curl",
  "American Shorthair",
  "American Wirehair",
  "Bengal",
  "Birman",
  "British Shorthair",
  "Burmese cat",
  "Bombay cat",
  "Balinese cat",
  "Burmilla",
  "Cornish Rex",
  "Chartreux",
  "Colorpoint Shorthair",
  "California spangled",
  "Chausie",
  "Devon Rex",
  "Donskoy",
  "Exotic Shorthair",
  "European shorthair",
  "Egyptian Mau",
  "Himalayan cat",
  "Havana Brown",
  "Japanese Bobtail",
  "Javanese cat",
  "Korat",
  "LaPerm",
  "Maine Coon",
  "Manx Cat",
  "Munchkin cat",
  "Norwegian Forest cat",
  "Oriental Shorthair",
  "Ocicat",
  "Oriental Longhair",
  "Persian cat",
  "Ragdoll",
  "Russian Blue",
  "Ragamuffin",
  "Sphynx cat",
  "Siamese cat",
  "Scottish Fold",
  "Siberian cat",
  "Selkirk Rex",
  "Somali cat",
  "Singapura cat",
  "Savannah cat",
  "Snowshoe cat",
  "Tonkinese cat",
  "Turkish Angora",
  "Turkish Van",
  "cats other breeds",
];

export const CITIES = [
  "tbilisi",
  "batumi",
  "kutaisi",
  "gori",
  "zugdidi",
  "poti",
  "khashuri",
  "samtredia",
  "senaki",
  "zestaponi",
  "marneuli",
  "telavi",
  "akhaltsikhe",
  "kobuleti",
  "ozurgeti",
  "kaspi",
  "chiatura",
  "tskaltubo",
  "sagarejo",
  "gardabani",
  "borjomi",
  "tkibuli",
  "khoni",
  "bolnisi",
  "akhalkalaki",
  "gurjaani",
  "mtskheta",
  "kvareli",
  "akhmeta",
  "khareli",
  "lanchkhuti",
  "dusheti",
  "sachkhere",
  "dedoflistskaro",
  "lagodekhi",
  "ninotsminda",
  "abasha",
  "tsnori",
  "terjola",
  "martvili",
  "khobi",
  "tsalenjikha",
  "vani",
  "bagdadi",
  "vale",
  "chkhorotsku",
  "tetritskaro",
  "dmanisi",
  "oni",
  "tsalka",
  "ambrolauri",
  "signagi",
  "tsageri",
  "jvari",
];
