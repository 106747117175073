import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getTransactionUrl } from "../actions/orderActions.js";

const AddVIPStatusModal = ({
  modalRef,
  isVisable,
  setIsVisable,
  post,
  getTransactionUrl,
}) => {
  const { t } = useTranslation();

  const AddVIPStatusSchema = yup.object().shape({
    post_type: yup.string().required(),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(AddVIPStatusSchema),
  });
  const handleFormSubmit = (formValues) => {
    const arr = formValues.post_type.split(":");
    const vip_days = arr[1];
    formValues.post_type = "VIP";
    getTransactionUrl({ days: vip_days, post: post.id }, "vip-post");
  };
  if (isVisable === false) return <></>;
  return (
    <div className="confirmation-background-container">
      <div className="confirmation-inside-container" ref={modalRef}>
        <p>{t("add vip status")}</p>
        <div className="confirmation-buttons-container">
          <form
            className="add-post"
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          >
            <select {...register("post_type")}>
              <option value="" disabled selected hidden>
                {t("add vip")}
              </option>
              <option value="VIP:1">{t("vip1")}</option>
              <option value="VIP:2">{t("vip2")}</option>
              <option value="VIP:3">{t("vip3")}</option>
              <option value="VIP:4">{t("vip4")}</option>
              <option value="VIP:5">{t("vip5")}</option>
              <option value="VIP:6">{t("vip6")}</option>
              <option value="VIP:7">{t("vip7")}</option>
            </select>
            <button type="submit">{t("confirm")}</button>
            <button onClick={() => setIsVisable(false)}>{t("cancel")}</button>
          </form>
        </div>
        <div className="confirmation-warning-text">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
          </svg>
          {t("add vip status confirmation text")}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getTransactionUrl })(AddVIPStatusModal);
