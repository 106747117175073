import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropDownInput } from "../../components/DropDownInput";
import useComponentVisible from "../../hooks/useComponentVisible";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import {
  signupAction,
  changeSignupModalDisplay,
  changeLoginModalDisplayAciton,
} from "../../actions/authActions.js";
import { clearServerErrors } from "../../actions/errors.js";
import { validatePhoneNumber } from "../../validations.js";

const lngs = ["ka", "en", "ru"];
const lngsName = { ka: "ქართული", en: "English", ru: "Русский" };

const SignUp = ({
  signupAction,
  serverErrors,
  clearServerErrors,
  displaySignupModal,
  changeSignupModalDisplay,
  changeLoginModalDisplayAciton,
}) => {
  const [langSwitcherRef, isLangSwicherVisalbe, setIsLangSwicherVisable] =
    useComponentVisible(false);
  const { t, i18n } = useTranslation();
  const SignupSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup
      .number()
      .required()
      .typeError("phone_number_type_error")
      .test({
        name: "phone_number",
        test: validatePhoneNumber,
      }),
    address: yup.string().required(),
    worker_type: yup.string().required(),
    password: yup.string().required(),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "passwords_must_match"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onSubmit", resolver: yupResolver(SignupSchema) });
  useEffect(() => {
    clearServerErrors();
  }, []);
  return (
    <div
      id="myModalR"
      className="modal-background"
      style={{ display: displaySignupModal }}
    >
      <div className="login-signup-parent-modal-container">
        <div className="registration-container animate__slideInDown">
          <div className="logo-and-language-switcher-container">
            <span
              className="close_registration"
              onClick={() => changeSignupModalDisplay("none")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g>
                  <g>
                    <path
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 18l-6-6m0 0L6 6m6 6l6-6m-6 6l-6 6"
                    ></path>
                  </g>
                </g>
              </svg>

              {t("close modal")}
            </span>

            <div
              className={
                "lang-switcher-container " +
                (isLangSwicherVisalbe ? "active" : "")
              }
              onClick={() => setIsLangSwicherVisable(!isLangSwicherVisalbe)}
              ref={langSwitcherRef}
            >
              <button className="lang-list-btn">
                <img src={`/img/${localStorage.getItem("i18nextLng")}.png`} />
                <svg
                  className="earth"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  fill="none"
                  viewBox="0 0 21 20"
                >
                  <path
                    fill="#272A37"
                    d="M10.49 0C4.97 0 .5 4.48.5 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10S16.02 0 10.49 0zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0117.42 6zM10.5 2.04c.83 1.2 1.48 2.53 1.91 3.96H8.59c.43-1.43 1.08-2.76 1.91-3.96zM2.76 12c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38C6.06 8.66 6 9.32 6 10c0 .68.06 1.34.14 2H2.76zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 013.58 14zm2.95-8H3.58a7.987 7.987 0 014.33-3.56A15.65 15.65 0 006.53 6zm3.97 11.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM12.84 12H8.16c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM14.86 12c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                  ></path>
                </svg>
                <span>{lngsName[localStorage.getItem("i18nextLng")]}</span>
                <svg
                  className="language-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 8 6"
                >
                  <path
                    stroke="#BEBFC3"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1.5l3 3 3-3"
                  ></path>
                </svg>
              </button>

              <div
                className={
                  "lang-list-container " +
                  (isLangSwicherVisalbe ? "active" : "")
                }
              >
                <ul className="">
                  <h3>{t("language")}</h3>
                  {lngs.map((lng) => {
                    if (lng === localStorage.getItem("i18nextLng")) {
                      return;
                    } else {
                      return (
                        <>
                          <li
                            className="lang-list-button"
                            type="submit"
                            key={lng}
                            onClick={() => i18n.changeLanguage(lng)}
                            disabled={i18n.resolvedLanguage === lng}
                          >
                            <img src={`/img/${lng}.png`} />
                            <p>{lngsName[lng]}</p>
                          </li>
                        </>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(signupAction)}
            autoComplete=""
            noValidate
          >
            <h4 className="signup-title">{t("sign_up")}</h4>
            <label for="name">
              <p>{t("first_name")}</p>
              <input
                type="name"
                name="name"
                placeholder={t("first_name")}
                {...register("first_name")}
              />
              {errors.first_name && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.first_name?.message.key)}
                </a>
              )}
              {serverErrors.first_name && (
                <p className="help is-danger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.first_name}
                </p>
              )}
            </label>

            <label for="lastname">
              <p>{t("last_name")}</p>
              <input
                type="lastname"
                name="lastname"
                placeholder={t("last_name")}
                {...register("last_name")}
              />
              {errors.last_name && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.last_name?.message.key)}
                </a>
              )}
              {serverErrors.last_name && (
                <p className="help is-danger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.last_name}
                </p>
              )}
            </label>

            <label for="email">
              <p>{t("email")}</p>
              <input
                type="email"
                name="email"
                placeholder={t("email")}
                {...register("email")}
              />
              {errors.email && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.email?.message.key)}
                </a>
              )}
              {serverErrors.email && (
                <a className="help is-danger error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(serverErrors.email)}
                </a>
              )}
            </label>
            <label for="number">
              <p>{t("phone_number")}</p>
              <input
                type="tel"
                name="phone"
                placeholder={t("phone_number")}
                {...register("phone_number")}
              />
              {errors.phone_number && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.phone_number?.message)}
                </a>
              )}
              {serverErrors.phone_number && (
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.phone_number}
                </p>
              )}
            </label>

            <label for="password">
              <p>{t("password")}</p>
              <input
                type="password"
                name="password"
                placeholder={t("password")}
                {...register("password")}
              />

              {errors.password && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.password?.message.key)}
                </a>
              )}
              {serverErrors.password && (
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.password}
                </p>
              )}
            </label>

            <label for="password">
              <p>{t("confirm_password")}</p>
              <input
                type="password"
                name="psw-repeat"
                placeholder={t("password")}
                {...register("confirm_password")}
              />
              {errors.confirm_password && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.confirm_password?.message)}
                </a>
              )}
              {serverErrors.confirm_password && (
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.confirm_password}
                </p>
              )}
            </label>

            <label>
              <p>{t("address")}</p>
              <input
                type="address"
                name="address"
                placeholder={t("address")}
                {...register("address")}
              />
              {errors.address && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.address?.message.key)}
                </a>
              )}
              {serverErrors.address && (
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.address}
                </p>
              )}
            </label>

            <label for="petwalker">
              <p>{t("get a job")}</p>
              <select {...register("worker_type")}>
                <option value="NONE">{t("noone")}</option>

                <option value="PET SITTER">{t("petsitter")}</option>

                <option value="PET WALKER">{t("petwalker")}</option>

                <option value="BOTH">{t("both")}</option>
              </select>
              {errors.worker_type && (
                <a className="error-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {t(errors.worker_type?.message)}
                </a>
              )}
              {serverErrors.worker_type && (
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                  >
                    <path d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm48-176a48 48 0 11-96 0 48 48 0 0196 0zm-48-464a32 32 0 0132 32v288a32 32 0 01-64 0V288a32 32 0 0132-32z"></path>
                  </svg>
                  {serverErrors.worker_type}
                </p>
              )}
            </label>

            {serverErrors.error && (
              <p className="help is-danger">{t(serverErrors.error)}</p>
            )}
            <p className="registration-text">
              {t("registrer box text")}
              <Link
                to="/rules"
                onClick={() => {
                  changeSignupModalDisplay("none");
                }}
              >
                {t("site rules")}
              </Link>
            </p>
            <input type="submit" value={t("sign_up")} />
          </form>
          <div className="login-signup-switcher">
            <p
              className="existing-account"
              onClick={() => {
                changeSignupModalDisplay("none");
                changeLoginModalDisplayAciton("block");
              }}
            >
              {t("with an existing account")} <span>{t("login")}</span>
            </p>
          </div>
        </div>
        <div className="login-advertise-container">
          <Swiper
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img className="" src="img/cattt.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="" src="img/back22.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="" src="img/hug.jpg" alt="" />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="advertise-container">
          <div className="vertical-lane move-down">
            <img src="img/ad.jpg" alt="" />
            <img src="img/back25.jpg" alt="" />
            <img src="img/back23.jpg" alt="" />
            <img src="img/canecorso.jpg" alt="" />
            <img src="img/eee.jpg" alt="" />
            <img src="img/aibo.jpg" alt="" />
          </div>
          <div className="vertical-lane move-up">
            <img src="img/ad.jpg" alt="" />
            <img src="img/ad.jpg" alt="" />
            <img src="img/ad.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
    displaySignupModal: state.userData.displaySignupModal,
  };
};

export default connect(mapStateToProps, {
  changeLoginModalDisplayAciton,
  signupAction,
  changeSignupModalDisplay,
  clearServerErrors,
})(SignUp);
