import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const storageLanguage = localStorage.getItem("i18nextLng");
const fallbackLanguage = "ka";

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: fallbackLanguage,
    lng: storageLanguage || fallbackLanguage,
  });

i18next.on("languageChanged", (lng) => {
  localStorage.setItem("i18nextLng", lng);
});
