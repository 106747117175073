import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import UserPanel from "../components/UserPanel";
import {
  getChatData,
  getChatUserMessagesAction,
  sendMessageAction,
  changeCurrentChatUserID,
} from "../actions/chatActions";

const Messages = ({
  getChatData,
  chatUsers,
  currentUserData,
  getChatUserMessagesAction,
  currentChatMessages,
  sendMessageAction,
  currentChatUserID,
  changeCurrentChatUserID,
}) => {
  const { t } = useTranslation();

  const [messageText, setMessageText] = useState("");

  const messagesRef = useRef(null);

  useLayoutEffect(() => {
    if (messagesRef.current) {
      const container = messagesRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [currentChatMessages]);

  useEffect(() => {
    getChatData();
  }, []);
  useEffect(() => {
    if (currentChatUserID) {
      getChatUserMessagesAction(currentChatUserID);
    }
  }, [currentChatUserID]);

  const renderChatUsers = () => {
    if (chatUsers && currentUserData) {
      return chatUsers.map((user) => {
        return (
          <div
            key={user.id}
            className="user-name"
            onClick={() => {
              changeCurrentChatUserID(user.id)
            }}
          >
            <img src={user.profile_pic} alt="" />
            <p>
              {user.first_name} {user.last_name}
            </p>
            {user.has_unseen_conversation ? <span>New!</span> : <></>}
          </div>
        );
      });
    }
  };

  const renderMessages = () => {
    if (currentChatMessages.length > 0 && currentUserData) {
      return currentChatMessages.map((message, i) => {
        if (message.sender_id === currentUserData.id) {
          return (
            <div className="messages-lane">
              <p key={message.content + i} className="me">
                {message.content}
              </p>
            </div>
          );
        } else {
          return (
            <div className="messages-lane">
              <p key={message.content + i} className="other-user">
                {message.content}
              </p>
            </div>
          );
        }
      });
    }
  };

  const handleSendMessage = () => {
    if (messageText !== "") {
      sendMessageAction({
        receiver_id: currentChatUserID,
        sender_id: currentUserData.id,
        content: messageText,
      });
      setMessageText("");
    }
  };
  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="user-message-container">
          <div className="user-list-container">{renderChatUsers()}</div>

          <div className="read-send-message-container">
            <div ref={messagesRef} className="read-message">
              {renderMessages()}
            </div>

            <textarea
              onChange={(e) => setMessageText(e.target.value)}
              placeholder={t("write message text")}
              value={messageText}
            ></textarea>

            <button onClick={() => handleSendMessage()} type="submit">
              {t("send")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chatUsers: state.chat.chatUsers,
    currentUserData: state.userData.userData,
    currentChatMessages: state.chat.currentChatMessages,
    currentChatUserID: state.chat.currentChatUserID,
  };
};

export default connect(mapStateToProps, {
  getChatData,
  getChatUserMessagesAction,
  sendMessageAction,
  changeCurrentChatUserID,
})(Messages);
