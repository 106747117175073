import {
  GET_POSTS,
  GET_SAVED_POSTS,
  GET_SINGLE_POST,
  POSTS_FOR_CONTAINER,
  GET_ACTIVE_POSTS,
  SAVE_POST,
  DELETE_SAVED_POST,
  DELETE_SINGLE_POST,
  GET_SAVED_POSTS_FOR_FAVORITE_PAGE,
  GET_SINGEL_ACTIVE_POST,
} from "../actions/types";

const INITIAL_STATE = {
  vip_posts: null,
  latest_posts: null,
  missing_posts: null,
  found_posts: null,
  posts_for_container: null,
  single_post: null,
  savedPosts: [],
  userActivePosts: null,
  favoritePosts: null,
  singlePostSuggestions: null,
  singleActivePost: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_POSTS:
      return {
        ...state,
        vip_posts: action.payload.vip_posts,
        latest_posts: action.payload.latest_posts,
        missing_posts: action.payload.missing_posts,
        found_posts: action.payload.found_posts,
      };
    case POSTS_FOR_CONTAINER:
      return { ...state, posts_for_container: action.payload };
    case GET_SINGLE_POST:
      action.payload.post.images.unshift({
        image: action.payload.post.preview_img,
      });
      return {
        ...state,
        single_post: action.payload.post,
        singlePostSuggestions: action.payload.suggestion_posts,
      };
    case DELETE_SINGLE_POST:
      return {
        ...state,
        userActivePosts: state.userActivePosts.filter(
          (value) => value.id !== action.payload,
        ),
      };
    case GET_SAVED_POSTS:
      return { ...state, savedPosts: action.payload };
    case SAVE_POST:
      return { ...state, savedPosts: [...state.savedPosts, action.payload] };
    case DELETE_SAVED_POST:
      const savedPosts = state.savedPosts.filter(
        (value) => value !== action.payload,
      );
      let favoritePosts = null;
      if (state.favoritePosts) {
        favoritePosts = state.favoritePosts.filter(
          (value) => value.post.id !== action.payload,
        );
      }
      return {
        ...state,
        savedPosts: savedPosts,
        favoritePosts: favoritePosts,
      };
    case GET_SAVED_POSTS_FOR_FAVORITE_PAGE:
      return { ...state, favoritePosts: action.payload };
    case GET_ACTIVE_POSTS:
      return { ...state, userActivePosts: action.payload };
    case GET_SINGEL_ACTIVE_POST:
      return { ...state, singleActivePost: action.payload };
    default:
      return state;
  }
};
