import axiosInstance from "../axiosApi/api.js";
import router from "../router.js";
import { handleServerError } from "./errors.js";
import {
  ADD_NEW_CHAT_USER,
  CHANGE_CURRENT_CHAT_USER_ID,
  CLEAR_CHAT_USERS,
  GET_CHAT_USERS,
  GET_CHAT_USER_MESSAGES,
  SEND_MESSAGE,
} from "./types.js";

export const getChatData = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/chat/");
      dispatch({
        type: GET_CHAT_USERS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getChatUserMessagesAction = (userID) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/chat/${userID}/`);
      dispatch({
        type: GET_CHAT_USER_MESSAGES,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const addNewChatUser = (userChatData) => {
  return {
    type: ADD_NEW_CHAT_USER,
    payload: userChatData,
  };
};

export const clearChatUsers = () => {
  return {
    type: CLEAR_CHAT_USERS,
    payload: [],
  };
};

export const sendMessageAction = (formData) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post("/chat/", formData);
      dispatch({
        type: SEND_MESSAGE,
        payload: formData,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const changeCurrentChatUserID = (userID) => {
  return {
    type: CHANGE_CURRENT_CHAT_USER_ID,
    payload: userID,
  };
};
