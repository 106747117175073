import React, { useState } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { setLocale } from "yup";

import Home from "./pages/Home";
import SignUp from "./pages/auth/Signup";
import VerifyAccount from "./pages/auth/VerifyAccount";
import Login from "./pages/auth/Login";
import Header from "./components/Header";
import AfterSignup from "./pages/auth/AfterSignup";
import PrivateRoute from "./PrivateRoute";
import RequestResetPassword from "./pages/auth/RequestResetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import SuccessPayment from "./pages/successPayment";
import AddNewPost from "./pages/AddNewPost";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import OurServices from "./pages/OurServices";
import AdPost from "./pages/AdPost";
import Search from "./pages/Search";
import SinglePost from "./pages/SinglePost";
import ActivePosts from "./pages/ActivePosts";
import Footrer from "./components/Footer";
import Profile from "./pages/Profile";
import Favorite from "./pages/Favorite";
import Messages from "./pages/Messages";
import ScrollToTop from "./components/ScrollToTop";
import QR from "./pages/QR";
import FindPet from "./pages/FindPet";
import Rules from "./pages/Rules";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import Workers from "./pages/Workers";
import OrderQRCollarPage from "./pages/OrderQRCollarPage";
import GenerateQR from "./pages/GenerateQR";
import UpdatePost from "./pages/UpdatePost";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./styles/styles.scss";
import "./styles/aboutus.scss";
import "./styles/contact.scss";
import "./styles/service.scss";
import "./styles/userpanel.scss";
import "./styles/resolution.scss";
import "./styles/seemore.scss";
import "./styles/darkmode.scss";
import "./styles/loading.scss";
import "./styles/order.scss";
import "./styles/orderpage.scss";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const App = () => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem("dark-mode"));
  const location = useLocation();
  setLocale({
    mixed: {
      required: ({ required }) => ({
        key: "required_field",
        values: { required },
      }),
      notType: function notType(_ref) {
        switch (_ref.type) {
          case "number":
            return "number_type_error";
          case "string":
            return "Not a string error or any other custom error message";
          default:
            return "Wrong type error or any other custom error message";
        }
      },
    },
    string: {
      email: ({ email }) => ({ key: "invalid_email", values: { email } }),
    },
    number: {
      integer: "Input should be only numbers",
      positive: "Please type only positive numbers.",
      max: ({ max }) => ({ key: "field_too_big", values: { max } }),
    },
  });

  return (
    <div className={darkMode}>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {location.pathname.slice(0, 6) !== "/find/" ? (
        <Header setDarkMode={setDarkMode} darkMode={darkMode} />
      ) : (
        <></>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/returnpolicy" element={<ReturnPolicy />} />
        <Route path="/ad/posts/:postType" element={<AdPost />} />
        <Route path="/ourservice" element={<OurServices />} />
        <Route path="/search" element={<Search />} />
        <Route path="/post/:postID" element={<SinglePost />} />
        <Route path="/success/payment" element={<SuccessPayment />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify/:uid64/:token" element={<VerifyAccount />} />
        <Route path="/after/signup/" element={<AfterSignup />} />
        <Route path="/qrcollar" element={<QR />} />
        <Route path="/workers" element={<Workers />} />
        <Route path="/order/qrcollar" element={<OrderQRCollarPage />} />
        <Route path="/find/:qrID" element={<FindPet />} />
        <Route
          path="/request/reset/password"
          element={<RequestResetPassword />}
        />
        <Route
          path="/reset/password/:uid64/:token"
          element={<ResetPassword />}
        />
        <Route
          path="/add/new/post"
          element={
            <PrivateRoute>
              <AddNewPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/generateqr"
          element={
            <PrivateRoute>
              <GenerateQR />
            </PrivateRoute>
          }
        />
        <Route
          path="/me"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/active/posts"
          element={
            <PrivateRoute>
              <ActivePosts />
            </PrivateRoute>
          }
        />
        <Route
          path="/favorite"
          element={
            <PrivateRoute>
              <Favorite />
            </PrivateRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <PrivateRoute>
              <Messages />
            </PrivateRoute>
          }
        />
        <Route
          path="/update/post/:postID"
          element={
            <PrivateRoute>
              <UpdatePost />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname.slice(0, 6) !== "/find/" ? <Footrer /> : <></>}
    </div>
  );
};

export default App;
