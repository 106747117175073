import { GET_MY_QRS, GET_INFO_FOR_FIND_PET, DELETE_MY_QR } from "../actions/types";

const INITIAL_STATE = {
  myQRS: null,
  findPetInfo: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MY_QRS:
      return { ...state, myQRS: action.payload };
    case GET_INFO_FOR_FIND_PET:
      return { ...state, findPetInfo: action.payload }
    case DELETE_MY_QR:
      return {
        ...state,
        myQRS: state.myQRS.filter(
          (value) => value.id !== action.payload,
        ),
      };
    default:
      return state;
  }
};
