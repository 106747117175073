import React from "react";
import { useTranslation } from "react-i18next";

const SuccessPayment = () => {
  const { t } = useTranslation();
  return (
    <div className="success-payment">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 12.611L8.923 17.5 20 6.5"
        ></path>
      </svg>
      <h1>{t("payment succeeded")}</h1>
    </div>
  );
};

export default SuccessPayment;
